import { LOAD_USER_SUCCEEDED, LOADING_USER, LOAD_USER_FAILED, UNLOAD_USER_SUCCEEDED,
         SWITCH_ACCOUNT_SUCCEEDED, SWITCH_ACCOUNT_FAILED, LOADING_ACCOUNT,
         SET_DEFAULT_ACCOUNT, SET_DEFAULT_ACCOUNT_SUCCEEDED,
         ACCEPT_EULA, ACCEPT_EULA_SUCCEEDED,
      } from '../actions/userActions'
import { LOAD_USER_CONFIG_SUCCEEDED } from '../actions/userConfigActions'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'


function userReducer(existing = {}, action) {
  switch(action.type) {
    case LOAD_USER_SUCCEEDED:
      return {
        ...existing,
        items: {
          authenticated: action.payload.authenticated,
          loaded: true
        },
        loading: false
      }
    case UNLOAD_USER_SUCCEEDED:
      return {}
    case LOADING_USER:
    case LOADING_ACCOUNT:
      if (existing.loading) return existing
      return {
        ...existing,
        loading: true,
      }

    case SWITCH_ACCOUNT_SUCCEEDED:
      return {
        ...existing,
        assumeAccountId: action.payload.accountId,
        assumeAccountRole: action.payload.role,
        assumeAccountEmail: action.payload.email,
        assumeAccountDefault: action.payload.isDefaultAccount ? true : false,
        adminHasAssumedAnotherIdentity : action.payload.admin ? true : false,
        loading: false
      }

    case SWITCH_ACCOUNT_FAILED:
    case LOAD_USER_FAILED:
      if (!existing.loading) return existing
      return {
        ...existing,
        loading: false,
        mfaRequired: action.error?.response?.data?.mfaRequired
      }

    case LOAD_USER_CONFIG_SUCCEEDED:
      return { ...existing, userRoles: action.payload.userRoles || [], assumeAccountRole: action.payload.metaRole }

    case SET_DEFAULT_ACCOUNT:
      return { ...existing, loading: true }
    case SET_DEFAULT_ACCOUNT_SUCCEEDED:
      return { ...existing, loading: false }

    case ACCEPT_EULA:
      return { ...existing, loading: true }
    case ACCEPT_EULA_SUCCEEDED:
      return { ...existing, loading: false }

    default:
      return existing
  }
}

const persistConfig = {
  key: 'ds-redux-user',
  storage: storage,
  whitelist: [ 'assumeAccountId', 'assumeAccountRole', 'assumeAccountEmail', 'assumeAccountDefault' ]
}

export default persistReducer(persistConfig, userReducer)