import React, { PureComponent } from 'react'
import Layout from '../../components/Layout'
import { Container, Header, Item, Segment, Icon } from 'semantic-ui-react'

export default class UseCases extends PureComponent {
  render() {
    return (
      <Layout header="Use Cases">
        <Container>
          <Item.Group>
            <Item>
              <Item.Content>
                <Segment>
                  <Item.Header as='h2'><Icon name='check' color='olive'/>File uploads</Item.Header>
                  <Item.Meta>Web portals are used for multiple purposes – but generally are chokepoints in organisations for accepting and/or publishing digital content.</Item.Meta>
                  <br/>
                  <Item.Description>
                    <Header as='h3'><Icon name='question circle outline' size='small'/>Challenge</Header>
                    <p>Prevent malicious file uploads to web portals.</p>
                    <p>A web portal is a key vector for attack, often an overlooked 'side entrance' to your organisation that, at best,
                      relies on OWASP Top Ten principles to protect your critical assets.</p>

                    <p>The problem is that technologies that try to prevent weaponised content being used to attack an application via a portal,
                      such as antivirus or WAFs, rely on detection and analytics to find the threat –
                      simply put they have to 'know' about the threat to be able to prevent it.</p>

                    <p>Most attackers know that carefully crafted documents (weaponised content) can trivially evade those defences.</p>

                    <p>How is it possible to accept content from unknown sources without accepting this unquantifiable business risk?</p>

                    <Header as='h3'><Icon name='thumbs up outline' size='small'/>Solution</Header>
                    <p>CDRaaS with Instant API or Async API. The API is sent the upload and the service transforms it, creating a new file that looks and feels identical to the original but is totally malware-free. The new file is passed back to your application or workflow.</p>

                    <p>With CDRaaS all content uploaded to a portal is treated as hostile – if you simply need to accept documents from
                      unknown and therefore untrusted sources – we set the baseline the same for all transactions – trust nothing!</p>

                    <p>Once integrated into your portal application, our API intercepts the upload and sends the original content to our
                      cloud-based Content Transformation Engine. In a matter of seconds, a new file is created that looks and feels identical
                      to the original upload – however, we only deliver this clean and threat-free document to your application or process flow.</p>

                    <p>You receive the business information you need – but all known and, more importantly unknown, threats remain outside the organisation.</p>

                    <p>Secure, fast and fully functional.</p>

                  </Item.Description>
                </Segment>
              </Item.Content>
            </Item>
            <Item>
              <Item.Content>
                <Segment>
                  <Item.Header as='h2'><Icon name='check' color='olive'/>Data Storage and Migrations with Amazon S3</Item.Header>
                  <Item.Meta>Without a doubt one of the most useful and widely deployed services within AWS is Amazon S3.
                    Typically used for delivering content to applications and websites as well as for more complex data migrations,
                    Amazon S3 is pivotal for storage and processing of content.</Item.Meta>
                  <br/>
                  <Item.Description>
                    <Header as='h3'><Icon name='question circle outline' size='small'/>Challenge</Header>
                    <p>Ensure workloads migrated to Amazon S3 are totally malware-free.</p>

                    <p>Typically, when a workload is migrated to Amazon S3 it is commonplace to scan the files entering the S3 bucket for malicious content.
                      The problem is that detection technologies cannot identify unknown zero-day threats. So, it's entirely possible that organisations moving
                      content from a traditional data centre move content to Amazon S3 assuming it has been subsequently 'cleaned' successfully by anti-virus engines.</p>

                    <p>This is not a safe assumption to make.</p>

                    <p>How then is it possible to migrate to a cloud storage platform, like Amazon S3, ensuring that you don't inadvertently migrate content laced with malware during the process?</p>

                    <Header as='h3'><Icon name='thumbs up outline' size='small'/>Solution</Header>
                    <p>CDRaaS with Amazon S3 API.</p>

                    <p>Use the API to pull and push files from and to S3 buckets, calling the service to transform each file, creating a new one that looks and feels identical to the original but is totally malware-free.</p>

                    <p>Working with the Amazon S3 service we can pull content from one bucket and deliver a new version to another bucket – even to another account if needed!</p>

                  </Item.Description>
                </Segment>
              </Item.Content>
            </Item>
          </Item.Group>
        </Container>
      </Layout>)
  }
}