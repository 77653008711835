import apigClientFactory from '../lib/api-gateway-client'
import _ from 'lodash'

import { awsRegion, getApiGatewayPublicApiUrl, getApiGatewayRealApiHost } from './aws'
import { store } from '../redux/store/store';
import { getCustomHeaders, getUserRoles } from '../redux/selectors/userSelectors'
import UserError from '../lib/UserError'

export let apiGatewayClient
let apiGatewayClientNoAuth

export function initApiGatewayClient({ accessKeyId, secretAccessKey, sessionToken} = {}) {
  apiGatewayClient = apigClientFactory.newClient({
    invokeUrl: getApiGatewayPublicApiUrl(),
    accessKey: accessKeyId,
    secretKey: secretAccessKey,
    sessionToken: sessionToken,
    region: awsRegion,
    host: getApiGatewayRealApiHost()
  })
  const roleAccessAllowed = (roles, defaultAccess = false) => {
    const state = store.getState();
    const userRoles = getUserRoles(state);
    if (!userRoles || !userRoles.length) return defaultAccess;
    return userRoles.some(role => roles.includes(role));
  }
  const confirmRoles = roles => {
    if (roles && !roleAccessAllowed(roles, false)) throw new UserError('You are not permitted to perform this action');
  }

  apiGatewayClientNoAuth = apigClientFactory.newClient({
    invokeUrl: getApiGatewayPublicApiUrl()
  })


  apiGatewayClient.getNoAuth = function (path, params, body, additionalParams) {
      return apiGatewayClientNoAuth.invokeApi(params, path, 'GET', additionalParams, body);
  };

  apiGatewayClient._invokeApi = function (params, path, method, additionalParams, body) {
    const customHeaders = getCustomHeaders(store.getState());
    const _params = _.merge({}, additionalParams || {}, customHeaders ? { headers: customHeaders } : null)
    return apiGatewayClient.invokeApi(params, path, method, _params, body);
  }

  apiGatewayClient.get = async function (path, params, body, additionalParams, roles) {
      if (roles && !roleAccessAllowed(roles, true)) return { data: null };
      try {
        return apiGatewayClient._invokeApi(params, path, 'GET', additionalParams, body);
      }
      catch (error) {
        console.error(error)
        throw error
      }
  };

  apiGatewayClient.post = async function (path, params, body, additionalParams, roles) {
    confirmRoles(roles);
    return apiGatewayClient._invokeApi(params, path, 'POST', additionalParams, body);
  };

  apiGatewayClient.put = async function (path, params, body, additionalParams, roles) {
    confirmRoles(roles);
    return apiGatewayClient._invokeApi(params, path, 'PUT', additionalParams, body);
  };

  apiGatewayClient.delete = async function (path, params, body, additionalParams, roles) {
    confirmRoles(roles);
    return apiGatewayClient._invokeApi(params, path, 'DELETE', additionalParams, body);
  };

  apiGatewayClient.roleAccessAllowed = roleAccessAllowed

}

export function getApiGatewayClient() {
  if (apiGatewayClient) return Promise.resolve(apiGatewayClient)

  return new Promise(resolve => {
    const poller = window.setInterval(() => {
      if (apiGatewayClient) {
        window.clearInterval(poller)
        resolve(apiGatewayClient)
      }
    }, 100)
  })
}
