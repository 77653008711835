/* eslint-disable camelcase */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Modal, Form, Message, Button } from 'semantic-ui-react';

export default class FreeTrialApplication extends PureComponent {
  static propTypes={
    trigger: PropTypes.node,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    loadUserConfig: PropTypes.func,
    applyForFreeTrial: PropTypes.func,
    freeTrialApplicationSubmissionSuccess: PropTypes.bool,
    freeTrialApplicationSubmissionError: PropTypes.bool,
    freeTrialStatus: PropTypes.string,
    freeTrialActivated: PropTypes.bool,
    refreshSubscriptions: PropTypes.func,
    loadUsagePlans: PropTypes.func
  }

  state = {
    showMore: null,
    closeModal: null
  }

  handleChange = (e, { name, value }) => {
    this.setState({ ['form_' + name]: value })
    if (name === 'source') {
      const showMore = this.whereDidYouHearOptions.find(x => x.value === value).more
      this.setState({ showMore })
      if (!showMore)
        this.setState({ form_sourceDetails: null })
    }

  }

  handleSubmitFreeTier = () => {
    const { applyForFreeTrial } = this.props
    const { form_details, form_source, form_sourceDetails, form_contact, form_customerName } = this.state
    applyForFreeTrial(form_details, form_source, form_sourceDetails, form_contact, form_customerName);
  }

  handleOpenFreeTier = () => {
    const stateToClear = Object.keys(this.state).filter(key => this.state[key] !== null);
    if (stateToClear.length) this.setState(stateToClear.reduce((state, key) => { state[key] = null; return state}, {}));
  }

  handleCloseFreeTier = (e) => {
    const { onClose, loadUserConfig, freeTrialApplicationSubmissionSuccess, refreshSubscriptions, loadUsagePlans } = this.props;

    e.preventDefault();
    if (freeTrialApplicationSubmissionSuccess) {
      loadUsagePlans()
      loadUserConfig();
      refreshSubscriptions();
    }

    if (onClose) {
      onClose(e);
    } else this.setState({ closeModal: true });
  }

  whereDidYouHearOptions = [
    { key: 'event', text: 'An event', value: 'event', more: 'true' },
    { key: 'search', text: 'Search engine', value: 'search' },
    { key: 'linkedin', text: 'LinkedIn', value: 'linkedin' },
    { key: 'channel', text: 'A Channel Partner', value: 'channel', more: 'true' },
    { key: 'customer', text: 'Existing customer', value: 'customer' },
    { key: 'other', text: 'Other', value: 'other', more: 'true' },
  ]

  render() {
    const {trigger, open, freeTrialApplicationSubmissionSuccess, freeTrialApplicationSubmissionError, freeTrialStatus, freeTrialActivated} = this.props
    const {showMore, closeModal} = this.state
    let isOpen = open;
    if (closeModal) {
      isOpen = false;
      this.setState({ closeModal: null });
    }

    const success = freeTrialApplicationSubmissionSuccess || freeTrialStatus === 'Applied'

    return (
      <Modal trigger={trigger} closeIcon onOpen={this.handleOpenFreeTier} onClose={this.handleCloseFreeTier} open={isOpen}>

          <Modal.Header>
          { freeTrialStatus === 'Applied'
          ? 'Free trial application'
          : 'Enter your details below to apply for the free trial'
          }
          </Modal.Header>
        <Modal.Content>
          <Form onSubmit={this.handleSubmitFreeTier} success={success} error={freeTrialApplicationSubmissionError} >

            { freeTrialStatus === 'Applied' ? null : <>
              <Form.Input label='Your name' name='customerName' placeholder='Please enter your name here' onChange={this.handleChange} required />

              <Form.TextArea label='Application summary' name='details' placeholder='Tell us about your company and your use case...' onChange={this.handleChange} required />
              <Form.Select label='Where did you hear about us?' name='source' options={this.whereDidYouHearOptions} onChange={this.handleChange} required />

              { showMore
              ? <Form.TextArea name='sourceDetails' label='More details' placeholder='Tell us more about where you heard about us...' onChange={this.handleChange} required />
              : null
              }

              <Form.Input label='Deep Secure contact' name='contact' placeholder='If you are already speaking to someone at Deep Secure, please enter their name here' onChange={this.handleChange} />

              { freeTrialApplicationSubmissionSuccess
                ? null
                : <Form.Button content='Apply' />
              }
            </>}

            <Message error>
              <Message.Header>
                There was a problem submitting your application
              </Message.Header>
              &nbsp;
              <Button basic floated='right' compact size='tiny' circular negative onClick={this.handleCloseFreeTier}>Close</Button>
            </Message>

            <Message success>
              <Message.Header>
                { freeTrialActivated
                ? 'Your free trial has been successfully activated.'
                : 'Your application has been successfully submitted, and will be reviewed by Deep Secure. Please wait for someone to get in touch.'
                }
              </Message.Header>
              &nbsp;
              <Button basic floated='right' compact size='tiny' circular positive onClick={this.handleCloseFreeTier}>Done</Button>
            </Message>

          </Form>
        </Modal.Content>
      </Modal>)
  }
}
