import { LOAD_REGIONS, LOADING_REGIONS, LOAD_REGIONS_SUCCEEDED, LOAD_REGIONS_FAILED } from '../actions/regionsActions'
import { all, call, fork, put, take } from 'redux-saga/effects'


import { getRegions } from '../../services/api-catalog'

export function* loadAsync() {
  yield put({type: LOADING_REGIONS})
  try {
    const payload = yield call(getRegions)
    yield put({type: LOAD_REGIONS_SUCCEEDED, payload})
  }
  catch (error) {
    yield put({type: LOAD_REGIONS_FAILED, error})
  }
}

export function* watchLoadAsync() {
  while (true) {
    yield take(LOAD_REGIONS)
    yield fork(loadAsync)
    yield take([LOAD_REGIONS_SUCCEEDED, LOAD_REGIONS_FAILED])
  }
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    call(watchLoadAsync),
  ])
}