import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-NPS8J55'
}

const tagManagerPageView = { dataLayer: { event: 'pageview' } }

const usePageTracking = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!window.location.href.includes('localhost')) {
        TagManager.initialize(tagManagerArgs)
    }
    setInitialized(true)
  }, [])

  useEffect(() => {
    if (initialized) {
      TagManager.dataLayer(tagManagerPageView)
    }
  }, [initialized, location])
}

export const PageTracking = () => {
    usePageTracking()
    return null
}

export default usePageTracking