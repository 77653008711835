import { connect } from 'react-redux'

import { withData, withStateMappedToProps, IS_AUTHENTICATED, LOADING_DETECTED, ERRORS, IS_USER_LOADED, USER_CONFIG_HAS_PRODUCTS } from '../../redux/withData'
import { clearError } from '../../redux/actions/errorActions'

import TheComponent from './Layout.js';

const requiredData = [IS_AUTHENTICATED, LOADING_DETECTED, ERRORS, IS_USER_LOADED, USER_CONFIG_HAS_PRODUCTS]

const mapStateToProps = state => {
  return withStateMappedToProps(state, requiredData)
}

const mapDispatchToProps = {
   clearError: clearError
}

export default connect(mapStateToProps, mapDispatchToProps)(withData(requiredData)(TheComponent))