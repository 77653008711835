import { CREATE_PORTAL_API_USER, CREATING_PORTAL_API_USER, CREATE_PORTAL_API_USER_SUCCEEDED, CREATE_PORTAL_API_USER_FAILED,
  DELETE_PORTAL_API_USER,DELETING_PORTAL_API_USER, DELETE_PORTAL_API_USER_SUCCEEDED, DELETE_PORTAL_API_USER_FAILED } from '../actions/portalApiActions'

import { all, call, fork, put, take } from 'redux-saga/effects'

import { createPortalApiUser, deletePortalApiUser } from '../../services/api-catalog'

import { apiUrl, cognitoRegion, cognitoUserPoolId, apiGatewayApiId} from '../../services/aws'

// Creates a downloadable json file with the portal API config
async function buildJSONConfig (portalApiConfig) {
  if (!portalApiConfig) return null;
  const { IdentityPoolId, ClientId, Username, Password } = portalApiConfig ;
  const urlSplit = apiUrl.split('/');
  const Stage = urlSplit.pop();
  const Host = urlSplit.pop();
  const Region = cognitoRegion;
  const UserPoolId = cognitoUserPoolId;
  const ApiId = apiGatewayApiId;

  const configObj = { ApiId, Region, Stage, UserPoolId, IdentityPoolId, ClientId, Username, Password, Host };
  const blob = new Blob([JSON.stringify(configObj, false, 2)]);
  return await window.URL.createObjectURL(blob, {type: 'application/json'});
}


export function* handleCreateAsync() {
  yield put({type: CREATING_PORTAL_API_USER})
  try {
    const portalApiConfig = yield call(createPortalApiUser);
    const payload = yield call(buildJSONConfig, portalApiConfig);
    yield put({type: CREATE_PORTAL_API_USER_SUCCEEDED, payload })
  }
  catch (error) {
    yield put({type: CREATE_PORTAL_API_USER_FAILED, error})
  }
}
export function* handleDeleteAsync() {
  yield put({type: DELETING_PORTAL_API_USER})
  try {
    const payload = yield call(deletePortalApiUser);
    yield put({type: DELETE_PORTAL_API_USER_SUCCEEDED, payload })
  }
  catch (error) {
    yield put({type: DELETE_PORTAL_API_USER_FAILED, error})
  }
}

export function* watchCreateAsync() {
  while (true) {
    yield take(CREATE_PORTAL_API_USER)
    yield fork(handleCreateAsync)
    yield take([CREATE_PORTAL_API_USER_SUCCEEDED, CREATE_PORTAL_API_USER_FAILED])
  }
}
export function* watchDeleteAsync() {
  while (true) {
    yield take(DELETE_PORTAL_API_USER)
    yield fork(handleDeleteAsync)
    yield take([DELETE_PORTAL_API_USER_SUCCEEDED, DELETE_PORTAL_API_USER_FAILED])
  }
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    call(watchCreateAsync),
    call(watchDeleteAsync)
  ])
}