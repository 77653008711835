import React, { PureComponent } from 'react'
import { Container, Message, Icon } from 'semantic-ui-react'
import Layout from '../../components/Layout'
import ApiChoiceTable from '../../components/ApiChoiceTable'

export default class ApiChoice extends PureComponent {
  render() {
    return (
      <Layout header='Which API?'>
        <Container>


          <Message color='blue'>
            <Message.Header>
              <Icon name='info circle' />
              Choosing an API
            </Message.Header>
            <p>
              All of the APIs use the same Content Transformation engine.
            </p>
            <p>
              The table below provides a summary of the differences between each API.
            </p>
          </Message>

          <ApiChoiceTable />

        </Container>

      </Layout>)
  }
}
