export const LOAD_SUBSCRIPTIONS = 'LOAD_SUBSCRIPTIONS'
export const LOADING_SUBSCRIPTIONS = 'LOADING_SUBSCRIPTIONS'
export const LOAD_SUBSCRIPTIONS_SUCCEEDED = 'LOAD_SUBSCRIPTIONS_SUCCEEDED'
export const LOAD_SUBSCRIPTIONS_FAILED = 'LOAD_SUBSCRIPTIONS_FAILED'

export const ADD_SUBSCRIPTION = 'ADD_SUBSCRIPTION'
export const REMOVE_SUBSCRIPTION = 'REMOVE_SUBSCRIPTION'
export const UPDATE_SUBSCRIPTION_FAILED = 'UPDATE_SUBSCRIPTION_FAILED'
export const UPDATE_SUBSCRIPTION_SUCCEEDED = 'UPDATE_SUBSCRIPTION_SUCCEEDED'

export const REFRESH_SUBSCRIPTIONS = 'REFRESH_SUBSCRIPTIONS'

export function loadSubscriptions() {
    return { type: LOAD_SUBSCRIPTIONS }
}

export function addSubscription(usagePlanId) {
    return { type: ADD_SUBSCRIPTION, payload: usagePlanId }
}

export function removeSubscription(usagePlanId) {
    return { type: REMOVE_SUBSCRIPTION, payload: usagePlanId }
}

export function refreshSubscriptions() {
    return { type: REFRESH_SUBSCRIPTIONS }
}