export const RESET_AWS_MARKETPLACE_STATUS = 'RESET_AWS_MARKETPLACE_STATUS'
export const CONFIRM_AWS_MARKETPLACE_SUBSCRIPTION = 'CONFIRM_AWS_MARKETPLACE_SUBSCRIPTION'
export const LOADING_AWS_MARKETPLACE_SUBSCRIPTION = 'LOADING_AWS_MARKETPLACE_SUBSCRIPTION'
export const CONFIRM_AWS_MARKETPLACE_SUBSCRIPTION_SUCCEEDED = 'CONFIRM_AWS_MARKETPLACE_SUBSCRIPTION_SUCCEEDED'
export const CONFIRM_AWS_MARKETPLACE_SUBSCRIPTION_FAILED = 'CONFIRM_AWS_MARKETPLACE_SUBSCRIPTION_FAILED'


export function confirmAwsMarketplaceSubscription(awsMarketplaceToken) {
    return { type: CONFIRM_AWS_MARKETPLACE_SUBSCRIPTION, payload: {awsMarketplaceToken} }
}

export function resetAwsMarketplaceStatus() {
    return { type: RESET_AWS_MARKETPLACE_STATUS }
}
