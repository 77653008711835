import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Item } from 'semantic-ui-react'
import ApiCatalogItem from './ApiCatalogItem'

export default class ApiCatalog extends PureComponent {
  static propTypes={
    catalog: PropTypes.array.isRequired,
  }

  enrichApi(api) {
    let extra = {}
    switch(api.apiName) {
      case 'instant':
        extra= {
          "details" : {
            "label": {
              "color": "black",
              "text": "Instant"
            },
            "subHeader": "Immediate responses for files of up to 4.5 MB",
            "description": "The Instant API is the quickest and easiest API to use. You simply upload your content and get an immediate response. If you can limit the file size to 4.5 MB then this is the API for you."
          }
        }
        break
      case 'async':
        extra = {
          "details" : {
            "label": {
              "color": "black",
              "text": "Async"
            },
            "subHeader": "Asynchronous processing for files of up to 1.25 GB",
            "description": "By separating the upload and download steps the Async API supports content up to 1.25 GB in size. You submit your content, check the status and can download the result when it is ready."
          }
        }
        break
      case 's3':
        extra = {
          "details" : {
            "label": {
              "color": "black",
              "text": "S3"
            },
            "subHeader": "For easy S3 integration",
            "description": "With the S3 API, our service interacts directly with your S3 buckets."
          }
        }
        break
      default:
        break
    }
    return Object.assign(extra, api)
  }

  render() {
    const { catalog = [] } = this.props
    return (
      <Item.Group divided>
        {catalog.map(api => <ApiCatalogItem api={this.enrichApi(api)} key={api.apiName}></ApiCatalogItem>)}
      </Item.Group>
    )
  }
}