import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Popup, Button } from 'semantic-ui-react'
import './CopyToClipboard.css'


export default class CopyToClipboard extends PureComponent {
  static propTypes={
    value: PropTypes.string
  }
  state = {
      copied: false,
      openPopup: false
  }
  popupEvents = ['hover', 'click'];

  handleCopy = () => {
    const { value } = this.props;
    navigator.clipboard.writeText(value);
  }

  handleClose = (e) => {
    switch (e.type) {
      case 'click': this.setState({ copied: true, openPopup: true }); break;
      default: this.setState({ copied: false, openPopup: false }); break;
    }
  }
  handleOpen = (e) => {
    switch (e.type) {
      case 'click': this.setState({ openPopup: true, copied: true }); break;
      default: this.setState({ openPopup: true, copied: false }); break;
    }
  }

  render() {
    const {copied, openPopup} = this.state;

    // If copy to clipboard not supported just return the content
    if (!navigator.clipboard) return null;


    return (
          <Popup open={openPopup} onOpen={this.handleOpen} onClose={this.handleClose} on={this.popupEvents}
              inverted content={copied ? 'Copied' : 'Copy to Clipboard'}
              trigger={<Button size='tiny' basic className='copy-to-clipboard' icon='copy outline' onClick={this.handleCopy}/>}
          />
    )
  }
}
