import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { hasPermission } from '../../services/self'

const adminPages = [
  {pageName: 'users', permission: 'create-user'},
  {pageName: 'users', permission: 'subscriptions'},
  {pageName: 'users', permission: 'free-trial'}
];

export default class AdminPage extends PureComponent {
  static propTypes={
    location: PropTypes.object,
    isAuthenticated: PropTypes.bool,
  }

  // Redirect to the first admin page the user has permission for
  render() {
    const {location, isAuthenticated} = this.props
    const pathname = (location || {}).pathname || ''

    const redirectPage = isAuthenticated ? adminPages.find((page) => hasPermission(page.permission)) : null;
    const redirectLink = redirectPage ? `${pathname}/${redirectPage.pageName}` : '/';

    return <Redirect to={{ pathname: redirectLink, state: { from: '/admin' } }}/>
  }
}
