import {
    LOAD_DELEGATES, LOADING_DELEGATES, LOAD_DELEGATES_SUCCEEDED, LOAD_DELEGATES_FAILED,
    ADD_DELEGATE, ADD_DELEGATE_SUCCEEDED, ADD_DELEGATE_FAILED,
    UPDATE_DELEGATE_ROLE, UPDATE_DELEGATE_ROLE_SUCCEEDED, UPDATE_DELEGATE_ROLE_FAILED,
    REMOVE_DELEGATE, REMOVE_DELEGATE_SUCCEEDED, REMOVE_DELEGATE_FAILED,
  } from '../actions/delegateActions'
  import { all, call, fork, put, take } from 'redux-saga/effects'


  import { getDelegates, addDelegate, updateDelegateRole, removeDelegate } from '../../services/api-account'

  export function* loadAsync() {
    yield put({ type: LOADING_DELEGATES })
    try {
      const payload = yield call(getDelegates)
      yield put({ type: LOAD_DELEGATES_SUCCEEDED, payload })
    }
    catch (error) {
      yield put({ type: LOAD_DELEGATES_FAILED, error })
    }
  }

  export function* addAsync(email, metaRole, roles) {
    yield put({ type: LOADING_DELEGATES })
    try {
      const payload = yield call(addDelegate, email, metaRole, roles)
      yield put({ type: ADD_DELEGATE_SUCCEEDED, payload })
    }
    catch (error) {
      yield put({ type: ADD_DELEGATE_FAILED, error })
    }
  }

  export function* updateAsync(userId, metaRole, roles) {
    yield put({ type: LOADING_DELEGATES })
    try {
      const payload = yield call(updateDelegateRole, userId, metaRole, roles)
      yield put({ type: UPDATE_DELEGATE_ROLE_SUCCEEDED, payload })
    }
    catch (error) {
      yield put({ type: UPDATE_DELEGATE_ROLE_FAILED, error })
    }
  }

  export function* removeAsync(userId) {
    yield put({ type: LOADING_DELEGATES })
    try {
      const payload = yield call(removeDelegate, userId)
      yield put({ type: REMOVE_DELEGATE_SUCCEEDED, payload })
    }
    catch (error) {
      yield put({ type: REMOVE_DELEGATE_FAILED, error })
    }
  }

  export function* watchLoadAsync() {
    while (true) {
      yield take(LOAD_DELEGATES)
      yield fork(loadAsync)
      yield take([LOAD_DELEGATES_SUCCEEDED, LOAD_DELEGATES_FAILED])
    }
  }
  export function* watchAdd() {
    while (true) {
      const action = yield take(ADD_DELEGATE)
      const { email, metaRole, roles } = action.payload
      yield fork(addAsync, email, metaRole, roles)
    }
  }
  export function* watchUpdate() {
    while (true) {
      const action = yield take(UPDATE_DELEGATE_ROLE)
      const { userId, metaRole, roles } = action.payload
      yield fork(updateAsync, userId, metaRole, roles)
    }
  }
  export function* watchRemove() {
    while (true) {
      const action = yield take(REMOVE_DELEGATE)
      const { userId } = action.payload
      yield fork(removeAsync, userId)
    }
  }


  // single entry point to start all Sagas at once
  export default function* rootSaga() {
    yield all([
      call(watchLoadAsync),
      call(watchAdd),
      call(watchRemove),
      call(watchUpdate),
    ])
  }