import { CREATING_PORTAL_API_USER, CREATE_PORTAL_API_USER_SUCCEEDED, CREATE_PORTAL_API_USER_FAILED,
         DELETING_PORTAL_API_USER, DELETE_PORTAL_API_USER_SUCCEEDED, DELETE_PORTAL_API_USER_FAILED } from '../actions/portalApiActions'


function integrationReducer(existing = {}, action) {
  switch(action.type) {
    case CREATING_PORTAL_API_USER:
    case DELETING_PORTAL_API_USER:
      return {
        loading: true
      }
    case CREATE_PORTAL_API_USER_SUCCEEDED:
      return {
        loading: false,
        portalApiConfig: action.payload
      }
    case DELETE_PORTAL_API_USER_SUCCEEDED:
      return {
        loading: false
      }
    case CREATE_PORTAL_API_USER_FAILED:
    case DELETE_PORTAL_API_USER_FAILED:
      return {
        loading: false
      }

    default:
      return existing
  }
}

export default integrationReducer