import { connect } from 'react-redux'

import { withData, withStateMappedToProps, CATALOG, IS_AUTHENTICATED, USAGE_PLANS } from '../../redux/withData'

import TheComponent from './ApiS3.js';

const requiredData = [CATALOG, IS_AUTHENTICATED, USAGE_PLANS]

const mapStateToProps = state => {
  return withStateMappedToProps(state, requiredData)
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(withData(requiredData)(TheComponent))