import { LOAD_API_KEYS_SUCCEEDED, LOADING_API_KEYS, LOAD_API_KEYS_FAILED, UPDATE_API_KEY_FAILED } from '../actions/apiKeyActions'

import { UPDATE_SUBSCRIPTION_SUCCEEDED } from '../actions/subscriptionActions'
import { UNLOAD_USER_SUCCEEDED, SWITCH_ACCOUNT_SUCCEEDED } from '../actions/userActions'

import _ from 'lodash'

function apiKeyReducer(existing = {}, action) {
  switch(action.type) {
    case LOAD_API_KEYS_SUCCEEDED:
      return updateApiKeys(existing, action.payload)
    case LOADING_API_KEYS:
      return {
        ...existing,
        loading: true,
      }
    case LOAD_API_KEYS_FAILED:
      return {
        ...existing,
        loading: false,
      }
    case UPDATE_API_KEY_FAILED:
      return {
        ...existing,
        loading: false,
      }

    case SWITCH_ACCOUNT_SUCCEEDED:
    case UPDATE_SUBSCRIPTION_SUCCEEDED:
      return {}
    case UNLOAD_USER_SUCCEEDED:
      return {}

    default:
      return existing
  }
}

function updateApiKeys(existing, payload) {
    const result = {
      items: {},
      ..._.cloneDeep(existing),
    }
    result.items[payload.usagePlanId] = payload.apiKeys
    delete result.loading
    return result
}

export default apiKeyReducer