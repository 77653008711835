export function getQueryString() {
  const { search: q } = window.location

  if (!q) return {}

  return (/^[?#]/.test(q) ? q.slice(1) : q)
    .split('&')
    .reduce((params, param) => {
      let [ key, value ] = param.split('=');
      params[key] = value ? decodeURIComponent(value) : '';
      return params;
    }, { })
}
export function fileReader(file) {
  const fileReader = new FileReader()

  return new Promise((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort()
      reject(new Error('Error reading file'))
    }

    fileReader.onload = () => {
      resolve(fileReader.result)
    }

    fileReader.readAsText(file)
  })
}