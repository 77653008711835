import React, { PureComponent } from 'react'
import Layout from '../../components/Layout'
import { Container, Header } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import { PrismAsyncLight as SyntaxHighlighter } from "react-syntax-highlighter";
import { tomorrow as SyntaxStyle}  from 'react-syntax-highlighter/dist/cjs/styles/prism';

export default class Environments extends PureComponent {

  render() {

    return (
      <Layout header='Environments'>
        <Container>
          <Header as='h3'>What are environments?</Header>
          <p>
            The majority of files can be transformed within the default environment. However some files may be too big to be submitted, and/or require more memory than is available there. So, in the S3 and Async APIs, we also have a "large" environment available. It has the capability to accept larger files and has extra memory available to process them. The file size limit is 1.25&nbsp;GB.
          </p>

          <Header as='h3'>Why do I use up my quota faster when sending files through the large environment?</Header>
          <p>
            The extra resources required in the large environment incur higher costs. This is reflected in the extra quota usage charged. See <Link to='/pricing'>Pricing</Link> for more details.
          </p>

          <Header as='h3'>How to send files to the large environment in the S3 API</Header>
          <p>
            The large environment is selected using properties within costs.environment.large in the options object for the
            <Link to='/api/s3/definition'> S3 </Link>
            API.
          </p>
          <p>
            There are two properties involved. Setting 'enabled' to <strong>true</strong> is required for the large environment to be used. On its own this will automatically use the large environment for files that are over 250&nbsp;MB and under 1.25&nbsp;GB.
          </p>
          <p>
            The second property is called 'force'. Setting this to <strong>true</strong> will mean the file always goes to the large environment regardless of its size. We only recommending doing this if you have a specific reason to, such as the file previously failing processing due to a relevant error.
          </p>
          <SyntaxHighlighter language='json' style={SyntaxStyle}>
            {
            '{\n' +
            '  "mime-type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",\n' +
            '  "options": {\n' +
            '    "costs": {\n' +
            '      "environment": {\n' +
            '        "large": { \n' +
            '           "enabled": true\n' +
            '        }\n' +
            '      }\n' +
            '    }\n' +
            '  }\n' +
            '}'
            }
          </SyntaxHighlighter>

          <p>And to force a smaller file into the large environment</p>

          <SyntaxHighlighter language='json' style={SyntaxStyle}>
            {
            '{\n' +
            '  "mime-type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",\n' +
            '  "options": {\n' +
            '    "costs": {\n' +
            '      "environment": {\n' +
            '        "large": { \n' +
            '           "enabled": true,\n' +
            '           "force": true\n' +
            '        }\n' +
            '      }\n' +
            '    }\n' +
            '  }\n' +
            '}'
            }
          </SyntaxHighlighter>

          <Header as='h3'>How to send files to the large environment in the Async API</Header>
          <p>
            The large environment is selected using properties within costs.environment.large in the options object for the
            <Link to='/api/async/definition'> Async </Link>
            API.
          </p>
          <p>
            There is one property involved. Setting 'enabled' to <strong>true</strong> is required for the large environment to be used. This will always use the large environment for files regardless of their size.
          </p>
          <SyntaxHighlighter language='json' style={SyntaxStyle}>
            {
            '{\n' +
            '  "mime-type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",\n' +
            '  "options": {\n' +
            '    "costs": {\n' +
            '      "environment": {\n' +
            '        "large": { \n' +
            '           "enabled": true\n' +
            '        }\n' +
            '      }\n' +
            '    }\n' +
            '  }\n' +
            '}'
            }
          </SyntaxHighlighter>

        </Container>
      </Layout>
    )
  }
}
