import { connect } from 'react-redux'

import { withData, withStateMappedToProps, CATALOG } from '../../redux/withData'

import TheComponent from './ApiCatalog.js';

const requiredData = [CATALOG]

const mapStateToProps = state => {
  return withStateMappedToProps(state, requiredData)
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(withData(requiredData)(TheComponent))