import {USER_CONFIG_STORE_NAME} from '../reducers/reducers'

export function getUserConfigItems(state) {
    const stored = state[USER_CONFIG_STORE_NAME] || { }
    return stored.items
}

export function getYaraUrlUpdateResult(state) {
    const stored = state[USER_CONFIG_STORE_NAME] || { }
    return stored.yaraUrlError
}