import { LOAD_FRESHDESK_TOKEN, LOADING_FRESHDESK_TOKEN, LOAD_FRESHDESK_TOKEN_SUCCEEDED, LOAD_FRESHDESK_TOKEN_FAILED } from '../actions/freshdeskActions'
import { all, call, fork, put, take } from 'redux-saga/effects'


import { getFreshdeskAuthenticationToken } from '../../services/user'

export function* loadAsync() {
  yield put({type: LOADING_FRESHDESK_TOKEN})
  try {
    const payload = yield call(getFreshdeskAuthenticationToken)
    yield put({type: LOAD_FRESHDESK_TOKEN_SUCCEEDED, payload})
  }
  catch (error) {
    yield put({type: LOAD_FRESHDESK_TOKEN_FAILED, error})
  }
}

export function* watchLoadAsync() {
  while (true) {
    yield take(LOAD_FRESHDESK_TOKEN)
    yield fork(loadAsync)
    yield take([LOAD_FRESHDESK_TOKEN_SUCCEEDED, LOAD_FRESHDESK_TOKEN_FAILED])
  }
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    call(watchLoadAsync),
  ])
}