import {
  LOAD_SUBSCRIPTIONS, LOADING_SUBSCRIPTIONS, LOAD_SUBSCRIPTIONS_SUCCEEDED, LOAD_SUBSCRIPTIONS_FAILED, ADD_SUBSCRIPTION,
  REMOVE_SUBSCRIPTION, loadSubscriptions, UPDATE_SUBSCRIPTION_FAILED, UPDATE_SUBSCRIPTION_SUCCEEDED, REFRESH_SUBSCRIPTIONS
} from '../actions/subscriptionActions'
import { all, call, fork, put, take } from 'redux-saga/effects'


import { fetchSubscriptions, addSubscription, removeSubscription } from '../../services/api-catalog'

export function* loadAsync(refresh = false) {
  yield put({ type: LOADING_SUBSCRIPTIONS })
  try {
    const payload = yield call(fetchSubscriptions, refresh)
    yield put({ type: LOAD_SUBSCRIPTIONS_SUCCEEDED, payload })
  }
  catch (error) {
    yield put({ type: LOAD_SUBSCRIPTIONS_FAILED, error })
  }
}

export function* addAsync(payload) {
  yield put({ type: LOADING_SUBSCRIPTIONS })
  try {
    yield call(addSubscription, payload)
    yield put({ type: UPDATE_SUBSCRIPTION_SUCCEEDED })
    yield put(loadSubscriptions())
  }
  catch (error) {
    yield put({ type: UPDATE_SUBSCRIPTION_FAILED, error })
  }
}

export function* removeAsync(payload) {
  yield put({ type: LOADING_SUBSCRIPTIONS })
  try {
    yield call(removeSubscription, payload)
    yield put({ type: UPDATE_SUBSCRIPTION_SUCCEEDED })
    yield put(loadSubscriptions())
  }
  catch (error) {
    yield put({ type: UPDATE_SUBSCRIPTION_FAILED, error })
  }
}



export function* watchLoadAsync() {
  while (true) {
    const action = yield take([LOAD_SUBSCRIPTIONS, REFRESH_SUBSCRIPTIONS])
    yield fork(loadAsync, action.type === REFRESH_SUBSCRIPTIONS)
    yield take([LOAD_SUBSCRIPTIONS_SUCCEEDED, LOAD_SUBSCRIPTIONS_FAILED])
  }
}
export function* watchAdd() {
  while (true) {
    const action = yield take(ADD_SUBSCRIPTION)
    yield fork(addAsync, action.payload)
  }
}
export function* watchRemove() {
  while (true) {
    const action = yield take(REMOVE_SUBSCRIPTION)
    yield fork(removeAsync, action.payload)
  }
}


// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    call(watchLoadAsync),
    call(watchAdd),
    call(watchRemove),
  ])
}