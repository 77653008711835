import React, { PureComponent } from 'react'

import Layout from '../../components/Layout'
import { Container, Accordion } from 'semantic-ui-react'
import errors from '../../services/errors'

export default class ErrorGlossary extends PureComponent {

  prepareErrors() {
    return Object.keys(errors).map(title => {
      const error = errors[title];
      return {
        title: error.code + ' - ' + title,
        key: error.code,
        content: error.description,
      }
    })
  }

  render() {
    const errors = this.prepareErrors()

    return (
      <Layout header={`Error Glossary`}>
          <Container>

            <h2>HTTP Status Codes</h2>
            <p>400 - these should be accompanied with a detailed response, with API Error codes as below.</p>
            <p>403 - this means that either the API Key is invalid, or that the url is. A common issue is submitting to the Service base URL without the extra method name (for example /v1/upload for the Instant API).</p>
            <p>429 - your account has gone over usage limits and is being throttled.</p>

            <h2>Api Error Codes</h2>
            <p>This is a summary of all possible error codes, some errors are only relevant to a particular API.</p>
            <p>Click the individual titles to see more detail.</p>

            <Accordion panels={errors} exclusive={false} fluid />

          </Container>
      </Layout>)
  }
}
