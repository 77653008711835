import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Container, Icon, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { isProductEnabled, getSubscribedUsagePlans, findApiByName } from '../../services/api-catalog'

export default class ApiHeader extends PureComponent {
  static propTypes={
    currentLocation: PropTypes.object.isRequired,
    catalog: PropTypes.array,
    subscriptions: PropTypes.array,
    usagePlans: PropTypes.array,
    isAuthenticated: PropTypes.bool,
  }

  constructor(props) {
    super(props)
    const { currentLocation: {pathname = ''}} = this.props
    this.state = {
      pathname: pathname,
      basepath: pathname.replace(/^(\/[^/]+\/[^/]+)(?:\/.*)$/, '$1'),
      apiName:  pathname.replace(/^\/[^/]+\/([^/]+)(?:\/.*)?$/, '$1'),
    }
  }





  prepareHeaderData() {
    const {catalog = [], subscriptions = []} = this.props
    const { apiName } = this.state
    const api = findApiByName(catalog, apiName)
    if (!api) return {}
    const subscribedUsagePlans = getSubscribedUsagePlans(api.usagePlans, subscriptions)

    return {
      subscribedToSomething: !!subscribedUsagePlans.length,
      hasProduct: this.hasProduct()
    }

  }

  hasProduct() {
    const { catalog = [], usagePlans = [], isAuthenticated } = this.props
    const { apiName } = this.state
    if (!catalog || !usagePlans || !isAuthenticated) return false
    return isProductEnabled(catalog, usagePlans, apiName)
  }

  render() {
    const { basepath, pathname } = this.state
    const { hasProduct, subscribedToSomething } = this.prepareHeaderData()

    return (
        <Container textAlign='center'>
            <Button.Group className='stackable'>
                <Button as={Link} to={basepath} primary={basepath === pathname}><Icon name='info circle'/>Introduction</Button>
                <Button as={Link} to={`${basepath}/documentation`} primary={`${basepath}/documentation` === pathname}><Icon name='book'/>Documentation</Button>
                <Button as={Link} to={`${basepath}/definition`} primary={`${basepath}/definition` === pathname}><Icon name='code'/>API Definition and code samples</Button>
                {
                    hasProduct
                    ? <Button as={Link} to={`${basepath}/manage`} primary={`${basepath}/manage` === pathname}><Icon name='edit'/>API Keys</Button>
                    : null
                }
                {
                    hasProduct && subscribedToSomething
                    ? <Button as={Link} to={`${basepath}/demo`} primary={pathname.startsWith(`${basepath}/demo`)}><Icon name='eye'/>API Demo</Button>
                    : null
                }
            </Button.Group>
            <br /><br />
        </Container>
    )
  }
}