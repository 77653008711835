import { combineReducers } from 'redux'

import apiKeyReducer from './apiKeyReducer'
import awsMarketplaceReducer from './awsMarketplaceReducer'
import catalogReducer from './catalogReducer'
import errorReducer from './errorReducer'
import freshdeskReducer from './freshdeskReducer'
import productReducer from './productReducer'
import regionsReducer from './regionsReducer'
import secureContentReducer from './secureContentReducer'
import subscriptionReducer from './subscriptionReducer'
import userConfigReducer from './userConfigReducer'
import usagePlanReducer from './usagePlanReducer'
import userReducer from './userReducer'
import mfaReducer from './mfaReducer'
import portalApiReducer from './portalApiReducer'
import freeTrialReducer from './freeTrialReducer'
import delegateReducer from './delegateReducer'

export const API_KEY_STORE_NAME = 'apiKey'
export const AWS_MARKETPLACE_STORE_NAME = 'awsMarketplace'
export const CATALOG_STORE_NAME = 'catalog'
export const ERROR_STORE_NAME = 'error'
export const FRESHDESK_STORE_NAME = 'freshdesk'
export const PRODUCT_STORE_NAME = 'product'
export const REGIONS_STORE_NAME = 'regions'
export const SECURE_CONTENT_STORE_NAME = 'secureContent'
export const SUBSCRIPTION_STORE_NAME = 'subscription'
export const USAGE_PLAN_STORE_NAME = 'usagePlan'
export const USER_CONFIG_STORE_NAME = 'userConfig'
export const USER_STORE_NAME = 'user'
export const MFA_STORE_NAME = 'mfa'
export const PORTAL_API_STORE_NAME = 'portal_api'
export const FREE_TRIAL_STORE_NAME = 'freeTrial'
export const DELEGATES_STORE_NAME = 'delegates'

export const reducers = combineReducers({
  [API_KEY_STORE_NAME]: apiKeyReducer,
  [AWS_MARKETPLACE_STORE_NAME]: awsMarketplaceReducer,
  [CATALOG_STORE_NAME]: catalogReducer,
  [ERROR_STORE_NAME]: errorReducer,
  [FRESHDESK_STORE_NAME]: freshdeskReducer,
  [PRODUCT_STORE_NAME]: productReducer,
  [REGIONS_STORE_NAME]: regionsReducer,
  [SECURE_CONTENT_STORE_NAME]: secureContentReducer,
  [SUBSCRIPTION_STORE_NAME]: subscriptionReducer,
  [USAGE_PLAN_STORE_NAME]: usagePlanReducer,
  [USER_CONFIG_STORE_NAME]: userConfigReducer,
  [USER_STORE_NAME]: userReducer,
  [MFA_STORE_NAME]: mfaReducer,
  [PORTAL_API_STORE_NAME]: portalApiReducer,
  [FREE_TRIAL_STORE_NAME]: freeTrialReducer,
  [DELEGATES_STORE_NAME]: delegateReducer,
})
