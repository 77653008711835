import { LOAD_SUBSCRIPTIONS_SUCCEEDED, LOADING_SUBSCRIPTIONS, LOAD_SUBSCRIPTIONS_FAILED, UPDATE_SUBSCRIPTION_FAILED } from '../actions/subscriptionActions'

import { UNLOAD_USER_SUCCEEDED, SWITCH_ACCOUNT_SUCCEEDED } from '../actions/userActions'

function subscriptionReducer(existing = {}, action) {
  switch(action.type) {
    case LOAD_SUBSCRIPTIONS_SUCCEEDED:
      return {
        items: action.payload
      }
    case LOADING_SUBSCRIPTIONS:
      return {
        ...existing,
        loading: true,
      }
    case LOAD_SUBSCRIPTIONS_FAILED:
      return {
        ...existing,
        loading: false,
      }
    case UPDATE_SUBSCRIPTION_FAILED:
      return {
        ...existing,
        loading: false,
      }

    case SWITCH_ACCOUNT_SUCCEEDED:
    case UNLOAD_USER_SUCCEEDED:
      return {}

    default:
      return existing
  }
}

export default subscriptionReducer