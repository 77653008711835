import { simpleAuthenticatedGetRequest } from './self'


export async function getFreshdeskAuthenticationToken() {
  const {token} = await simpleAuthenticatedGetRequest(`/freshdesk-auth`)
  return token
}


export async function getUserConfig() {
  return await simpleAuthenticatedGetRequest(`/user-config`)
}
