import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Dimmer, Icon, Loader, Table, Dropdown, Pagination, Button, Popup } from 'semantic-ui-react';
import { getAuditLog } from '../../services/audit-log';
import { csvDownload } from '../../lib/csvDownload';

const oneDay = 24 * 60 * 60;
const dateOptions = [
    { key: 'd', text: 'Today', value: oneDay },
    { key: 'w', text: 'This Week', value: oneDay * 7 },
    { key: 'm', text: 'This Month', value: oneDay * 31 },
    { key: 'q', text: 'Last 3 Months', value: oneDay * 92 },
    { key: 'h', text: 'Last 6 Months', value: oneDay * 183 },
    { key: 'y', text: 'This Year', value: oneDay * 365 }
]

export default class AuditLog extends PureComponent {
  static propTypes={
    rowsPerPage: PropTypes.number,
    onLoading: PropTypes.func
  }
  constructor(props) {
    super(props)
    this.state = {
        startDate: Date.now(),
        endDate: Date.now(),
        log: [],
        pages: [],
        isLoading: true,
        dateRange: oneDay,
        dateHeld: null,
        rowsPerPage: props.rowsPerPage || 6,
        activePage: 1,
        timeZone: new Date().toString().split(' (').pop().slice(0,-1),
        downloadLink: null
    }
  }

  componentDidMount() {
    // Load the log
    this.loadLogs(oneDay)
  }
  // If the date range changes reload the log
  handleDateChange = (event, { value }) => this.loadLogs(value);

  loadLogs = (dateRange) => {
    const {onLoading} = this.props
    const {dateHeld, log} = this.state
    const toDate = Date.now() / 1000;
    let fromDate = toDate - (dateRange > 0 ? dateRange : oneDay);
    this.setState({ dateRange });

    // Only load more data if we don't already have it
    if (!dateHeld || dateRange >= dateHeld) {
        this.setState({ dateHeld: dateRange });
        if (onLoading) onLoading(true);
        getAuditLog(fromDate).then((log) => {
            this.paginateLog(log);
            this.setState({ log });
            if (onLoading) onLoading(false);
        });
    } else {
        // Reformat the data we already have
        const filteredLog = log.filter(entry => entry.timestamp >= fromDate);
        this.paginateLog(filteredLog);
        this.createDownloadLink(filteredLog);
    }

  }

  // Formats the data into pages
  paginateLog = (log) => {
    const {activePage, rowsPerPage} = this.state
    let currentPage = [];
    const pages = [currentPage];
    log.forEach(entry => {
        if (currentPage.length >= rowsPerPage) {
            currentPage = [];
            pages.push(currentPage);
        }

        currentPage.push({
            timestamp: new Date(entry.timestamp * 1000).toString().split(' (').shift(),
            user: entry.user,
            action: entry.action,
            details: entry.details
        });
    });
    const activePageSafe = activePage > pages.length ? pages.length : activePage;
    this.setState({ pages, activePage: activePageSafe, isLoading: false });
  }

  // Switch page event
  changePage = (event, { activePage }) => !isNaN(activePage) && activePage > 0 ? this.setState({ activePage }) : null;

  handleDownload = (event) => {
    const { log } = this.state;
    event.preventDefault()

    csvDownload(
      'audit_log.csv',
      ['Timestamp (UTC)', 'User', 'Action', 'Details'],
      log.map(entry => [new Date(entry.timestamp * 1000), entry.user, entry.action, entry.details])
    );
  }

  render() {
      const {activePage, isLoading, log, pages, timeZone} = this.state
      return isLoading
        ? <Dimmer active>
            <Loader />
          </Dimmer>
        :
        <div>
          <span>
            Display entries from <Dropdown inline options={dateOptions}
              defaultValue={dateOptions[0].value} onChange={this.handleDateChange}
              />
          </span>
          <Table basic fixed columns={4}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={2}>Timestamp ({timeZone})</Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign='center'>User</Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign='center'>Action</Table.HeaderCell>
                <Table.HeaderCell width={3} colspan={2}>Details</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
            { log.length
              ? pages[activePage-1].map(entry =>
                <Table.Row key={entry.timestamp}>
                    <Table.Cell width={2}>{entry.timestamp}</Table.Cell>
                    <Popup content={entry.user} trigger={<Table.Cell width={1} textAlign='center'>{entry.user}</Table.Cell>} />
                    <Table.Cell width={1} textAlign='center'>{entry.action}</Table.Cell>
                    <Table.Cell colspan={2}>{entry.details}</Table.Cell>
                </Table.Row>)
              : <Table.Row>
                  <Table.Cell colspan='4' textAlign='center'>
                    No log entries for this date period
                  </Table.Cell>
                </Table.Row>
            }
            </Table.Body>
            <Table.Footer>
              <Table.Row>

                <Table.HeaderCell colSpan='4'>
                  <Pagination
                    activePage={activePage}
                    ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                    firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                    lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                    prevItem={{ content: <Icon name='angle left' />, icon: true }}
                    nextItem={{ content: <Icon name='angle right' />, icon: true }}
                    totalPages={pages.length}
                    onPageChange={this.changePage}
                    mini secondary compact
                  />
                </Table.HeaderCell>

                <Table.HeaderCell textAlign='right'>
                  <Button onClick={this.handleDownload} icon labelPosition='right'>
                    Download <Icon name='download' />
                  </Button>
                </Table.HeaderCell>

              </Table.Row>
            </Table.Footer>
          </Table>
        </div>
  }
}
