import { confirmMarketplaceSubscription } from './api-catalog'
import { getHomeUrl } from './self'
import { getQueryString } from './misc'



export function getToken() {
    const { awsmarketplacetoken } = getQueryString()
    const tokenFromLocalStorage = localStorage.getItem('aws-marketplace-token')

    if (awsmarketplacetoken && awsmarketplacetoken !== tokenFromLocalStorage) {
      localStorage.setItem('aws-marketplace-token', awsmarketplacetoken)
    }

    return awsmarketplacetoken || tokenFromLocalStorage
}


export function getStatus() {
    return localStorage.getItem('aws-marketplace-status')
}


export function resetStatus() {
    return localStorage.removeItem('aws-marketplace-status')
}


export function startConfirmMarketplaceSubscription(awsMarketplaceToken) {
  if (!awsMarketplaceToken || getStatus() === 'complete')
    return Promise.resolve({})

  return confirmMarketplaceSubscription(awsMarketplaceToken)
  .then(() => {
    localStorage.removeItem('aws-marketplace-token')
    localStorage.setItem('aws-marketplace-status', 'complete')
    window.location = getHomeUrl()
  })
}
