import { LOAD_USAGE_PLANS_SUCCEEDED, LOADING_USAGE_PLANS, LOAD_USAGE_PLANS_FAILED } from '../actions/usagePlanActions'

import { UNLOAD_USER_SUCCEEDED, SWITCH_ACCOUNT_SUCCEEDED } from '../actions/userActions'

function usagePlanReducer(existing = {}, action) {
  switch(action.type) {
    case LOAD_USAGE_PLANS_SUCCEEDED:
      return  {
        items: action.payload
      }
    case LOADING_USAGE_PLANS:
      return {
        ...existing,
        loading: true,
      }
    case LOAD_USAGE_PLANS_FAILED:
      return {
        ...existing,
        loading: false,
      }

    case SWITCH_ACCOUNT_SUCCEEDED:
    case UNLOAD_USER_SUCCEEDED:
      return {}

    default:
      return existing
  }
}

export default usagePlanReducer