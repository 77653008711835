import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Layout from '../../components/Layout'
import { Container, Embed, Header } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { loginHosted } from '../../services/self'
import LinkButton from '../../components/LinkButton'

import videoImage from '../Home/images/video-preview.jpg'


export default class GettingStarted extends PureComponent {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    freeTrialStatus: PropTypes.string
  }
  state = { activeIndex: 0, freeTrialRegister: false }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  handleFreeTrialLogin = (event) => this._handleLoginHosted(event, true)
  _handleLoginHosted(event, register) {
    event.preventDefault()
    loginHosted({marketplace: true, freeTrialSignup: true}, register)
  }

  handleFreeTrialRegister = () => this.setState({ freeTrialRegister: true });
  handleFreeTrialClose = (event) => {
    event.preventDefault();
    this.setState({ freeTrialRegister: false });
  }

  render() {
    return (
      <Layout header='Getting Started'>
        <Container>
          <Header as='h2'>Using our APIs is easy - just follow these steps</Header>

          <br/>

            <ol style={{listStyle: 'decimal', paddingLeft: '1.5rem', marginBottom: '1rem'}}>

              <li>Start your <LinkButton handleClick={this.handleFreeTrialLogin}>free trial</LinkButton></li>
              <li>Follow the integration <Link to='/api/instant/documentation'>documentation</Link></li>
              <li>Understand the error conditions for your chosen API</li>
              <li>Try the demos and code samples</li>
              <li>Integrate with your application</li>

            </ol>

            <div style={{maxWidth:'800px', margin: '0 auto'}}>
              <Embed
                id='kJ9Xx1DyAXQ'
                placeholder={videoImage}
                source='youtube'
                brandedUI={false}
                aspectRatio='16:9'
                />
            </div>
        </Container>
      </Layout>
    )
  }
}
