import React, { PureComponent } from 'react'
import { Table, Icon, Label } from 'semantic-ui-react'
import PropTypes from 'prop-types'

import './ApiChoiceTable.css'

export default class ApiChoiceTable extends PureComponent {
  static propTypes={
    highlight: PropTypes.string,
  }
  render() {
    const {highlight} = this.props

    let instantClassNames = ''
    let asyncClassNames = ''
    let s3ClassNames = ''

    if (highlight === 'instant') instantClassNames = 'highlight'
    if (highlight === 'async') asyncClassNames = 'highlight'
    if (highlight === 's3') s3ClassNames = 'highlight'
    return (

      <Table basic='very' celled padded selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell className={instantClassNames}><Label ribbon color='black'>Instant</Label></Table.HeaderCell>
            <Table.HeaderCell className={asyncClassNames}><Label ribbon color='black'>Async</Label></Table.HeaderCell>
            <Table.HeaderCell className={s3ClassNames}><Label ribbon color='black'>S3</Label></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.HeaderCell textAlign='right'>Type of API</Table.HeaderCell>
            <Table.Cell className={instantClassNames}>HTTP Upload</Table.Cell>
            <Table.Cell className={asyncClassNames}>HTTP + json</Table.Cell>
            <Table.Cell className={s3ClassNames}>HTTP + json + events</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell textAlign='right'>Content size limit</Table.HeaderCell>
            <Table.Cell className={instantClassNames}>4.5&nbsp;MB</Table.Cell>
            <Table.Cell colSpan='2' className={asyncClassNames + ' ' + s3ClassNames} positive={!highlight}>250&nbsp;MB/1.25&nbsp;GB</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell textAlign='right'>Number of API requests required</Table.HeaderCell>
            <Table.Cell className={instantClassNames}>1</Table.Cell>
            <Table.Cell className={asyncClassNames}>4 (minimum)</Table.Cell>
            <Table.Cell className={s3ClassNames}>1</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell textAlign='right'>Processing time expectation</Table.HeaderCell>
            <Table.Cell colSpan='3' textAlign='center' positive={!highlight} className={instantClassNames + ' ' + asyncClassNames + ' ' + s3ClassNames}>From 0.3 seconds per MB<br/>(varies depending on content complexity)</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell textAlign='right'>Processing time limit</Table.HeaderCell>
            <Table.Cell className={instantClassNames}>28 seconds</Table.Cell>
            <Table.Cell colSpan='2' textAlign='center' positive={!highlight} className={asyncClassNames + ' ' + s3ClassNames}>200 seconds</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell textAlign='right'>AWS services required to use</Table.HeaderCell>
            <Table.Cell colSpan='2' textAlign='center' positive={!highlight} className={instantClassNames + ' ' + asyncClassNames}>None</Table.Cell>
            <Table.Cell className={s3ClassNames}>S3, IAM and SNS</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell textAlign='right'>Content temporarily stored</Table.HeaderCell>
            <Table.Cell className={instantClassNames}>No</Table.Cell>
            <Table.Cell className={asyncClassNames}>In S3 with KMS</Table.Cell>
            <Table.Cell className={s3ClassNames}>No</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell textAlign='right'>Available in multiple regions</Table.HeaderCell>
            <Table.Cell className={instantClassNames}><Icon name='checkmark' /></Table.Cell>
            <Table.Cell className={asyncClassNames}><Icon name='checkmark' /></Table.Cell>
            <Table.Cell className={s3ClassNames}><Icon name='checkmark' /></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell textAlign='right'>Fully documented</Table.HeaderCell>
            <Table.Cell className={instantClassNames}><Icon name='checkmark' /></Table.Cell>
            <Table.Cell className={asyncClassNames}><Icon name='checkmark' /></Table.Cell>
            <Table.Cell className={s3ClassNames}><Icon name='checkmark' /></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell textAlign='right'>Code samples available</Table.HeaderCell>
            <Table.Cell className={instantClassNames}><Icon name='checkmark' /></Table.Cell>
            <Table.Cell className={asyncClassNames}><Icon name='checkmark' /></Table.Cell>
            <Table.Cell className={s3ClassNames}><Icon name='checkmark' /></Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      )
  }
}
