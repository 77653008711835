import { connect } from 'react-redux'

import { withData, withStateMappedToProps, FREE_TRIAL, USER_CONFIG_FREE_TRIAL_STATUS } from '../../redux/withData'
import { loadUserConfig } from '../../redux/actions/userConfigActions'
import { applyForFreeTrial } from '../../redux/actions/freeTrialActions'
import { refreshSubscriptions } from '../../redux/actions/subscriptionActions'
import { loadUsagePlans } from '../../redux/actions/usagePlanActions'

import TheComponent from './FreeTrialApplication.js';

const requiredData = [FREE_TRIAL,USER_CONFIG_FREE_TRIAL_STATUS]

const mapStateToProps = state => {
  return withStateMappedToProps(state, requiredData)
}

const mapDispatchToProps = { loadUserConfig, applyForFreeTrial, refreshSubscriptions, loadUsagePlans }

export default connect(mapStateToProps, mapDispatchToProps)(withData(requiredData)(TheComponent))