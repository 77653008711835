export const LOAD_USER = 'LOAD_USER'
export const LOADING_USER = 'LOADING_USER'
export const LOAD_USER_SUCCEEDED = 'LOAD_USER_SUCCEEDED'
export const LOAD_USER_FAILED = 'LOAD_USER_FAILED'
export const UNLOAD_USER_SUCCEEDED = 'UNLOAD_USER_SUCCEEDED'
export const LOADING_ACCOUNT = 'LOADING_ACCOUNT'
export const SWITCH_ACCOUNT = 'SWITCH_ACCOUNT'
export const ADMIN_SWITCH_ACCOUNT = 'ADMIN_SWITCH_ACCOUNT'
export const SWITCH_ACCOUNT_SUCCEEDED = 'SWITCH_ACCOUNT_SUCCEEDED'
export const SWITCH_ACCOUNT_FAILED = 'SWITCH_ACCOUNT_FAILED'
export const CANCEL_SWITCH_ACCOUNT = 'CANCEL_SWITCH_ACCOUNT'
export const SET_DEFAULT_ACCOUNT = 'SET_DEFAULT_ACCOUNT'
export const CHANGING_DEFAULT_ACCOUNT = 'CHANGING_DEFAULT_ACCOUNT'
export const SET_DEFAULT_ACCOUNT_SUCCEEDED = 'SET_DEFAULT_ACCOUNT_SUCCEEDED'
export const SET_DEFAULT_ACCOUNT_FAILED = 'SET_DEFAULT_ACCOUNT_FAILED'
export const ACCEPT_EULA = 'ACCEPT_EULA'
export const CHANGING_ACCEPT_EULA = 'CHANGING_ACCEPT_EULA'
export const ACCEPT_EULA_SUCCEEDED = 'ACCEPT_EULA_SUCCEEDED'
export const ACCEPT_EULA_FAILED = 'ACCEPT_EULA_FAILED'

export function loadingUser() {
    return { type: LOADING_USER }
}

export function logoutUserSuccess() {
    return { type: UNLOAD_USER_SUCCEEDED }
}

export function loadUserSuccess(authenticated=true) {
    return { type: LOAD_USER_SUCCEEDED, payload: { authenticated } }
}

export function loadAnonymousUserSuccess(authenticated=false) {
    return loadUserSuccess(authenticated)
}

export function loadUserFailed(error) {
    return { type: LOAD_USER_FAILED, error }
}


export function switchAccount(accountEmail) {
    return { type: SWITCH_ACCOUNT, payload: accountEmail }
}

export function adminSwitchAccount(accountEmail) {
    return { type: ADMIN_SWITCH_ACCOUNT, payload: accountEmail }
}


export function cancelSwitchAccount() {
    return { type: CANCEL_SWITCH_ACCOUNT }
}


export function defaultAccount(accountId, role, email) {
    return { type: SWITCH_ACCOUNT_SUCCEEDED, payload: { accountId, role, email, isDefaultAccount: true }}
}


export function setDefaultAccount(email) {
    return { type: SET_DEFAULT_ACCOUNT, payload: { email }}
}


export function acceptEula() {
    return { type: ACCEPT_EULA }
}