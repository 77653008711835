export const LOAD_USER_CONFIG = 'LOAD_USER_CONFIG'
export const LOADING_USER_CONFIG = 'LOADING_USER_CONFIG'
export const LOAD_USER_CONFIG_SUCCEEDED = 'LOAD_USER_CONFIG_SUCCEEDED'
export const LOAD_USER_CONFIG_FAILED = 'LOAD_USER_CONFIG_FAILED'

export const UPDATE_USER_CONFIG_YARA_URL = 'UPDATE_USER_CONFIG_YARA_URL'
export const UPDATE_USER_CONFIG_YARA_URL_SUCCEEDED = 'UPDATE_USER_CONFIG_YARA_URL_SUCCEEDED'
export const UPDATE_USER_CONFIG_YARA_URL_ERROR = 'UPDATE_USER_CONFIG_YARA_URL_ERROR'
export const LOADING_USER_CONFIG_YARA_URL = 'LOADING_USER_CONFIG_YARA_URL'
export const LOAD_USER_CONFIG_YARA_URL_SUCCEEDED = 'LOAD_USER_CONFIG_YARA_URL_SUCCEEDED'
export const LOAD_USER_CONFIG_YARA_URL_FAILED = 'LOAD_USER_CONFIG_YARA_URL_FAILED'

export function loadUserConfig() {
    return { type: LOAD_USER_CONFIG }
}

export function updateYaraUrl(url) {
    return { type: UPDATE_USER_CONFIG_YARA_URL, payload: {url} }
}
