export const LOADING_MFA = 'LOADING_MFA'
export const ENABLE_MFA = 'ENABLE_MFA'
export const ENABLE_MFA_SUCCEEDED = 'ENABLE_MFA_SUCCEEDED'
export const ENABLE_MFA_FAILED = 'ENABLE_MFA_FAILED'
export const VERIFY_MFA = 'VERIFY_MFA'
export const VERIFY_MFA_SUCCEEDED = 'VERIFY_MFA_SUCCEEDED'
export const VERIFY_MFA_FAILED = 'VERIFY_MFA_FAILED'
export const DISABLE_MFA = 'DISABLE_MFA'
export const DISABLE_MFA_SUCCEEDED = 'DISABLE_MFA_SUCCEEDED'
export const DISABLE_MFA_FAILED = 'DISABLE_MFA_FAILED'
export const CANCEL_MFA = 'CANCEL_MFA'

export function enableMfa() {
    return { type: ENABLE_MFA }
}

export function verifyMfa(mfaVerifyPin) {
    return { type: VERIFY_MFA, payload: mfaVerifyPin }
}

export function disableMfa() {
    return { type: DISABLE_MFA }
}

export function cancelMfa() {
    return { type: CANCEL_MFA }
}
