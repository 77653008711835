import { LOAD_SECURE_CONTENT_SUCCEEDED, LOADING_SECURE_CONTENT, LOAD_SECURE_CONTENT_FAILED } from '../actions/secureContentActions'

import { UNLOAD_USER_SUCCEEDED, SWITCH_ACCOUNT_SUCCEEDED } from '../actions/userActions'

function secureContentReducer(existing = {}, action) {
  switch(action.type) {
    case LOAD_SECURE_CONTENT_SUCCEEDED:
      return {
        items: {
          ...action.payload,
          ...existing.items,
        }
      }
    case LOADING_SECURE_CONTENT:
      return {
        ...existing,
        loading: true,
      }
    case LOAD_SECURE_CONTENT_FAILED:
      return {
        ...existing,
        loading: false,
      }

    case SWITCH_ACCOUNT_SUCCEEDED:
    case UNLOAD_USER_SUCCEEDED:
      return {}

    default:
      return existing
  }
}

export default secureContentReducer