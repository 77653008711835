import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'

import Layout from '../../components/Layout'
import ApiHeader from '../../components/ApiHeader'
import {showMarketplaceLinks} from '../../components/AwsMarketplace'
import { getSubscribedUsagePlans, findApiByName } from '../../services/api-catalog'
import { s3BucketName, accountId } from '../../services/aws'
import { getIdentityId } from '../../services/self'

import { Icon, Message, Container, Header, Tab } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

export default class TryItS3 extends PureComponent {
  static propTypes={
    match: PropTypes.shape({
      params: PropTypes.shape({
        usagePlanId: PropTypes.string
      })
    }),
    location: PropTypes.object,
    catalog: PropTypes.array,
    subscriptions: PropTypes.array,
    yaraEnabled: PropTypes.bool,
  }
  state = {}
  checkStatusInterval = 1000
  apiId = 's3'

  componentDidMount() {
    const { match: { params: { usagePlanId } } } = this.props
    this.usagePlanId = usagePlanId

    this.prepareUsagePlans()
  }

  componentDidUpdate(prevProps) {
    const { catalog, subscriptions } = this.props

    if (!_.isEqual(prevProps.catalog, catalog) || !_.isEqual(prevProps.subscriptions, subscriptions))
      this.prepareUsagePlans()
  }

  prepareUsagePlans() {
    const { catalog, subscriptions } = this.props
    const api = findApiByName(catalog, this.apiId);
    if (!api) return
    const subscribedUsagePlans = getSubscribedUsagePlans(api.usagePlans, subscriptions)

    if (!this.usagePlanId && subscribedUsagePlans.length) this.usagePlanId = subscribedUsagePlans[0].id;

    const usagePlan = api.usagePlans.find(u => u.id === this.usagePlanId)
    if (!usagePlan) return

    const userId = getIdentityId()
    this.setState({
      cloudformationLink: `https://console.aws.amazon.com/cloudformation/home?region=${usagePlan.region}#/stacks/create/review?stackName=threat-removal-for-s3&templateURL=https://s3.amazonaws.com/${s3BucketName}/static/cloudformation-for-s3.yml&param_ApiUrl=${usagePlan.url}&param_TrustedAccountIds=${api.accountIds.join(',')}`,
      yaraCloudformationLink: `https://console.aws.amazon.com/cloudformation/home?region=${usagePlan.region}#/stacks/create/review?stackName=threat-removal-yara&templateURL=https://s3.amazonaws.com/${s3BucketName}/static/cloudformation-for-s3-yara.yml&param_ApiUrl=${usagePlan.url}&param_TrustedAccountIds=${api.accountIds.join(',')}&param_PortalAccountId=${accountId}&param_PortalUserId=${userId}`,
      ready: true,
      hasSubscriptions: !!subscribedUsagePlans.length
    })

  }

  render() {
    const { location, yaraEnabled } = this.props
    const { cloudformationLink, hasSubscriptions, ready, yaraCloudformationLink } = this.state

    const panes = [
      {
        menuItem: 'S3 API',
        render: () => <Tab.Pane>
          <ol>
            <li>If necessary <a href='https://portal.aws.amazon.com/billing/signup' target='_blank' rel='noopener noreferrer'>create a new AWS account <Icon name='external' size='small'/></a>, or choose an existing one.{ showMarketplaceLinks && <> It does not need to be the same account used to subscribe through the Marketplace</>}</li>
            <li>Login to the AWS account</li>
            <li>Open this CloudFormation template <a href={cloudformationLink} target='_blank' rel='noopener noreferrer'><img src='https://s3.amazonaws.com/cloudformation-examples/cloudformation-launch-stack.png' alt='Launch CloudFormation' style={{verticalAlign: 'middle'}}/></a></li>
            {yaraEnabled
              ?<li><em>(YARA only)</em> Alternatively for the YARA demo open this CloudFormation template <a href={yaraCloudformationLink} target='_blank' rel='noopener noreferrer'><img src='https://s3.amazonaws.com/cloudformation-examples/cloudformation-launch-stack.png' alt='Launch CloudFormation' style={{verticalAlign: 'middle'}}/></a></li>
            : null}
            <li>Get your API Key and ExternalId from the <Link to={`manage`}>S3 API Keys</Link> page and enter into the CloudFormation stack parameters</li>
            <li>Confirm 'I acknowledge that AWS CloudFormation might create IAM resources.'</li>
            <li>Click 'Create', and wait for completion. This should only take a couple of minutes</li>
            <li>Go to the 'Outputs' section and open each of the three https links</li>
            {yaraEnabled
              ?<li><em>(YARA only)</em> Go to the 'Outputs' section and find the 'YARAResultsApiDomain' value, you will need to enter that to configure your <Link to='/yara-dashboard'>YARA results dashboard</Link></li>
            : null}
          </ol>

          <p>The three pages are for managing the S3 buckets. Each suffix describes the purpose.</p>

          <ul>
            <li><em>source</em> - you upload unsafe content here, the API gets notified and downloads it</li>
            <li><em>target</em> - the API uploads new safe content here after processing</li>
            <li><em>source-problems</em> - the original unsafe file and an explanation of what happened are placed in here</li>
          </ul>
        </Tab.Pane>
      }
    ]

    return (
      <Layout header='The S3 API' secure={true} loading={!ready}>
        <Container>
          <ApiHeader currentLocation={location}/>

          <Header as='h2'>Demo the Zero Trust CDR S3 API</Header>

          { !ready || !hasSubscriptions ?
          <Message error icon>
            <Icon name='warning circle'/>
            <Message.Header>Please subscribe to the Zero Trust CDR S3 API first</Message.Header>
          </Message>
          :
          <div>
            <Message warning icon>
              <Icon name='warning sign'/>
              <Message.Header>The demo uses your API Keys to call the API and will be charged at your normal rate, and you will incur some additional AWS usage charges.</Message.Header>
            </Message>

            <p>This API requires users to provide some AWS infrastructure. You will have to create these in your own AWS account, and usage will incur both API usage fees and some AWS charges as well. The AWS fees are primarily for S3 storage and S3 data transfer. You can keep the storage charges to a minimum by removing data from the buckets after use. The data transfer charges can be avoided by creating the S3 Buckets in the same AWS Region as the API endpoint is located in. The other fees are very low, and in some places covered by the free tier.</p>

            <Header as='h2'>Setup instructions</Header>

            <p>Please follow these steps to start using the S3 API within minutes.</p>
            <Tab panes={panes} />

            <Header as='h2'>Usage instructions</Header>

            <p>You are now ready to upload some content.</p>

            <ol>
              <li>Open the tab with the <em>source</em> bucket</li>
              <li>Upload a file via the web interface (this can also be done with the AWS CLI and various SDKs)</li>
              <li>Refresh until the file disappears</li>
              <li>Check the <em>target</em> bucket for your new file</li>
              <li>If it is not there, check the <em>source-problems</em> bucket</li>
            </ol>

            <Header as='h2'>Cleaning up</Header>

            <p>The main aspect of these resources that costs anything when you're not using it is S3 storage, see <a href='https://aws.amazon.com/s3/pricing/' target='_blank' rel='noopener noreferrer'>S3 pricing <Icon name='external' size='small'/></a>.</p>

            <p>You can remove the AWS resources by deleting the relevant <a href='https://us-west-2.console.aws.amazon.com/cloudformation/home' target='_blank' rel='noopener noreferrer'>CloudFormation stack <Icon name='external' size='small'/></a>. However the S3 buckets won't be removed unless they are already empty.</p>

          </div> }


        </Container>
      </Layout>)
    }
  }