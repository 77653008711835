export const LOAD_API_KEYS = 'LOAD_API_KEYS'
export const LOADING_API_KEYS = 'LOADING_API_KEYS'
export const LOAD_API_KEYS_SUCCEEDED = 'LOAD_API_KEYS_SUCCEEDED'
export const LOAD_API_KEYS_FAILED = 'LOAD_API_KEYS_FAILED'

export const ADD_API_KEY = 'ADD_API_KEY'
export const REMOVE_API_KEY = 'REMOVE_API_KEY'
export const UPDATE_API_KEY_FAILED = 'UPDATE_API_KEY_FAILED'

export function loadApiKeys(usagePlanId, demo=false) {
    return { type: LOAD_API_KEYS, payload: { usagePlanId, demo } }
}

export function addApiKey(usagePlanId) {
    return { type: ADD_API_KEY, payload: { usagePlanId } }
}

export function removeApiKey(usagePlanId, apiKeyId) {
    return { type: REMOVE_API_KEY, payload: {usagePlanId, apiKeyId} }
}
