import { LOAD_USER_CONFIG_SUCCEEDED, LOADING_USER_CONFIG, LOAD_USER_CONFIG_FAILED, LOAD_USER_CONFIG_YARA_URL_SUCCEEDED,
  UPDATE_USER_CONFIG_YARA_URL, UPDATE_USER_CONFIG_YARA_URL_SUCCEEDED, UPDATE_USER_CONFIG_YARA_URL_ERROR} from '../actions/userConfigActions'

import { UNLOAD_USER_SUCCEEDED, SWITCH_ACCOUNT_SUCCEEDED, SET_DEFAULT_ACCOUNT_SUCCEEDED, ACCEPT_EULA_SUCCEEDED } from '../actions/userActions'
import { VERIFY_MFA_SUCCEEDED, DISABLE_MFA_SUCCEEDED } from '../actions/mfaActions'
import { CREATE_PORTAL_API_USER_SUCCEEDED, DELETE_PORTAL_API_USER_SUCCEEDED } from '../actions/portalApiActions'

function userConfigReducer(existing = {}, action) {
  switch(action.type) {
    case LOAD_USER_CONFIG_SUCCEEDED:
      return {
        items: action.payload
      }
    case LOADING_USER_CONFIG:
      return {
        ...existing,
        loading: true,
      }
    case LOAD_USER_CONFIG_FAILED:
      return {
        ...existing,
        loading: false,
      }

    case UPDATE_USER_CONFIG_YARA_URL:
      return {
        ...existing,
        loading: true,
        yaraUrlError: false
      }
    case UPDATE_USER_CONFIG_YARA_URL_ERROR:
      return {
        ...existing,
        loading: false,
        yaraUrlError: true
      }

    case CREATE_PORTAL_API_USER_SUCCEEDED:
    case DELETE_PORTAL_API_USER_SUCCEEDED:
    case SWITCH_ACCOUNT_SUCCEEDED:
    case UPDATE_USER_CONFIG_YARA_URL_SUCCEEDED:
    case VERIFY_MFA_SUCCEEDED:
    case DISABLE_MFA_SUCCEEDED:
    case LOAD_USER_CONFIG_YARA_URL_SUCCEEDED:
    case UNLOAD_USER_SUCCEEDED:
    case SET_DEFAULT_ACCOUNT_SUCCEEDED:
    case ACCEPT_EULA_SUCCEEDED:
      return {}

    default:
      return existing
  }
}

export default userConfigReducer