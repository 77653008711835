import { connect } from 'react-redux'

import { withData, withStateMappedToProps, IS_AUTHENTICATED } from '../../redux/withData'

import TheComponent from './Faq.js';

const requiredData = [IS_AUTHENTICATED]

const mapStateToProps = state => {
  return withStateMappedToProps(state, requiredData)
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(withData(requiredData)(TheComponent))