import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import './LinkButton.css'


export default class LinkButton extends PureComponent {
  static propTypes={
    children: PropTypes.node.isRequired,
    handleClick: PropTypes.func.isRequired,
  }
  render() {
    const {children, handleClick} = this.props
    return <button type='button' className='link-button' onClick={handleClick}>{children}</button>
  }
}
