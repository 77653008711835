export const CLEAR_ERROR = 'CLEAR_ERROR'
export const NEW_ERROR = 'NEW_ERROR'
export const ERROR_UPDATE = 'ERROR_UPDATE'
export const ERROR_REMOVE = 'ERROR_REMOVE'
export const CUSTOM_FAILURE = 'CUSTOM_FAILURE'


export function clearError(index) {
    return { type: CLEAR_ERROR, payload: index }
}

export function newError(error) {
    return { type: NEW_ERROR, payload: error }
}
