import { ERROR_REMOVE, CUSTOM_FAILURE } from '../actions/errorActions'

function errorReducer(existing = { items: []}, action) {
  switch(action.type) {
    case CUSTOM_FAILURE:
      console.error(action.payload.error)
      return {
        items: existing.items.concat([action.error || CUSTOM_FAILURE])
      }
    case ERROR_REMOVE:
      return {
        items: existing.items.filter((_, i) => i !== action.payload)
      }

    default:
      if (action.type.endsWith('_FAILED'))
        return{
          items: existing.items.concat([action.error || action.type])
        }
      return existing
  }
}

export default errorReducer
