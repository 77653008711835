import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Table, Button, Form, Message } from 'semantic-ui-react';
import Delegate from './Delegate'

const metaRoles = ['owner', 'manager', 'developer', 'viewer'].map(role => ({ key: role, text: role, value: role }));
const validEmail = /\S+@\S+\.\S+/;

export default class RoleManagement extends PureComponent {
  static propTypes={
    addDelegate: PropTypes.func,
    delegates: PropTypes.array,
    errorMessage: PropTypes.string
  }

  state = {
      newEmail: '',
      newRole: '',
      submitted: false
  }

  handleEmail = (e, {value}) => this.setState({ newEmail: value, emailError: false });
  handleRole = (e, {value}) => this.setState({ newRole: value, roleError: false });

  handleAddDelegate = (e) => {
      const { addDelegate } = this.props;
      const { newEmail, newRole } = this.state;
      e.preventDefault();

      if (!newEmail || !validEmail.test(newEmail)) {
          this.setState({ emailError: true });
          return;
      }

      if (!newRole) {
          this.setState({ roleError: true });
          return;
      }

      this.setState({ roleError: false, emailError: false, submitted: true, newEmail: null, newRole: null });
      addDelegate(newEmail, newRole);
  }

  render() {
    const { delegates, errorMessage } = this.props;
    const { newEmail, newRole, roleError, submitted } = this.state;
    const emailError = this.state.emailError || ( errorMessage === 'User not found' && submitted );

    return (
    <Form error={errorMessage}>
        <Table compact>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>email address</Table.HeaderCell>
                    <Table.HeaderCell width={3} >Role</Table.HeaderCell>
                    <Table.HeaderCell width={1} />
              </Table.Row>
            </Table.Header>

            <Table.Body>
            { (delegates || []).map((delegate, index) =>
                <Delegate key={index} userId={delegate.userId} email={delegate.email} meta={delegate.meta} roles={delegate.roles} />
            ) }
            </Table.Body>

            <Table.Footer>
                <Table.Row>
                    <Table.Cell>
                        <Form.Input error={emailError} fluid placeholder='email address for the new user'
                            value={newEmail} onChange={this.handleEmail} />
                    </Table.Cell>
                    <Table.Cell>
                        <Form.Select error={roleError} fluid placeholder='Select role' value={newRole} options={metaRoles} onChange={this.handleRole} />
                    </Table.Cell>
                    <Table.Cell>
                        <Button icon='plus' onClick={this.handleAddDelegate} />
                    </Table.Cell>
                </Table.Row>
            </Table.Footer>
        </Table>

        { submitted ? <Message error icon='warning sign' content={errorMessage} /> : null }
    </Form>
    )
  }
}
