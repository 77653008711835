import { connect } from 'react-redux'

import { withData, withStateMappedToProps, FRESHDESK_AUTHENTICATION_TOKEN, IS_AUTHENTICATED } from '../../redux/withData'
import { loadFreshdeskToken } from '../../redux/actions/freshdeskActions'

import TheComponent from './Freshdesk.js';

const requiredData = [FRESHDESK_AUTHENTICATION_TOKEN, IS_AUTHENTICATED]

const mapStateToProps = state => {
  return withStateMappedToProps(state, requiredData)
}

const mapDispatchToProps = {
    loadFreshdeskToken: loadFreshdeskToken
}

export default connect(mapStateToProps, mapDispatchToProps)(withData(requiredData)(TheComponent))