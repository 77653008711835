import { LOAD_PRODUCT, LOADING_PRODUCT, LOAD_PRODUCT_SUCCEEDED, LOAD_PRODUCT_FAILED } from '../actions/productActions'
import { all, call, fork, put, take } from 'redux-saga/effects'


import { getProducts } from '../../services/api-catalog'

export function* loadAsync() {
  yield put({type: LOADING_PRODUCT})
  try {
    const payload = yield call(getProducts)
    yield put({type: LOAD_PRODUCT_SUCCEEDED, payload})
  }
  catch (error) {
    yield put({type: LOAD_PRODUCT_FAILED, error})
  }
}

export function* watchLoadAsync() {
  while (true) {
    yield take(LOAD_PRODUCT)
    yield fork(loadAsync)
    yield take([LOAD_PRODUCT_SUCCEEDED, LOAD_PRODUCT_FAILED])
  }
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    call(watchLoadAsync),
  ])
}