import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Route, Redirect } from 'react-router-dom'
import { Dimmer, Loader } from 'semantic-ui-react'

export default class SecureRoute extends PureComponent { // eslint-disable-line
  static propTypes={
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.node]).isRequired,
    isAuthenticated: PropTypes.bool,
    isUserLoaded: PropTypes.bool,
  }

  render() {
    const {component: Component, isAuthenticated, isUserLoaded, ...rest} = this.props

    return <Route {...rest} render={renderProps => {

      if (!isUserLoaded) return <Dimmer active><Loader content='Loading' /></Dimmer>

      if (!isAuthenticated) return <Redirect to={{ pathname: '/', state: { from: renderProps.location } }}/>

      return <Component {...renderProps} />
    }} />
  }
}
