import React, { PureComponent } from 'react'
import Layout from '../../components/Layout'
import { Container, Table, Header } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import { PrismAsyncLight as SyntaxHighlighter } from "react-syntax-highlighter";
import { tomorrow as SyntaxStyle}  from 'react-syntax-highlighter/dist/cjs/styles/prism';

export default class FormatConversion extends PureComponent {

  render() {

    return (
      <Layout header='Format conversion'>
        <Container>
          <Header as='h3'>Why format conversion?</Header>
          <p>
            Whilst the Zero Trust CDR engine supports a wide variety of file formats, there are some files that it cannot transform, for example the old style Office 97 documents.
            In order to extend support to these kinds of files the APIs have a facility to convert some additional file types to ones that can be transformed.
          </p>

          <Header as='h3'>How to enable format conversion</Header>
          <p>
            Format conversion is enabled by specifying the conversion.from type in the options object for the
            <Link to='/api/async/definition'> Async </Link>
            and
            <Link to='/api/s3/definition'> S3 </Link>
            APIs, or the X-Options header for the
            <Link to='/api/instant/definition'> Instant </Link>
            API, as well as the primary mime-type to be converted to.
          </p>
          <SyntaxHighlighter language='json' style={SyntaxStyle}>
            {
            '{\n' +
            '  "mime-type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",\n' +
            '  "options": {\n' +
            '    "conversion": {\n' +
            '      "from": { "mime-type": "application/vnd.ms-excel" }\n' +
            '    }\n' +
            '  }\n' +
            '}'
            }
          </SyntaxHighlighter>

          <p>
            If the conversion is supported the data is converted from the conversion.from type into the primary mime-type, and then the converted data is transformed and returned.
          </p>

          <Header as='h3'>Automatic conversion</Header>
          <p>
            If the primary mime-type is set to application/octet-stream then the mime-type of the output file will be automatically
            selected based on the conversion.from type and the current best quality conversion for that type. As the range of file
            formats supported by the Zero Trust CDR engine is expanded and new conversion tools are added, the automatic conversion
            mappings will change to ensure the highest quality output file is generated.
            The following example converts a Word 97 document into the best format for transformation (currently .docx).
          </p>
          <SyntaxHighlighter language='json' style={SyntaxStyle}>
            {
            '{\n' +
            '  "mime-type": "application/octet-stream",\n' +
            '  "options": {\n' +
            '    "conversion": {\n' +
            '      "from": { "mime-type": "application/msword" }\n' +
            '    }\n' +
            '  }\n' +
            '}'
            }
          </SyntaxHighlighter>

          <p>
            With the same setup, passing a jpeg with conversion.from set to "image/jpeg" will cause the image to be transformed
            and returned as a jpeg file, the conversion will be skipped as image/jpeg is supported.
          </p>
          <SyntaxHighlighter language='json' style={SyntaxStyle}>
            {
            '{\n' +
            '  "mime-type": "application/octet-stream",\n' +
            '  "options": {\n' +
            '    "conversion": {\n' +
            '      "from": { "mime-type": "image/jpeg" }\n' +
            '    }\n' +
            '  }\n' +
            '}'
            }
          </SyntaxHighlighter>
          <br/>

          <p>
            Note using automatic conversion with the Instant API will still require the primary mime-type to be specified in the Accept header, although this header can contain a list of possible types as well as */* to allow all types.
          </p>
          <Header as='h3'>Valid conversions</Header>
            <Table celled selectable>
            <Table.Header>
            <Table.Row textAlign='center'>
                <Table.HeaderCell colSpan='3'>Original file</Table.HeaderCell>
                <Table.HeaderCell rowSpan='2'>Default conversion tool</Table.HeaderCell>
                <Table.HeaderCell colSpan='3'>Output file</Table.HeaderCell>
              </Table.Row>
              <Table.Row textAlign='center'>
                <Table.HeaderCell>Format</Table.HeaderCell>
                <Table.HeaderCell>Extension</Table.HeaderCell>
                <Table.HeaderCell>Mime type</Table.HeaderCell>
                <Table.HeaderCell>Format</Table.HeaderCell>
                <Table.HeaderCell>Extension</Table.HeaderCell>
                <Table.HeaderCell>Mime type</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>MS Word 97</Table.Cell>
                <Table.Cell>.doc</Table.Cell>
                <Table.Cell>application/msword</Table.Cell>
                <Table.Cell>Libre Office*</Table.Cell>
                <Table.Cell>Office X Word</Table.Cell>
                <Table.Cell>.docx</Table.Cell>
                <Table.Cell>application/vnd.openxmlformats-officedocument.wordprocessingml.document</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>MS PowerPoint 97</Table.Cell>
                <Table.Cell>.ppt</Table.Cell>
                <Table.Cell>application/vnd.ms-powerpoint</Table.Cell>
                <Table.Cell>Libre Office*</Table.Cell>
                <Table.Cell>Office X PowerPoint</Table.Cell>
                <Table.Cell>.pptx</Table.Cell>
                <Table.Cell>application/vnd.openxmlformats-officedocument.presentationml.presentation</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>MS Excel 97</Table.Cell>
                <Table.Cell>.xls</Table.Cell>
                <Table.Cell>application/vnd.ms-excel</Table.Cell>
                <Table.Cell>Libre Office*</Table.Cell>
                <Table.Cell>Office X Excel</Table.Cell>
                <Table.Cell>.xlsx</Table.Cell>
                <Table.Cell>application/vnd.openxmlformats-officedocument.spreadsheetml.sheet</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>MS Excel 97 (with macros)</Table.Cell>
                <Table.Cell>.xls</Table.Cell>
                <Table.Cell>application/vnd.ms-excel</Table.Cell>
                <Table.Cell>Libre Office*</Table.Cell>
                <Table.Cell>Office X Excel</Table.Cell>
                <Table.Cell>.xlsm</Table.Cell>
                <Table.Cell>application/vnd.ms-excel.sheet.macroEnabled.12</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>MS Excel 2007 Binary</Table.Cell>
                <Table.Cell>.xlsb</Table.Cell>
                <Table.Cell>application/vnd.ms-excel.sheet.binary.macroenabled.12</Table.Cell>
                <Table.Cell>Libre Office*</Table.Cell>
                <Table.Cell>Office X Excel</Table.Cell>
                <Table.Cell>.xlsx</Table.Cell>
                <Table.Cell>application/vnd.openxmlformats-officedocument.spreadsheetml.sheet</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Open Office Writer</Table.Cell>
                <Table.Cell>.odt</Table.Cell>
                <Table.Cell>application/vnd.oasis.opendocument.text</Table.Cell>
                <Table.Cell>Libre Office*</Table.Cell>
                <Table.Cell>Office X Word</Table.Cell>
                <Table.Cell>.docx</Table.Cell>
                <Table.Cell>application/vnd.openxmlformats-officedocument.wordprocessingml.document</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Open Office Impress</Table.Cell>
                <Table.Cell>.odp</Table.Cell>
                <Table.Cell>application/vnd.oasis.opendocument.presentation</Table.Cell>
                <Table.Cell>Libre Office*</Table.Cell>
                <Table.Cell>Office X PowerPoint</Table.Cell>
                <Table.Cell>.pptx</Table.Cell>
                <Table.Cell>application/vnd.openxmlformats-officedocument.presentationml.presentation</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Open Office Calc</Table.Cell>
                <Table.Cell>.ods</Table.Cell>
                <Table.Cell>application/vnd.oasis.opendocument.spreadsheet</Table.Cell>
                <Table.Cell>Libre Office*</Table.Cell>
                <Table.Cell>Office X Excel</Table.Cell>
                <Table.Cell>.xlsx</Table.Cell>
                <Table.Cell>application/vnd.openxmlformats-officedocument.spreadsheetml.sheet</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>CSV</Table.Cell>
                <Table.Cell>.csv</Table.Cell>
                <Table.Cell>text/csv</Table.Cell>
                <Table.Cell>Libre Office*</Table.Cell>
                <Table.Cell>CSV</Table.Cell>
                <Table.Cell>.xlsx</Table.Cell>
                <Table.Cell>application/vnd.openxmlformats-officedocument.spreadsheetml.sheet</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <p>
            * For office document conversions processed by Libre Office, there may be some loss of fidelity in the conversion process, see the
            <a rel="noopener noreferrer" target="_blank" href='https://help.libreoffice.org/6.3/en-US/text/shared/guide/ms_import_export_limitations.html?&DbPAR=WRITER&System=WIN'> Libre Office documentation </a>
            for details.
          </p>

          <Header as='h3'>Conversion method</Header>
          <p>
            New conversion tools that enable more types of conversions will be added in the future. You can control which tools can be used to process your files through the conversion methods field.
            This will allow you to set which tools to enable and disable.
            If the methods field is omitted then the default tool set is used, if you disable "default" then the conversion process will only use tools you have explicitly enabled.
          </p>
          <p>
            The following example shows the default tools being disabled and Libre Office explicitly enabled ensuring that only Libre Office will ever be used for the conversion.
          </p>
          <SyntaxHighlighter language='json' style={SyntaxStyle}>
            {
            '{\n' +
            '  "mime-type": "application/octet-stream",\n' +
            '  "options": {\n' +
            '    "conversion": { "from": { "mime-type": "application/vnd.oasis.opendocument.spreadsheet" }},\n' +
            '    "method": { "disabled": "default", "enabled": "libreoffice" }\n' +
            '  }\n' +
            '}'
            }
          </SyntaxHighlighter>
          <p>
            Currently, all conversions happen within the Deep Secure cloud service, but in the future we may offer
            conversions that integrate with external third party services.
            However, no such conversion will ever be included in the set of default conversion tools.
            This means you will always have explicit control over which services we pass your data to.
          </p>

          <Header as='h3'>Conversion failure</Header>
          <p>
            If for any reason the conversion could not be handled, either due to an unsupported conversion or a failiure in the
            actual conversion process. The error will be reported back and the file will not be transformed. All possible error
            messages are listed in the <Link to='/api/errors'>Error Glossary</Link>.
          </p>

          <Header as='h3'>Conversion limitations</Header>
          <p>
            Conversion is handled by third party tools, so any limitations of those tools also apply to our service.
            In addition on the S3 and Async API, conversion can limit the size of file that can be processed due to the extra space required by the conversion tools.
          </p>

        </Container>
      </Layout>
    )
  }
}
