import { LOAD_CATALOG, LOADING_CATALOG, LOAD_CATALOG_SUCCEEDED, LOAD_CATALOG_FAILED } from '../actions/catalogActions'
import { all, call, fork, put, take } from 'redux-saga/effects'


import { getCatalog } from '../../services/api-catalog'

export function* loadAsync() {
  yield put({type: LOADING_CATALOG})
  try {
    const payload = yield call(getCatalog)
    yield put({type: LOAD_CATALOG_SUCCEEDED, payload})
  }
  catch (error) {
    yield put({type: LOAD_CATALOG_FAILED, error})
  }
}

export function* watchLoadAsync() {
  while (true) {
    yield take(LOAD_CATALOG)
    yield fork(loadAsync)
    yield take([LOAD_CATALOG_SUCCEEDED, LOAD_CATALOG_FAILED])
  }
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    call(watchLoadAsync),
  ])
}