import * as md5 from 'md5'

import { getApiGatewayClientForSession } from './self'

export async function showApiKey(usagePlanId, demo) {
  const apiGatewayClient = await getApiGatewayClientForSession();
  try {
    const response = await apiGatewayClient.get(`/apikey/${usagePlanId}`, {}, {}, demo ? {queryParams: {demo: true}} : {});
    return response?.data?.apiKeys || [];
  }
  catch(e) {
    if (e?.response?.status === 403) return []
    throw e
  }
}

export async function createApiKey(usagePlanId) {
  const apiGatewayClient = await getApiGatewayClientForSession();
  return apiGatewayClient.post(`/subscriptions/${usagePlanId}/apikey`, {}, {}, {}, ['create-keys']);
}

export async function deleteApiKey(usagePlanId, apiKeyId) {
  const apiGatewayClient = await getApiGatewayClientForSession();
  return apiGatewayClient.delete(`/subscriptions/${usagePlanId}/apikey/${apiKeyId}`, {}, {}, {}, ['delete-keys']);
}

export function getExternalId(apiKey) {
  return md5(['threat', apiKey, 'removal'].join('-'))
}
