import { LOADING_DELEGATES, LOAD_DELEGATES_SUCCEEDED, LOAD_DELEGATES_FAILED,
         ADD_DELEGATE_SUCCEEDED, ADD_DELEGATE_FAILED,
         UPDATE_DELEGATE_ROLE_SUCCEEDED, UPDATE_DELEGATE_ROLE_FAILED,
         REMOVE_DELEGATE_SUCCEEDED, REMOVE_DELEGATE_FAILED,
        } from '../actions/delegateActions'

import { UNLOAD_USER_SUCCEEDED, SWITCH_ACCOUNT_SUCCEEDED } from '../actions/userActions'

function userRolesReducer(existing = {}, action) {
  switch(action.type) {
    case LOAD_DELEGATES_SUCCEEDED:
    case ADD_DELEGATE_SUCCEEDED:
    case UPDATE_DELEGATE_ROLE_SUCCEEDED:
    case REMOVE_DELEGATE_SUCCEEDED:
      return {
        items: action.payload
      }
    case LOADING_DELEGATES:
      return {
        ...existing,
        loading: true,
      }
    case LOAD_DELEGATES_FAILED:
    case ADD_DELEGATE_FAILED:
    case UPDATE_DELEGATE_ROLE_FAILED:
    case REMOVE_DELEGATE_FAILED:
      return {
        ...existing,
        loading: false,
        errorMessage: action?.error?.response?.data?.message || 'Failed to update user'
      }

    case SWITCH_ACCOUNT_SUCCEEDED:
    case UNLOAD_USER_SUCCEEDED:
      return {}

    default:
      return existing
  }
}

export default userRolesReducer