import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Table, Dropdown, Button } from 'semantic-ui-react';

const metaRoles = [ 'owner', 'manager', 'developer', 'viewer' ];

export default class Delegate extends PureComponent {
  static propTypes={
    updateDelegatesRole: PropTypes.func,
    removeDelegate: PropTypes.func,
    email: PropTypes.string,
    userId: PropTypes.string,
    meta: PropTypes.string,
    roles: PropTypes.array
  }

  handleRemoveDelegate = (e) => {
    const { removeDelegate, userId } = this.props;
    e.preventDefault();
    removeDelegate(userId);
  }

  handleUpdateRoles = (e, {text}) => {
    const { updateDelegatesRole, userId } = this.props;
    e.preventDefault();
    updateDelegatesRole(userId, text);
  }


  render() {
    const { email, meta } = this.props

    return (
      <Table.Row>
        <Table.Cell>{email}</Table.Cell>
        <Table.Cell>
          <Dropdown fluid text={meta}>
            <Dropdown.Menu>
              { metaRoles.map((role, index) => <Dropdown.Item key={index} text={role} onClick={this.handleUpdateRoles} /> ) }
            </Dropdown.Menu>
          </Dropdown>
        </Table.Cell>
        <Table.Cell><Button icon='trash' onClick={this.handleRemoveDelegate} /></Table.Cell>
      </Table.Row>
    )
  }
}
