import { connect } from 'react-redux'

import { withData, withStateMappedToProps, IS_AUTHENTICATED, USER_CONFIG_PORTAL_API } from '../../redux/withData'

import TheComponent from './PortalApi.js';

const requiredData = [IS_AUTHENTICATED, USER_CONFIG_PORTAL_API ]

const mapStateToProps = state => {
  return withStateMappedToProps(state, requiredData)
}

const mapDispatchToProps = { }

export default connect(mapStateToProps, mapDispatchToProps)(withData(requiredData)(TheComponent))