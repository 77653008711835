import {USER_STORE_NAME} from '../reducers/reducers'

export function getUserItems(state) {
    const stored = state[USER_STORE_NAME] || { }
    return stored.items || {}
}

export function getIsUserLoaded(state) {
    return !!getUserItems(state).loaded
}

export function getIsAuthenticated(state) {
    return !!getUserItems(state).authenticated
}

export function getCurrentAccount(state) {
    const { assumeAccountId, assumeAccountRole, assumeAccountEmail, adminHasAssumedAnotherIdentity, mfaRequired, assumeAccountDefault  } = state[USER_STORE_NAME] || { }
    return { assumeAccountId, assumeAccountRole, assumeAccountEmail, adminHasAssumedAnotherIdentity, mfaRequired, assumeAccountDefault  }
}

export function getCustomHeaders(state) {
    const { assumeAccountId } = state[USER_STORE_NAME] || {}
    if (assumeAccountId) return { 'x-ds-account': assumeAccountId }
}

export function getUserRoles(state) {
    const { userRoles } = state[USER_STORE_NAME] || {}
    return userRoles || [];
}