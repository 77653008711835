import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Container, Dimmer, Loader, Message } from 'semantic-ui-react'
import AlertPopup from '../../components/AlertPopup'
import Freshdesk from '../../components/Freshdesk'
import LayoutHeader from './LayoutHeader'
import './Layout.css'
import { getIdentityId } from '../../services/self'

export default class Layout extends PureComponent {
  static propTypes={
    usagePlanId: PropTypes.string,
    appClassName: PropTypes.string,
    header: PropTypes.string.isRequired,
    clearError: PropTypes.func,
    errors: PropTypes.array,
    children: PropTypes.node,
    hasProducts: PropTypes.bool,
    secure: PropTypes.bool,
    loading: PropTypes.bool,
    loadingDetected: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    isUserLoaded: PropTypes.bool
  }

  state = {
    yaraEnabled: false,
    openCreateUser: false,
    enterprise: false
  }

  openCreateUser = () => this.setState({ openCreateUser: true});
  closeCreateUser = () => this.setState({ openCreateUser: false});

  render() {
    const { appClassName, children, clearError, errors, hasProducts, header, loading, loadingDetected, secure,
            isUserLoaded, isAuthenticated } = this.props

    const hasErrors = errors?.length

    const isLoading = (loading || loadingDetected) && !hasErrors
    const hasViewPermission = !secure || isAuthenticated

    const showPageContent = hasViewPermission && isUserLoaded

    return (
      <div className={`App ${appClassName}`}>
        <LayoutHeader header={header}/>

        <section className='App-content'>
            { !hasViewPermission
              ? <Container><Message warning>Please login to see this page</Message></Container>
              : null
            }
            <Container>
              <AlertPopup errors={errors} clearError={clearError} />
            </Container>
            { showPageContent
              ? children
              : null
            }
            { isLoading
              ? <Dimmer active><Loader content='Loading' /></Dimmer>
              : null
            }
        </section>
        <div className='footer'>
          { isAuthenticated
            ? <p className='logged-in-as'>Logged in as: {getIdentityId()}</p>
            : null
          }
        </div>

        { isAuthenticated && hasProducts
          ? <Freshdesk/>
          : null
        }

      </div>
    )
  }
}
