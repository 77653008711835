import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Icon, Item } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import './ApiCatalogItem.css'

import whatIsCtrImage from './images/what-is-threat-removal.png'

export default class ApiCatalogItem extends PureComponent {
  static propTypes={
    api: PropTypes.object,
    usagePlans: PropTypes.array,
    isAuthenticated: PropTypes.bool,
  }

  render() {
    const {api, usagePlans = [], isAuthenticated} = this.props
    const subscribed = !!api.usagePlans.filter(x => usagePlans.includes(x.id)).length
    return (
      <Item>
        <Item.Image src={whatIsCtrImage} size='small' label={{ as: 'span', ribbon: true, content: api.details.label.text, color: api.details.label.color }} className="api-image" as={Link} to={`/api/${api.apiName}`}/>

        <Item.Content>
          <Item.Header as={Link} to={`/api/${api.apiName}`}>{api.swagger.info.title} { isAuthenticated ? (subscribed ? <Icon name='check' color='green'/> : <Icon name='lock' color='red'/>) : null}</Item.Header>
          <Item.Meta>
            <span>{api.details.subHeader}</span>
          </Item.Meta>
          <Item.Description>{api.details.description}</Item.Description>
        </Item.Content>
      </Item>
    )
  }
}