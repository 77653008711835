import { LOAD_USER_CONFIG, LOADING_USER_CONFIG, LOAD_USER_CONFIG_SUCCEEDED, LOAD_USER_CONFIG_FAILED,
  UPDATE_USER_CONFIG_YARA_URL, UPDATE_USER_CONFIG_YARA_URL_SUCCEEDED, UPDATE_USER_CONFIG_YARA_URL_ERROR,
  LOADING_USER_CONFIG_YARA_URL } from '../actions/userConfigActions'
import { all, call, fork, put, take } from 'redux-saga/effects'


import { getUserConfig } from '../../services/user'
import { submitYaraUrl } from '../../services/api-catalog'

export function* loadAsync() {
  yield put({type: LOADING_USER_CONFIG})
  try {
    const payload = yield call(getUserConfig)
    yield put({type: LOAD_USER_CONFIG_SUCCEEDED, payload})
  }
  catch (error) {
    yield put({type: LOAD_USER_CONFIG_FAILED, error})
  }
}

export function* watchLoadAsync() {
  while (true) {
    yield take(LOAD_USER_CONFIG)
    yield fork(loadAsync)
    yield take([LOAD_USER_CONFIG_SUCCEEDED, LOAD_USER_CONFIG_FAILED])
  }
}

export function* updateYaraUrlAsync(action) {
  yield put({type: LOADING_USER_CONFIG_YARA_URL})
  try {
    const url = new URL(action.payload.url)
    const payload = yield call(submitYaraUrl, url.origin)
    yield put({type: UPDATE_USER_CONFIG_YARA_URL_SUCCEEDED, payload})
  }
  catch (error) {
    yield put({type: UPDATE_USER_CONFIG_YARA_URL_ERROR, error})
  }
}

export function* watchUpdateYaraUrlAsync() {
  while (true) {
    const action = yield take(UPDATE_USER_CONFIG_YARA_URL)
    yield fork(updateYaraUrlAsync, action)
    yield take([UPDATE_USER_CONFIG_YARA_URL_SUCCEEDED, UPDATE_USER_CONFIG_YARA_URL_ERROR])
  }
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    call(watchLoadAsync),
    call(watchUpdateYaraUrlAsync),
  ])
}