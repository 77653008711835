import _apigClientFactory from 'aws-api-gateway-client';

export let apiGatewayClient
let currentClockSkew = 0;

const convertDateTime = (dt) => new Date(dt.slice(0, 4), dt.slice(4, 6) -1, dt.slice(6, 8), dt.slice(9, 11), dt.slice(11, 13), dt.slice(13, 15));

const apigClientFactory = {};


apigClientFactory.newClient = (options = {}) => {

    const _options = Object.assign({}, options);
    _options.systemClockOffset = currentClockSkew;

    let client = _apigClientFactory.newClient(_options);

    return {
        invokeApi: (params, path, method, additionalParams, body) => {
            return client.invokeApi(params, path, method, additionalParams, body).catch(error => {
                if (!error.response || error.response.status !== 403) throw error;

                const message = error.response.data.message;
                if (!message.startsWith('Signature not yet current')) throw error;

                // Calculate the clock skew from the error message
                const times = message.match(/([0-9Z])\w+/g);
                const current = convertDateTime(times[0]);
                const actual = convertDateTime(times[2]);
                const skew = (actual - current);

                // Check if the skew has already been fixed if not re-init the client
                if (Math.abs(_options.systemClockOffset - skew) > 10000) {
                    _options.systemClockOffset = skew;
                    client = apigClientFactory.newClient(_options);
                    currentClockSkew = skew;        // Keep track of skew for any new instances of apiGatewayClient
                }

                // Retry the invoke
                return client.invokeApi(params, path, method, additionalParams, body);
            });
        }
    }
}

export default apigClientFactory;