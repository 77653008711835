export const CREATE_PORTAL_API_USER = 'CREATE_PORTAL_API_USER_USER'
export const CREATING_PORTAL_API_USER = 'CREATING_PORTAL_API_USER'
export const CREATE_PORTAL_API_USER_SUCCEEDED = 'CREATE_PORTAL_API_USER_SUCCEEDED'
export const CREATE_PORTAL_API_USER_FAILED = 'CREATE_PORTAL_API_USER_FAILED'

export const DELETE_PORTAL_API_USER = 'DELETE_PORTAL_API_USER'
export const DELETING_PORTAL_API_USER = 'DELETING_PORTAL_API_USER'
export const DELETE_PORTAL_API_USER_SUCCEEDED = 'DELETE_PORTAL_API_USER_SUCCEEDED'
export const DELETE_PORTAL_API_USER_FAILED = 'DELETE_PORTAL_API_USER_FAILED'

export function createPortalApiUser() {
    return { type: CREATE_PORTAL_API_USER }
}

export function deletePortalApiUser() {
    return { type: DELETE_PORTAL_API_USER}
}

