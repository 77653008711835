import { APPLY_FREE_TRIAL, APPLY_FREE_TRIAL_IN_PROGRESS, APPLY_FREE_TRIAL_SUCCEEDED, APPLY_FREE_TRIAL_FAILED } from '../actions/freeTrialActions'
import { all, call, fork, put, take } from 'redux-saga/effects'
import { submitFreeTierApplication } from '../../services/api-catalog'

export function* loadAsync(action) {
  yield put({type: APPLY_FREE_TRIAL_IN_PROGRESS})
  try {
    const { details, source, sourceDetails, contact, customerName } = action.payload
    const activated = yield call(submitFreeTierApplication, details, source, sourceDetails, contact, customerName)
    yield put({type: APPLY_FREE_TRIAL_SUCCEEDED, payload: { activated }})
  }
  catch (error) {
    yield put({type: APPLY_FREE_TRIAL_FAILED, error})
  }
}

export function* watchLoadAsync() {
  while (true) {
    const action = yield take(APPLY_FREE_TRIAL)
    yield fork(loadAsync, action)
    yield take([APPLY_FREE_TRIAL_SUCCEEDED, APPLY_FREE_TRIAL_FAILED])
  }
}


// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    call(watchLoadAsync)
  ])
}