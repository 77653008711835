import { LOAD_SECURE_CONTENT, LOADING_SECURE_CONTENT, LOAD_SECURE_CONTENT_SUCCEEDED, LOAD_SECURE_CONTENT_FAILED } from '../actions/secureContentActions'
import { all, call, put, takeEvery } from 'redux-saga/effects'


import { fetchSecurePageContent } from '../../services/api-catalog'

export function* loadAsync(action) {
  const pageName = action.payload
  yield put({type: LOADING_SECURE_CONTENT})
  try {
    const content = yield call(fetchSecurePageContent, pageName)
    yield put({type: LOAD_SECURE_CONTENT_SUCCEEDED, payload: { [pageName]: content }})
  }
  catch (error) {
    yield put({type: LOAD_SECURE_CONTENT_FAILED, error})
  }
}

export function* watchLoadAsync() {
  yield takeEvery(LOAD_SECURE_CONTENT, loadAsync)
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    call(watchLoadAsync),
  ])
}