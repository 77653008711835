import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Layout from '../../components/Layout'
import { Container } from 'semantic-ui-react'


export default class ContentTypesImages extends PureComponent {
  static propTypes={
    secureContent: PropTypes.string,
  }

  render() {
    const { secureContent } = this.props
    return (
      <Layout header='Images'>
        <Container>
          <div dangerouslySetInnerHTML={{ __html: secureContent }}/>
        </Container>
      </Layout>
    )
  }
}
