import { RESET_AWS_MARKETPLACE_STATUS, CONFIRM_AWS_MARKETPLACE_SUBSCRIPTION, LOADING_AWS_MARKETPLACE_SUBSCRIPTION, CONFIRM_AWS_MARKETPLACE_SUBSCRIPTION_SUCCEEDED, CONFIRM_AWS_MARKETPLACE_SUBSCRIPTION_FAILED } from '../actions/awsMarketplaceActions'
import { all, call, fork, put, take } from 'redux-saga/effects'


import { startConfirmMarketplaceSubscription, resetStatus } from '../../services/aws-marketplace'



export function* resetAsync() {
  yield call(resetStatus)
}

export function* confirmAsync(action) {
  yield put({type: LOADING_AWS_MARKETPLACE_SUBSCRIPTION})
  try {
    const payload = yield call(startConfirmMarketplaceSubscription, action.payload.awsMarketplaceToken)
    yield put({type: CONFIRM_AWS_MARKETPLACE_SUBSCRIPTION_SUCCEEDED, payload})
  }
  catch (error) {
    yield put({type: CONFIRM_AWS_MARKETPLACE_SUBSCRIPTION_FAILED, error})
  }
}



export function* watchResetAsync() {
  while (true) {
    yield take(RESET_AWS_MARKETPLACE_STATUS)
    yield fork(resetAsync)
  }
}

export function* watchConfirmAsync() {
  while (true) {
    const action = yield take(CONFIRM_AWS_MARKETPLACE_SUBSCRIPTION)
    yield fork(confirmAsync, action)
    yield take([CONFIRM_AWS_MARKETPLACE_SUBSCRIPTION_SUCCEEDED, CONFIRM_AWS_MARKETPLACE_SUBSCRIPTION_FAILED])
  }
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    call(watchConfirmAsync),
  ])
}