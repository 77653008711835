import { connect } from 'react-redux'

import { withData, withStateMappedToProps, DELEGATES, IS_AUTHENTICATED, LOADING_DETECTED } from '../../redux/withData'
import { addDelegate } from '../../redux/actions/delegateActions'

import TheComponent from './RoleManagement.js';

const requiredData = [IS_AUTHENTICATED, DELEGATES, LOADING_DETECTED]

const mapStateToProps = state => {
  return withStateMappedToProps(state, requiredData)
}

const mapDispatchToProps = { addDelegate }

export default connect(mapStateToProps, mapDispatchToProps)(withData(requiredData)(TheComponent))