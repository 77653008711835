import { connect } from 'react-redux'

import { withData, withStateMappedToProps, IS_AUTHENTICATED, USER_CONFIG_EULA_ACCEPTED, USER_CONFIG_NEW_EULA_ACCEPT_REQUIRED } from '../../redux/withData'

import TheComponent from './Eula.js';

import { acceptEula } from '../../redux/actions/userActions'
const requiredData = [IS_AUTHENTICATED, USER_CONFIG_EULA_ACCEPTED, USER_CONFIG_NEW_EULA_ACCEPT_REQUIRED]

const params = {}

const mapStateToProps = state => {
  return withStateMappedToProps(state, requiredData)
}

const mapDispatchToProps = {
    acceptEula,
}

export default connect(mapStateToProps, mapDispatchToProps)(withData(requiredData, params)(TheComponent))