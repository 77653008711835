import { connect } from 'react-redux'

import { withData, withStateMappedToProps, IS_AUTHENTICATED } from '../../../redux/withData'
import { removeDelegate, updateDelegatesRole } from '../../../redux/actions/delegateActions'

import TheComponent from './Delegate.js';

const requiredData = [IS_AUTHENTICATED]

const mapStateToProps = state => {
    return withStateMappedToProps(state, requiredData)
  }

const mapDispatchToProps = { removeDelegate, updateDelegatesRole }

export default connect(mapStateToProps, mapDispatchToProps)(withData(requiredData)(TheComponent))