import React from 'react'

import { store } from '../redux/store/store'

import { getAwsMarketplaceStatus, getAwsMarketplaceToken } from '../redux/selectors/awsMarketplaceSelectors'
import { loadCatalog } from '../redux/actions/catalogActions'
import { getCatalogItems } from '../redux/selectors/catalogSelectors'
import { loadRegions } from '../redux/actions/regionsActions'
import { getRegions } from '../redux/selectors/regionsSelectors'
import { loadFreshdeskToken } from '../redux/actions/freshdeskActions'
import { getFreshdeskAuthenticationToken } from '../redux/selectors/freshdeskSelectors'
import { loadSubscriptions } from '../redux/actions/subscriptionActions'
import { getSubscriptionItems } from '../redux/selectors/subscriptionSelectors'
import { loadUserConfig } from '../redux/actions/userConfigActions'
import { getUserConfigItems, getYaraUrlUpdateResult } from '../redux/selectors/userConfigSelectors'
import { loadUsagePlans } from '../redux/actions/usagePlanActions'
import { getUsagePlanItems } from '../redux/selectors/usagePlanSelectors'
import { getIsUserLoaded, getIsAuthenticated, getCurrentAccount, getUserRoles } from '../redux/selectors/userSelectors'
import { getErrorItems } from '../redux/selectors/errorSelectors'
import { detectLoading } from '../redux/selectors/selectors'
import { loadSecureContent } from '../redux/actions/secureContentActions'
import { getSecureContentPageContent } from '../redux/selectors/secureContentSelectors'
import { loadProducts } from '../redux/actions/productActions'
import { getProductItems } from '../redux/selectors/productSelectors'
import { getApiKeysForUsagePlan } from '../redux/selectors/apiKeySelectors'
import { getMfaCode } from '../redux/selectors/mfaSelector'
import { getFreeTrialApplication } from '../redux/selectors/freeTrialSelectors'
import { getPortalApiConfig } from '../redux/selectors/portalApiSelectors'
import { getDelegates } from '../redux/selectors/delegateSelectors'
import { loadDelegates } from '../redux/actions/delegateActions'

export const AWS_MARKETPLACE_STATUS = 'AWS_MARKETPLACE_STATUS'
export const AWS_MARKETPLACE_TOKEN = 'AWS_MARKETPLACE_TOKEN'
export const CATALOG = 'CATALOG'
export const FRESHDESK_AUTHENTICATION_TOKEN = 'FRESHDESK_AUTHENTICATION_TOKEN'
export const IS_AUTHENTICATED = 'IS_AUTHENTICATED'
export const SUBSCRIPTIONS = 'SUBSCRIPTIONS'
export const USER_CONFIG = 'USER_CONFIG'
export const USER_CONFIG_YARA_ENABLED = 'USER_CONFIG_YARA_ENABLED'
export const USER_CONFIG_YARA_URL = 'USER_CONFIG_YARA_URL'
export const USER_CONFIG_ENTERPRISE_ENABLED = 'USER_CONFIG_ENTERPRISE_ENABLED'
export const USER_CONFIG_MFA_ENABLED = 'USER_CONFIG_MFA_ENABLED'
export const USER_CONFIG_HAS_PRODUCTS = 'USER_CONFIG_HAS_PRODUCTS'
export const USER_CONFIG_HAS_PRODUCTS_FOR_AWS_MARKETPLACE = 'USER_CONFIG_HAS_PRODUCTS_FOR_AWS_MARKETPLACE'
export const USER_CONFIG_IS_AWS_MARKETPLACE_CUSTOMER = 'USER_CONFIG_IS_AWS_MARKETPLACE_CUSTOMER'
export const USER_CONFIG_ACCOUNTS = 'USER_CONFIG_ACCOUNTS'
export const USER_CONFIG_FREE_TRIAL_STATUS = 'USER_CONFIG_FREE_TRIAL_STATUS'
export const USER_CONFIG_EULA_ACCEPTED = 'USER_CONFIG_EULA_ACCEPTED'
export const USER_CONFIG_NEW_EULA_ACCEPT_REQUIRED = 'USER_CONFIG_NEW_EULA_ACCEPT_REQUIRED'
export const USAGE_PLANS = 'USAGE_PLANS'
export const LOADING_DETECTED = 'LOADING_DETECTED'
export const ERRORS = 'ERRORS'
export const IS_USER_LOADED = 'IS_USER_LOADED'
export const SECURE_CONTENT_PAGE_CONTENT = 'SECURE_CONTENT_PAGE_CONTENT'
export const PRODUCTS = 'PRODUCTS'
export const REGIONS = 'REGIONS'
export const API_KEYS = 'API_KEYS'
export const MFA = 'MFA'
export const ACCOUNT = 'ACCOUNT'
export const ROLES = 'ROLES'
export const USER_CONFIG_PORTAL_API = 'USER_CONFIG_PORTAL_API'
export const FREE_TRIAL = 'FREE_TRIAL'
export const PORTAL_API_CONFIG = 'PORTAL_API_CONFIG'
export const DELEGATES = 'DELEGATES'

export function withStateMappedToProps(state, requiredData, params) {
  let result = {}

  const isAuthenticated = getIsAuthenticated(state)

  if (requiredData.includes(AWS_MARKETPLACE_STATUS)) {
    const awsMarketplaceStatus = getAwsMarketplaceStatus()
    result = Object.assign(result, {awsMarketplaceStatus})
  }

  if (requiredData.includes(AWS_MARKETPLACE_TOKEN)) {
    const awsMarketplaceToken = getAwsMarketplaceToken()
    result = Object.assign(result, {awsMarketplaceToken})
  }

  if (requiredData.includes(CATALOG)) {
    const catalog = getCatalogItems(state)
    result = Object.assign(result, {catalog})
  }

  if (requiredData.includes(REGIONS)) {
    const regions = getRegions(state)
    result = Object.assign(result, {regions})
  }

  if (requiredData.includes(FRESHDESK_AUTHENTICATION_TOKEN)) {
    const freshdeskAuthenticationToken = getFreshdeskAuthenticationToken(state)
    result = Object.assign(result, {freshdeskAuthenticationToken})
  }

  if (requiredData.includes(SUBSCRIPTIONS)) {
    const subscriptions = getSubscriptionItems(state)
    result = Object.assign(result, {subscriptions})
  }

  if (requiredData.includes(DELEGATES)) {
    const delegates = getDelegates(state)
    result = Object.assign(result, delegates)
  }

  if (userConfigRequested(requiredData)) {
    const userConfig = getUserConfigItems(state)

    //always add it even if it isn't directly requested so we don't have to load it again
    result = Object.assign(result, {userConfig})


    // userConfig properties
    if (userConfig) {
      if (requiredData.includes(USER_CONFIG_MFA_ENABLED)) {
        const mfaEnabled = userConfig.mfaEnabled
        result = Object.assign(result, {mfaEnabled})
      }

      if (requiredData.includes(USER_CONFIG_HAS_PRODUCTS)) {
        const hasProducts = userConfig.hasProducts
        result = Object.assign(result, {hasProducts})
      }

      if (requiredData.includes(USER_CONFIG_HAS_PRODUCTS_FOR_AWS_MARKETPLACE)) {
        const hasProductsForAwsMarketplace = userConfig.hasProductsForAwsMarketplace
        result = Object.assign(result, {hasProductsForAwsMarketplace})
      }

      if (requiredData.includes(USER_CONFIG_IS_AWS_MARKETPLACE_CUSTOMER)) {
        const isAwsMarketplaceCustomer = userConfig.isAwsMarketplaceCustomer
        result = Object.assign(result, {isAwsMarketplaceCustomer})
      }

      if (requiredData.includes(USER_CONFIG_ACCOUNTS)) {
        const {accounts, defaultAccountEmail} = userConfig
        result = Object.assign(result, {accounts, defaultAccountEmail})
      }

      if (requiredData.includes(USER_CONFIG_FREE_TRIAL_STATUS)) {
        const freeTrialStatus = userConfig.freeTrialStatus
        result = Object.assign(result, {freeTrialStatus})
      }

      if (requiredData.includes(USER_CONFIG_EULA_ACCEPTED)) {
        const eulaAccepted = userConfig.eulaAccepted
        result = Object.assign(result, {eulaAccepted})
      }

      if (requiredData.includes(USER_CONFIG_NEW_EULA_ACCEPT_REQUIRED)) {
        const newEulaAcceptRequired = userConfig.newEulaAcceptRequired
        result = Object.assign(result, {newEulaAcceptRequired})
      }

      // userData properties
      if (userConfig.userData) {
        if (requiredData.includes(USER_CONFIG_YARA_ENABLED)) {
          const yaraEnabled = userConfig.userData.yaraEnabled
          result = Object.assign(result, {yaraEnabled})
        }
        if (requiredData.includes(USER_CONFIG_YARA_URL)) {
          const yaraApiUrl = userConfig.userData.yaraApiUrl
          const yaraUrlError = getYaraUrlUpdateResult(state)
          result = Object.assign(result, {yaraApiUrl, yaraUrlError})
        }
        if (requiredData.includes(USER_CONFIG_YARA_URL) && requiredData.includes(USER_CONFIG_YARA_ENABLED)) {
          const { yaraApiUrl, yaraEnabled } = result;
          const yaraConfigured = yaraEnabled && !!yaraApiUrl;
          result = Object.assign(result, {yaraConfigured})
        }
        if (requiredData.includes(USER_CONFIG_ENTERPRISE_ENABLED)) {
          const enterprise = userConfig.userData.enterprise
          result = Object.assign(result, {enterprise})
        }
        if (requiredData.includes(USER_CONFIG_PORTAL_API)) {
          const portalApiCognitoIdentityId = userConfig.userData.portalApiCognitoIdentityId
          result = Object.assign(result, {portalApiCognitoIdentityId})
        }
      }
    }


  }

  if (requiredData.includes(USAGE_PLANS)) {
    const usagePlans = getUsagePlanItems(state)
    result = Object.assign(result, {usagePlans})
  }

  if (requiredData.includes(LOADING_DETECTED)) {
    const loadingDetected = detectLoading(state)
    result = Object.assign(result, {loadingDetected})
  }

  if (requiredData.includes(ERRORS)) {
    const errors = getErrorItems(state)
    result = Object.assign(result, {errors})
  }

  if (requiredData.includes(IS_USER_LOADED)) {
    const isUserLoaded = getIsUserLoaded(state)
    result = Object.assign(result, {isUserLoaded})
  }

  if (requiredData.includes(SECURE_CONTENT_PAGE_CONTENT)) {
    const secureContent = getSecureContentPageContent(state, params.pageName)
    result = Object.assign(result, {secureContent})
  }

  if (requiredData.includes(API_KEYS)) {
    const apiKeys = getApiKeysForUsagePlan(state, params.usagePlanId)
    result = Object.assign(result, {apiKeys})
  }

  if (requiredData.includes(PRODUCTS)) {
    const products = getProductItems(state)
    result = Object.assign(result, {products})
  }

  if (requiredData.includes(IS_AUTHENTICATED)) {
    result = Object.assign(result, {isAuthenticated})
  }

  if (requiredData.includes(MFA)) {
    const mfaCode = getMfaCode(state);
    result = Object.assign(result, { mfaCode })
  }

  if (requiredData.includes(ACCOUNT)) {
    const account = getCurrentAccount(state)
    result = Object.assign(result, account)
  }

  if (requiredData.includes(ROLES)) {
    const userRoles = getUserRoles(state)
    result = Object.assign(result, { userRoles })
  }

  if (requiredData.includes(FREE_TRIAL)) {
    const freeTrialResult = getFreeTrialApplication(state)
    result = Object.assign(result, freeTrialResult)
  }

  if (requiredData.includes(PORTAL_API_CONFIG)) {
    const portalApiConfig = getPortalApiConfig(state);
    result = Object.assign(result, { portalApiConfig })
  }

  return result
}

export function withData(requiredData, params) {

  const loadData = ({catalog, freshdeskAuthenticationToken, isAuthenticated, subscriptions, userConfig, usagePlans, secureContent, products, regions, delegates}) => {
    if (requiredData.includes(CATALOG) && !catalog)
      if (params?.catalog?.onlyIfAuthenticated !== true || isAuthenticated)
        store.dispatch(loadCatalog())

    if (requiredData.includes(REGIONS) && !regions)
      store.dispatch(loadRegions())

    if (isAuthenticated) {
      if (requiredData.includes(FRESHDESK_AUTHENTICATION_TOKEN) && !freshdeskAuthenticationToken)
        store.dispatch(loadFreshdeskToken())

      if (requiredData.includes(SUBSCRIPTIONS) && !subscriptions)
        store.dispatch(loadSubscriptions())

      if (userConfigRequested(requiredData) && !userConfig)
        store.dispatch(loadUserConfig())

      if (requiredData.includes(USAGE_PLANS) && !usagePlans)
        store.dispatch(loadUsagePlans())

      if (requiredData.includes(SECURE_CONTENT_PAGE_CONTENT) && !secureContent)
        store.dispatch(loadSecureContent(params.pageName))

      if (requiredData.includes(PRODUCTS) && !products)
        store.dispatch(loadProducts())

      if (requiredData.includes(DELEGATES) && !delegates)
        store.dispatch(loadDelegates())
    }
  }

  return function (WrappedComponent) {
    // ...and returns another component...
    class WithData extends React.PureComponent {
      componentDidMount() {
        loadData(this.props)
      }
      componentDidUpdate() {
        loadData(this.props)
      }
      render() {
        // ... and renders the wrapped component with the fresh data!
        // Notice that we pass through any additional props
        return <WrappedComponent {...this.props} />;
      }
    }
    WithData.displayName = `WithData(${getDisplayName(WrappedComponent)})`;
    return WithData
  }
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

function userConfigRequested(requiredData) {
  return requiredData.filter( x => x.startsWith(USER_CONFIG)).length
}