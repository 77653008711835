import { connect } from 'react-redux'

import { withData, withStateMappedToProps, IS_AUTHENTICATED, IS_USER_LOADED } from '../../redux/withData'

import TheComponent from './SecureRoute.js';

const requiredData = [IS_AUTHENTICATED, IS_USER_LOADED]

const mapStateToProps = state => {
  return withStateMappedToProps(state, requiredData)
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(withData(requiredData)(TheComponent))