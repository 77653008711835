import {
  LOADING_ACCOUNT, SWITCH_ACCOUNT, ADMIN_SWITCH_ACCOUNT, CANCEL_SWITCH_ACCOUNT,
  SWITCH_ACCOUNT_SUCCEEDED, SWITCH_ACCOUNT_FAILED, SET_DEFAULT_ACCOUNT,
  CHANGING_DEFAULT_ACCOUNT, SET_DEFAULT_ACCOUNT_SUCCEEDED, SET_DEFAULT_ACCOUNT_FAILED,
  ACCEPT_EULA, CHANGING_ACCEPT_EULA, ACCEPT_EULA_SUCCEEDED, ACCEPT_EULA_FAILED,
} from '../actions/userActions'
import { all, call, fork, put, take } from 'redux-saga/effects'

import { switchAccount, adminSwitchAccount, setDefaultAccount, acceptEula } from '../../services/api-account'


export function* switchAccountAsync(admin, email) {
  yield put({ type: LOADING_ACCOUNT })
  try {
    const { accountId, role, isDefaultAccount } = yield call(admin ? adminSwitchAccount : switchAccount, email)
    yield put({ type: SWITCH_ACCOUNT_SUCCEEDED, payload: { accountId, role, email, admin, isDefaultAccount } })
  }
  catch (error) {
    yield put({ type: SWITCH_ACCOUNT_FAILED, error })
  }
}

export function* cancelSwitchAccountAsync() {
  yield put({ type: LOADING_ACCOUNT })
  yield put({ type: SWITCH_ACCOUNT_SUCCEEDED, payload: { accountId: null, email: null } })
}

export function* setDefaultAccountAsync({email}) {
  yield put({ type: CHANGING_DEFAULT_ACCOUNT })
  try {
    yield call(setDefaultAccount, email)
    yield put({ type: SET_DEFAULT_ACCOUNT_SUCCEEDED, payload: { email } })
  }
  catch (error) {
    yield put({ type: SET_DEFAULT_ACCOUNT_FAILED, error })
  }
}


export function* acceptEulaAsync() {
  yield put({ type: CHANGING_ACCEPT_EULA })
  try {
    yield call(acceptEula)
    yield put({ type: ACCEPT_EULA_SUCCEEDED })
  }
  catch (error) {
    yield put({ type: ACCEPT_EULA_FAILED, error })
  }
}


export function* watchSwitch() {
  while (true) {
    const action = yield take([SWITCH_ACCOUNT, ADMIN_SWITCH_ACCOUNT])
    yield fork(switchAccountAsync, action.type === ADMIN_SWITCH_ACCOUNT, action.payload)
    yield take([SWITCH_ACCOUNT_SUCCEEDED, SWITCH_ACCOUNT_FAILED])
  }
}


export function* watchCancel() {
  while (true) {
    yield take(CANCEL_SWITCH_ACCOUNT)
    yield fork(cancelSwitchAccountAsync)
    yield take([SWITCH_ACCOUNT_SUCCEEDED, SWITCH_ACCOUNT_FAILED])
  }
}


export function* watchSetDefault() {
  while (true) {
    const action = yield take(SET_DEFAULT_ACCOUNT)
    yield fork(setDefaultAccountAsync, action.payload)
    yield take([SET_DEFAULT_ACCOUNT_SUCCEEDED, SET_DEFAULT_ACCOUNT_FAILED])
  }
}


export function* watchAcceptEula() {
  while (true) {
    yield take(ACCEPT_EULA)
    yield fork(acceptEulaAsync)
    yield take([ACCEPT_EULA_SUCCEEDED, ACCEPT_EULA_FAILED])
  }
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    call(watchSwitch),
    call(watchCancel),
    call(watchSetDefault),
    call(watchAcceptEula),
  ])
}
