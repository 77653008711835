import React, { PureComponent } from 'react'
import { Container, Header, Icon, Message, Tab } from 'semantic-ui-react'
import Layout from '../../components/Layout'

export default class ApiAzure extends PureComponent {
  title = "Zero Trust CDR on Microsoft Azure"

  render() {
    const title = this.title

    const panes = [
        {
            menuItem: 'Instant API',
            render: () => <Tab.Pane>
                <ol>
                    <li>Login to the <a href="https://portal.azure.com/">Microsoft Azure Portal</a>. You can create a new Microsoft Azure account if necessary, or choose an existing one. Your Azure account must be linked to an active subscription in order to deploy this code sample. To do so, either add a payment method to your Azure account or activate a <a href="https://azure.microsoft.com/en-gb/free/" target="_blank" rel="noopener noreferrer">free trial subscription</a>.</li>
                    <li>
                        <p>Click this button to open the code sample template in the Azure Portal:</p>
                        <a href="https://portal.azure.com/#create/Microsoft.Template/uri/https%3A%2F%2Fraw.githubusercontent.com%2Fdeep-secure%2Fthreat-removal-instant-api-example-azure%2Fmain%2Fazuredeploy.json" target="_blank" rel="noopener noreferrer"><img src="https://aka.ms/deploytoazurebutton" alt="deploy Instant code sample"/></a>
                    </li>
                    <li>Select the appropriate subscription to manage the deployed resources and associated costs. Then, choose a resource group to deploy into. This can either be an existing resource group or a newly created one.</li>
                    <li>If you chose to create a new resource group, please select the region you would like the resources to be deployed into. If you chose an existing resource group, the region will be based on the location of the resource group you selected.</li>
                    <li>Enter a name for the function app resource. This can be anything you like, but the name must only contain alphanumerics and hyphens.</li>
                    <li>Get your API Service URL and API Key from the <a href="https://threat-removal.deep-secure.com/api/instant/manage" target="_blank" rel="noopener noreferrer">Instant API management page</a> and enter these values into the Azure deployment parameters.</li>
                    <li>Click the blue 'Review + create' button at the bottom of the page. The deployment will now undergo final validation checks. After the validation checks have passed, please read the Microsoft Azure terms that are on screen.</li>
                    <li>Once you have read the on-screen T&C's, click the blue 'Create' button to start the deployment of the code sample.</li>
                    <li>You will now be redirected to the deployment overview. The deployment should only take a couple of minutes. Once the deployment is complete, click the blue 'Go to resource group' button.</li>
                    <li>In the resource group, click on the <i>filestorexxxxxxxxxxxxx</i> storage account. Then, on the 'overview' page, in the 'Tools and SDKs' section, open 'Storage Explorer (preview)'.</li>
                    <li>
                        In Storage Explorer, open the blob containers dropdown:
                        <ul>
                            <li><i>input</i> - upload unsafe content here. The Azure Function then sends the unsafe content to the Instant API to be transformed.</li>
                            <li><i>transformed</i> - Safe content is uploaded to this blob container after a successful transformation.</li>
                            <li><i>errors</i> - If the unsafe content cannot be processed, a json file containing error information will be placed here.</li>
                        </ul>
                    </li>
                </ol>
                <p>You are now ready to upload some content to the Instant API.</p>
            </Tab.Pane>
        },
        {
            menuItem: 'Async API',
            render: () => <Tab.Pane>
                <ol>
                    <li>Login to the <a href="https://portal.azure.com/">Microsoft Azure Portal</a>. You can create a new Microsoft Azure account if necessary, or choose an existing one. Your Azure account must be linked to an active subscription in order to deploy this code sample. To do so, either add a payment method to your Azure account or activate a <a href="https://azure.microsoft.com/en-gb/free/" target="_blank" rel="noopener noreferrer">free trial subscription</a>.</li>
                    <li>
                        <p>Click this button to open the code sample template in the Azure Portal:</p>
                        <a href="https://portal.azure.com/#create/Microsoft.Template/uri/https%3A%2F%2Fraw.githubusercontent.com%2Fdeep-secure%2Fthreat-removal-async-api-example-azure%2Fmain%2Fazuredeploy.json" target="_blank" rel="noopener noreferrer"><img src="https://aka.ms/deploytoazurebutton" alt="deploy Async code sample" /></a>
                    </li>
                    <li>Select the appropriate subscription to manage the deployed resources and associated costs. Then, choose a resource group to deploy into. This can either be an existing resource group or a newly created one.</li>
                    <li>If you chose to create a new resource group, please select the region you would like the resources to be deployed into. If you chose an existing resource group, the region will be based on the location of the resource group you selected.</li>
                    <li>Enter a name for the function app resource. This can be anything you like, but the name must only contain alphanumerics and hyphens.</li>
                    <li>Get your API Service URL and API Key from the <a href="https://threat-removal.deep-secure.com/api/async/manage" target="_blank" rel="noopener noreferrer">Async API management page</a> and enter these values into the Azure deployment parameters.</li>
                    <li>Click the blue 'Review + create' button at the bottom of the page. The deployment will now undergo final validation checks. After the validation checks have passed, please read the Microsoft Azure terms that are on screen.</li>
                    <li>Once you have read the on-screen T&C's, click the blue 'Create' button to start the deployment of the code sample.</li>
                    <li>You will now be redirected to the deployment overview. The deployment should only take a couple of minutes. Once the deployment is complete, click the blue 'Go to resource group' button.</li>
                    <li>In the resource group, click on the <i>filestorexxxxxxxxxxxxx</i> storage account. Then, on the 'overview' page, in the 'Tools and SDKs' section, open 'Storage Explorer (preview)'.</li>
                    <li>
                        In Storage Explorer, open the blob containers dropdown:
                        <ul>
                            <li><i>input</i> - upload unsafe content here. The Azure Function then sends the unsafe content to the Async API to be transformed.</li>
                            <li><i>transformed</i> - Safe content is uploaded to this blob container after a successful transformation.</li>
                            <li><i>errors</i> - If the unsafe content cannot be processed, a json file containing error information will be placed here.</li>
                        </ul>
                    </li>
                </ol>
                <p>You are now ready to upload some content to the Async API.</p>
            </Tab.Pane>
        }
    ]

    return (
      <Layout header={`${title}`}>
        <Container>

            <Header as='h2'>Introduction</Header>
            <p>
                Our Instant and Async APIs can be utilised through Microsoft Azure. By following the setup instructions below, content can be uploaded to a storage container within Azure, sent to our Zero Trust CDR services in AWS and subsequently transformed. The safe content is then placed in another storage container in Azure for you to download.
                Currently, we do not support native Azure transformations. When using our Azure code samples, content leaves the Azure infrastructure and is sent to our AWS-hosted APIs for transformation. We are planning to support Microsoft Azure natively in the future.
            </p>
            <p>This code sample deploys a small amount of Microsoft Azure infrastructure. This includes:</p>
            <ul>
                <li>A storage account used to store input files, transformations and errors</li>
                <li>A Function App that uploads files from your storage account to your chosen API, then downloads the result</li>
                <li>Additional resources necessary for logging and billing within Microsoft Azure</li>
            </ul>
            <Message color='blue'>
                <Message.Header><Icon name='info circle' />Usage Information</Message.Header>
                <p>
                    These resources will be created in your own Microsoft Azure account, and usage will incur charges to your Microsoft Azure subscription as well as separate Zero Trust CDR API fees.
                    The Azure charges are primarily for Storage Accounts and data transfer. You can keep the storage charges to a minimum by removing data from the blob containers after use (input files are automatically deleted after transformation), as well as tearing down the deployed resources once they are no longer needed (See the 'Cleaning up' section).
                </p>
            </Message>
            <p>Microsoft Azure offer a free account which should cover any usage costs incurred by this code sample. You can find out more about this on <a href="https://azure.microsoft.com/en-gb/free/" target="_blank" rel="noopener noreferrer">their website</a>.</p>

            <Header as='h2'>Data residency</Header>
            <p>During the creation of the Azure deployment, you will be asked to choose a region for the infrastructure to be deployed into. It is important to consider the data residency requirements that your organisation may have.</p>
            <p>Our APIs can currently be deployed in Ireland, London and Oregon and are hosted on AWS. Keep this in mind when you choose a region during the Azure deployment. You can help meet data residency and compliance requirements by choosing regions for the API and Azure deployments that are within the same geographical area e.g. activating an API in the AWS region EU-West-2, and deploying the Azure infrastructure to UK South.</p>

            <Header as='h2'>Setup instructions for code samples</Header>
            <p>Use the tabs below to show the setup instructions for the API of your choice.</p>
            <Tab panes={panes} defaultActiveIndex={0} />


            <Header as='h2'>Usage instructions</Header>
            <ol>
               <li>In the <i>filestorexxxxxxxxxxxxx</i> storage account, open the <i>input</i> blob container.</li>
               <li>Upload a file to the <i>input</i> container via the Azure Portal (alternatively, this can be done via the <a href="https://docs.microsoft.com/en-us/azure/storage/blobs/storage-blobs-introduction" target="_blank" rel="noopener noreferrer">Azure REST API, CLI, Powershell etc.</a>)</li>
               <li>Refresh until the file disappears from the <i>input</i> container.</li>
               <li>Check the <i>transformed</i> container for your newly transformed file.</li>
               <li>If it is not there, check the <i>errors</i> container.</li>
            </ol>

            <Header as='h2'>Cleaning up</Header>
            <p>Follow these steps in order to remove the deployed Azure resources:</p>
            <ol>
                <li>Navigate to 'Resource Groups' in the <a href="https://portal.azure.com/" target="_blank" rel="noopener noreferrer">Azure portal</a>.</li>
                <li>Click on the resource group that contains your deployed resources</li>
                <li>Click 'Delete resource group'</li>
                <li>Click the blue 'Delete' button.</li>
            </ol>
          </Container>
      </Layout>)
  }
}