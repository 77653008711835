import { connect } from 'react-redux'

import { withData, withStateMappedToProps, AWS_MARKETPLACE_STATUS, AWS_MARKETPLACE_TOKEN, IS_AUTHENTICATED, USER_CONFIG_HAS_PRODUCTS_FOR_AWS_MARKETPLACE, USER_CONFIG_IS_AWS_MARKETPLACE_CUSTOMER } from '../../redux/withData'
import { confirmAwsMarketplaceSubscription, resetAwsMarketplaceStatus } from '../../redux/actions/awsMarketplaceActions'

import TheComponent from './AwsMarketplace.js';

const requiredData = [AWS_MARKETPLACE_STATUS, AWS_MARKETPLACE_TOKEN, IS_AUTHENTICATED, USER_CONFIG_HAS_PRODUCTS_FOR_AWS_MARKETPLACE, USER_CONFIG_IS_AWS_MARKETPLACE_CUSTOMER]

const mapStateToProps = state => {
  return withStateMappedToProps(state, requiredData)
}

const mapDispatchToProps = {
  confirmAwsMarketplaceSubscription,
  resetAwsMarketplaceStatus
}

export const showMarketplaceLinks = false

export default connect(mapStateToProps, mapDispatchToProps)(withData(requiredData)(TheComponent))