import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Container, Message, Icon, Header } from 'semantic-ui-react'
import { isProductEnabled } from '../../services/api-catalog'
import Layout from '../../components/Layout'
import ApiHeader from '../../components/ApiHeader'
import ApiChoiceTable from '../../components/ApiChoiceTable'

export default class ApiAsync extends PureComponent {
  static propTypes={
    location: PropTypes.object,
    catalog: PropTypes.array,
    usagePlans: PropTypes.array,
    isAuthenticated: PropTypes.bool,
  }
  state = {
    title: 'Async API',
  }

  checkProduct() {
    const {catalog, usagePlans, isAuthenticated} = this.props
    if (!catalog || !usagePlans || !isAuthenticated) return
    return isProductEnabled(catalog, usagePlans, this.getApiName())
  }

  getApiName = () => {
    const { location } = this.props
    return ((location || {}).pathname || '').replace(/^.*\/([^/]+)$/, '$1')
  }

  render() {
    const {catalog = [], usagePlans = [], location} = this.props
    const {title} = this.state
    const hasProduct = this.checkProduct()

    const ready = !!catalog.length
    return (
      <Layout header={`The ${title}`} loading={!ready}>
        <Container>


            <ApiHeader currentLocation={location} />

            { usagePlans.length && !hasProduct
              ? <Message warning>
                  <Message.Header><Icon name='lock'/>You are not subscribed to the {title}</Message.Header>
                  <p>If you need more access please <a href='https://www.forcepoint.com/product/zero-trust-cdr' target='_blank' rel='noopener noreferrer'>contact us</a>. If you have just subscribed it can take a few minutes for our records to be updated.</p>
                </Message>
              : null
            }

            <Header as='h2'>Why would I use the Async API?</Header>
            <p>The Async API supports file sizes up to 1.25&nbsp;GB and more complex content. It enables large files to be processed and allows more advanced scenarios such as uploading from one system and then downloading the safe content from a separate system.</p>

            <Header as='h2'>Should I use a different Zero Trust CDR API?</Header>

            <p>The Async API requires a few API calls, and highly complex content may take minutes to process. Using this API requires files to be temporarily stored in our <a href='https://aws.amazon.com/s3/' target='_blank' rel='noopener noreferrer'>Amazon S3 <Icon name='external' size='small'/></a> infrastructure. Due to the nature of this service files are stored on it for longer, and are accessible outside of the processing stage by design.</p>

            <Message icon color='blue'>
              <Icon name='info circle' />
              <Message.Content>
                When storing files in S3, we aim to remove input immediately after processing, and output about 10 minutes after completion. Failing this files are removed automatically within 48 hours.
              </Message.Content>
            </Message>

            <ApiChoiceTable highlight='async'/>

          </Container>
      </Layout>)
  }
}