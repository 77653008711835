import { connect } from 'react-redux'

import { withData, withStateMappedToProps, IS_AUTHENTICATED, SECURE_CONTENT_PAGE_CONTENT } from '../../redux/withData'

import TheComponent from './ContentTypesOfficeX.js'

const params = {pageName: 'officex'}

const requiredData = [IS_AUTHENTICATED, SECURE_CONTENT_PAGE_CONTENT]

const mapStateToProps = state => {
  return withStateMappedToProps(state, requiredData, params)
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(withData(requiredData, params)(TheComponent))