import React, { PureComponent } from 'react'
import Layout from '../../../components/Layout'
import { Container, Header } from 'semantic-ui-react'

export default class StructuredData extends PureComponent {

  render() {
    return (
      <Layout header='Risks of structured data files'>
        <Container>

          <Header as='h3'>Text files (poly/text)</Header>
          <p>
            JSON and XML are text files, and all text files have an inherent risk of being used to hide other data types.
            This is possible because the rules around what is regarded as a valid text file are intentionally very loose.
            For example, a zip archive containing a malicious payload could be labelled as a text file, and applications
            such as Notepad will open it and interpret the bytes as characters.
          </p>
          <p>
            A file that conforms to multiple different file formats is variously called a Polyformatted file or a Polyglot.
            To control the risk introduced by such files, the APIs introduce the "poly" risk.
          </p>
          <p>
            For text files, which can be literally anything masquerading as a simple text file, the poly/text risk
            applies. JSON and XML data is text so this risk applies - even though they are not arbitrary text, there is
            still a risk of unsafe data masquerading as them.
          </p>
          <p>
            By default, files with this risk are blocked, but if you are confident that you can rely on only ever treating
            these files as structured data, then you can allow this risk.
          </p>

          <Header as='h3'>Schemas (structured/no-schema)</Header>
          <p>
            Structured data files are typically used by other services, and whilst JSON and XML define the general structure
            of the file, the services consuming the data will require stricter rules around the actual data. Invalid or
            malicious data could cause unforeseen problems with your service.
          </p>
          <p>
            Schema validation allows for much more detailed constraints to be applied to the data being passed to your service.
            Currently schema validation is still in development, but in the meantime to allow structured data to be processed
            you can allow the structured/no-schema risk. If you do so, it is recommended that you disallow this risk when
            schema validation becomes available.
          </p>

        </Container>
      </Layout>
    )
  }
}
