/* global AWS */
import { CognitoUserPool } from 'amazon-cognito-identity-js'
import { CognitoAuth } from 'amazon-cognito-auth-js'

import { cognitoIdentityPoolId, cognitoUserPoolId, cognitoClientId, cognitoRegion, cognitoUserPoolDomain, websiteUrl } from './aws'
import { initApiGatewayClient, getApiGatewayClient } from './api'
import { store } from '../redux/store/store';
import {loadUserSuccess, loadUserFailed, logoutUserSuccess, loadingUser, loadAnonymousUserSuccess, defaultAccount} from '../redux/actions/userActions';
import {loadSubscriptions} from '../redux/actions/subscriptionActions'
import {loadUserConfig} from '../redux/actions/userConfigActions'
import {loadUsagePlans} from '../redux/actions/usagePlanActions'

const poolData = {
  UserPoolId: cognitoUserPoolId,
  ClientId: cognitoClientId
}

let cognitoUser
let userPool
const auth = initCognitoSDK();
// eslint-disable-next-line no-undef
const staticHostingAssumed = process.env.PUBLIC_URL !== ''
// eslint-disable-next-line no-undef
const homeUrl = staticHostingAssumed ? (process.env.PUBLIC_URL + '/index.html') : '/'


// Check which admin groups this user is a member of to get their permissions
export function hasPermission(permission) {
  if (!(cognitoUser && cognitoUser.signInUserSession && cognitoUser.signInUserSession.accessToken)) return;

  const cognitoGroups = cognitoUser.signInUserSession.accessToken.payload['cognito:groups'] || [];
  const permissions = permission ? (Array.isArray(permission) ? permission : [permission]) : null;

  return cognitoGroups.some(group => {
    const s = group.split(':');
    if (s[0] !== 'admin') return false;
    if (!permissions) return true;
    return permissions.some(p => p === s[1]);
  });
}

export function getEmail() {
  return (cognitoUser && cognitoUser.signInUserSession.idToken.payload.email) || '';
}

// MFA Step 1: Get the totp token to enter into the authenticator app
export async function initMFACode() {
  if (!cognitoUser) return null;

  return new Promise((resolve, reject) => {
    cognitoUser.associateSoftwareToken({
      onFailure: (err) => reject(err),
      associateSecretCode: (secretCode) => resolve(secretCode)
    });
  });
}

// MFA Step 2: Veryify the 6 digit pin code from the authenticator app
export async function verifyMFAToken(token, deviceName = 'Authenticator') {
  if (!cognitoUser) return null;

  return new Promise((resolve, reject) => {
    cognitoUser.verifySoftwareToken(token, deviceName, {
      onFailure: (err) => reject(err),
      onSuccess: (data) => resolve(data)
    });
  });
}

// MFA Step 3: Enable MFA on this user so they are challenged for the code on sign in
export async function enableMFA() {
  if (!cognitoUser) return null;

  return new Promise((resolve, reject) => {
    cognitoUser.setUserMfaPreference(null, {Enabled: true}, (err, result) => {
      if (err) return reject(err);
      if (result !== 'SUCCESS') return reject(new Error('Failed to enable MFA'));
      resolve(true);
    });
  });
}


export async function disableMFA() {
  if (!cognitoUser) return null;

  return new Promise((resolve, reject) => {
    cognitoUser.setUserMfaPreference(null, {Enabled: false}, (err, result) => {
      if (err) return reject(err);
      if (result !== 'SUCCESS') return reject(new Error('Failed to disable MFA'));
      resolve(true);
    });
  });
}

export function getIdentityId() {
  return (AWS.config.credentials || {}).identityId
}

function getCognitoLoginKey() {
  return `cognito-idp.${cognitoRegion}.amazonaws.com/${cognitoUserPoolId}`
}

// Initialize a cognito auth object.
function initCognitoSDK() {
  var authData = {
    ClientId : cognitoClientId,
    AppWebDomain : cognitoUserPoolDomain.replace(/^https?:\/\//, ''), // Exclude the "https://" part.
    TokenScopesArray : ['email', 'openid'],
    RedirectUriSignIn : websiteUrl,
    RedirectUriSignOut : websiteUrl,
    // IdentityProvider : '<TODO: your identity provider you want to specify here>',
    UserPoolId : cognitoUserPoolId,
    AdvancedSecurityDataCollectionFlag : false
  };
  var auth = new CognitoAuth(authData);
  auth.userhandler = {
    onSuccess: function(result) {

      if (!result || !result.state) {

        return
      }
      const state = JSON.parse(atob(decodeURIComponent(result.state)))
      Object.keys(state).forEach(key => localStorage.setItem(key, state[key]));
      if (state) localStorage.setItem('loginStateTimeout', Date.now()+ 15000);

      window.location = homeUrl
    },
    onFailure: function(err) {
      let message = 'Failed to connect to the authentication service';
      if (typeof(err) === 'string') {
        try {
          const result = JSON.parse(err);
          if (result && typeof(result.error) === 'string') {
            message = result.error;
          }
        }
        catch (e) {
          if (err) message = err;
        }
      } else if (err.message) {
        message = err.message;
      }

      const error = new Error('Login failed: ' + message);
      error.content = error.message;
      store.dispatch(loadUserFailed(error))
    }
  };
  // You can also set state parameter
  // auth.setState(<state parameter>);
  // The default response_type is "token", uncomment the next line will make it be "code".
  auth.useCodeGrantFlow();
  return auth;
}

// Get a login state var, these will timeout 15 seconds after login
export function getLoginState(state) {
  const timeout = localStorage.getItem('loginStateTimeout') || 0;
  if (Date.now() > timeout) return null;

  const val = localStorage.getItem(state);
  if (val == null) localStorage.removeItem(state);
  return val;
}

let awsCredentialsRefreshPromise = null

function refreshIfNeeded() {
  // return new Promise((resolve, reject) => {
    if (!cognitoUser || !(AWS?.config?.credentials?.needsRefresh()))
      return Promise.resolve()

    if (!awsCredentialsRefreshPromise) {
      store.dispatch(loadingUser())
      awsCredentialsRefreshPromise = getSessionPromise()
        .then(session => {
          if (!AWS.config.credentials.needsRefresh()) return

          AWS.config.credentials.params.Logins[getCognitoLoginKey()]  = session.getIdToken().getJwtToken()

          return AWS.config.credentials.refreshPromise()
            .then(() => {
              store.dispatch(loadUserSuccess(true))
              return
            })
        })
        .catch(err => {
          store.dispatch(loadUserFailed())
          logout()
          console.error(err)
        })
        .then(() => awsCredentialsRefreshPromise = null)
    }

    return awsCredentialsRefreshPromise
}

function getSessionPromise() {
  return new Promise((resolve, reject) => {
    cognitoUser.getSession((err, session) => {
      if (err) return reject(err)
      resolve(session)
    })
  })
}


export function init() {

  // this hates the #part so removed it
  var curUrl = window.location.origin + window.location.pathname + window.location.search;
  auth.parseCognitoWebResponse(curUrl);

  // attempt to refresh credentials from active session
  userPool = new CognitoUserPool(poolData)
  cognitoUser = userPool.getCurrentUser()


  if (cognitoUser !== null) {
    store.dispatch(loadingUser())

    getSessionPromise()
      .then(session => {
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: cognitoIdentityPoolId,
          Logins: { [getCognitoLoginKey()]: session.getIdToken().getJwtToken() }
        })

        return getApiGatewayClientForSession().then(apiGatewayClient =>
          apiGatewayClient.post('/signin', {}, {}, {})
        ).then(({ data }) => {
          if (data.defaultAccount) {
            const { accountId, role, email } = data.defaultAccount;
            store.dispatch(defaultAccount(accountId, role, email))
          }
          if (data.changesMade || data.defaultAccount) {
            store.dispatch(loadSubscriptions());
            store.dispatch(loadUsagePlans());
            store.dispatch(loadUserConfig());
          }
        })

      })
      .catch(err => {
        store.dispatch(loadUserFailed())
        logout()
        console.error(err)
      })

  } else {
    store.dispatch(loadAnonymousUserSuccess())
    initApiGatewayClient()
  }
}

export function loginHosted(state, register) {
  if (cognitoUser) {
    store.dispatch(logoutUserSuccess())
    cognitoUser.signOut()
    localStorage.clear()
    cognitoUser = null
  }

  auth.setState(btoa(JSON.stringify(state)))
  if (register) {
    const getFQDNSignInOriginal = auth.getFQDNSignIn
    auth.getFQDNSignIn = function() {
      const url = getFQDNSignInOriginal.call(this)
      auth.getFQDNSignIn = getFQDNSignInOriginal
      return url.replace(auth.getCognitoConstants().DOMAIN_PATH_SIGNIN, 'signup')
    }
  }
  return auth.getSession() === undefined
}

export function logout() {
  store.dispatch(logoutUserSuccess())
  cognitoUser.signOut()
  localStorage.clear()
  cognitoUser = null
  auth.signOut()
}

// Causes a log out then log in with the supplied state
export function relog(state, admin) {
  store.dispatch(logoutUserSuccess())
  cognitoUser.signOut()
  localStorage.clear()
  cognitoUser = null


  if (admin) {
    const scopes = auth.signInUserSession.getTokenScopes().tokenScopes;
    if (!scopes.includes('aws.cognito.signin.user.admin')) {
      scopes.push('aws.cognito.signin.user.admin');
      auth.signInUserSession.tokenScopes.setTokenScopes(scopes);
    }
  }

  const _state = btoa(JSON.stringify(state));
  auth.setState(_state);

  const signinUrl = auth.getFQDNSignIn().split('?');
  const signoutUrl = auth.getFQDNSignOut().split('?');
  const url = `${signoutUrl[0]}?${signinUrl[1]}`;

  auth.signInUserSession = null;
  auth.clearCachedTokensScopes();
  auth.launchUri(url);
}

export function getApiGatewayClientForSession() {
  return refreshIfNeeded()
    .then(() => initApiGatewayClient(AWS.config.credentials || {}))
    .then(() => getApiGatewayClient())
}

export async function simpleAuthenticatedGetRequest(path) {
  const apiGatewayClient = await getApiGatewayClientForSession()
  const {data} = await apiGatewayClient.get(path, {}, {}, {})
  return data
}

export function getHomeUrl() { return homeUrl }
