import { createStore, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'
import { reducers } from '../reducers/reducers'

import rootSaga from '../sagas/sagas'

const persistConfig = {
  key: 'ds-redux-store',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  whitelist: [  ]
}

const sagaMiddleware = createSagaMiddleware()

export const store = createStore(
  persistReducer(persistConfig, reducers),
  applyMiddleware(sagaMiddleware)
)

export const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)
