import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Message } from 'semantic-ui-react'
import SignIn from '../../components/SignIn'


// const style = {
//   padding: '0 1rem 1rem 1rem'
// }

export default class AlertPopup extends PureComponent {
  static propTypes={
    clearError: PropTypes.func,
    errors: PropTypes.array
  }
  state = {}

  handleDismissIndexes = (indexes) => {
    const { clearError } = this.props
    indexes.map(i => clearError(i))
  }

  getResponse = (obj) => {
    if (obj.response)
      return obj.response
    if (obj.error && obj.error.response)
      return obj.error.response

    return {}
  }

  getContent = (obj) => {
    let content = 'Error loading - please refresh and try again'
    let permissionError = false
    if (typeof(obj.content) === 'string')
      content = obj.content
    else {
      const response = this.getResponse(obj)
      const message = (response.data || response).message
      if (message) {
        if (response.status === 403) {
          permissionError = true
          content = `${message} - please login and try again`
        } else if (response.status === 429) {
          content = `${message} - temporary throttling error, please retry`
        } else {
          content = message
        }
      }
    }

    return {content, permissionError}
  }

  prepareErrors() {
    const { errors } = this.props
    let showLogin = false
    const formattedErrors = (errors || [])
      .map((x, index) => {
        const {content, permissionError} = this.getContent(x)
        if (permissionError) showLogin = true
        return {
          index,
          indexes: [],
          header: x.header,
          content,
        }
      })
      .reduce((result, x) => {
        const existingItem = result.find(y => y.header === x.header && y.content === x.content)
        const item = existingItem || x
        item.indexes.push(x.index)

        if (!existingItem) result.push(item)
        return result
      }, [])

    return { errors: formattedErrors, showLogin }
  }

  render() {
      const { errors, showLogin } = this.prepareErrors()

      if (!errors.length) return null

      return (
        <div>

          {errors.map(x =>
          <Message
            key={`error_${x.index}`}
            onDismiss={() => this.handleDismissIndexes(x.indexes)}
            negative
            floating
            icon='warning sign'
            header={x.header || 'There was an error'}
            content={x.content}
          />
          )}

          { showLogin ? <SignIn /> : null }
        </div>
      )
  }
}
