import React, { PureComponent } from 'react'
import Layout from '../../components/Layout'
import { Container, Table, Header } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter'
import { tomorrow as SyntaxStyle}  from 'react-syntax-highlighter/dist/cjs/styles/prism'

export default class Risks extends PureComponent {

  render() {
    return (
      <Layout header='Managing risks'>
        <Container>
          <Header as='h2'>Why risks?</Header>
          <p>
            Transforming data makes it safe, but it only works for static data.
            It's not possible to transform active content – code – so it becomes safe, whether it is an executable file, script or macros within some other document.
            The only completely safe thing to do with code is not bring it into your system, and by default this is what the APIs do – they block all code.
          </p>
          <p>
            But this tough approach can have an unwanted impact on the usability of the content - for example the loss of a macro in an important spreadsheet.
            If your system has measures in place to handle the risk posed by macros, you can tell the service to deliver the transformed document with the macros preserved inside it.
            The service is making sure the document will open safely, but it leaves it to your additional measures to prevent the macros doing anything malicious.
          </p>
          <p>
            You need to take positive action to get the service to deliver macro enabled documents – by default all macros are discarded – and so explicitly accept the risk they pose.
          </p>
          <p>
            There are other risks posed by some content which future versions of the service will allow you to control.
            Currently it is just the risks below that are supported, but other risks will be controlled in a similar way.
          </p>

          <Header as='h3'>How to allow a risk</Header>
          <p>
            The APIs can be told to 'allow' particular risks.
            The supplied list of allowed risks is used to customise the transformation to suit your particular risk profile.
          </p>
          <p>
            The risks are categorised in a hierarchical structure, and can be allowed (and denied) at any level.
            If a high level risk is allowed, this also applies to all the descendants of that risk and allows them too.
          </p>
          <p>
            Risks are allowed by specifying the risks.allow property in the options object for the
            <Link to='/api/async/definition'> Async </Link>
            and
            <Link to='/api/s3/definition'> S3 </Link>
            APIs, or the X-Options header for the
            <Link to='/api/instant/definition'> Instant </Link>
            API.
          </p>
          <SyntaxHighlighter language='json' style={SyntaxStyle}>
            {
            '{\n' +
            '  "options": {\n' +
            '    "risks": {\n' +
            '      "allow": ["exe/macros/ms/excel"]\n' +
            '    }\n' +
            '  }\n' +
            '}'
            }
          </SyntaxHighlighter>

          <Header as='h3'>How to deny a risk</Header>
          <p>
            No risks are allowed by default, but it is possible to explicitly deny a risk to make it clear that the risk is not being accepted and to prevent the risk from ever being accidentally accepted.
            Also, risks can be explicitly denied to partly override a risk that has been allowed at a high level.
            So it is possible to allow all of some category of risks, except for some more specific case.
          </p>

          <p>
            The following example shows how to allow all Microsoft macros, except in those found Excel.
          </p>
          <SyntaxHighlighter language='json' style={SyntaxStyle}>
            {
            '{\n' +
            '  "options": {\n' +
            '    "risks": {\n' +
            '      "deny": ["exe/macros/ms/excel"],\n' +
            '      "allow": ["exe/macros/ms"]\n' +
            '    }\n' +
            '  }\n' +
            '}'
            }
          </SyntaxHighlighter>

          <Header as='h3'>Reporting risks taken</Header>
          <p>
            In some cases the APIs can selectively report back to you whether the risk you allowed was relevant to the content you submitted.
            For example suppose you accept the risk 'exe/macro/ms/powerpoint' - macro enabled PowerPoint presentations.
            Whether you submit a .PPTM or a .PNG file, the file will be transformed and delivered, but the risk will only have been taken if it was a .PPTM file.
            In this easy case the APIs can work out whether the risk was taken, but in more complex cases they cannot tell so the APIs would always report such risks as having been taken.
          </p>
          <p>
            To allow you to take any appropriate measures, information about what risks have been taken when a file is processed is stored as an alphabetically ordered array in the status object for the S3 and Async APIs, and as a CSV in an HTTP header for the Instant API.
            You should pay attention to this list, even if you don't currently allow any risks.
            This will allow you to reject the file if it has risks taken that you weren't expecting.
          </p>
          <p>
            Note that the risks are reported even if the risky content is embedded in something that isn't risky.
            For example, a .PPTM file can be embedded inside a .DOCX.
            If you accept the risk of macros in PowerPoint, the file will be delivered and the risk noted, even if you don't accept the risk of macros in Word documents.
          </p>

          <Header as='h3'>Controlling risks wih the S3 API</Header>
          <p>
            The S3 API goes a step further than the other APIs and allows control over where risky content can be delivered. Further details are available in the <Link to='/api/s3/documentation'>S3 API Documentation</Link>.
          </p>

          <Header as='h3'>Fallback format</Header>
          <p>
            Where a macro risk is not taken, the format is changed to a fallback format that is not risky.
            For example, submitting a .DOCM without accepting the macro risk will result in the macros being discarded and a .DOCX being delivered.
            The fallback formats are listed in the table of Available Risks given below.
          </p>

          <Header as='h2'>Available risks</Header>
          <p>
            The supported risks are listed in the table below. The name of the risk is given along with the content type of the data it applies to.
          </p>
          <Table celled selectable>
            <Table.Header>
            <Table.Row textAlign='center'>
                <Table.HeaderCell>Risk</Table.HeaderCell>
                <Table.HeaderCell>Applies to</Table.HeaderCell>
                <Table.HeaderCell>Details</Table.HeaderCell>
                <Table.HeaderCell>Fallback</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>exe/macro/ms/excel<br/>exe/macro/ms/excel/version:id</Table.Cell>
                <Table.Cell>application/vnd.ms-excel.sheet.macroEnabled.12<br/>application/vnd.ms-excel.template.macroEnabled.12<br/>application/vnd.ms-excel.addin.macroEnabled.12</Table.Cell>
                <Table.Cell>Preserve macros in Microsoft Excel spreadsheets. See information on <Link to='/documentation/risks/macros'>Microsoft Office macros</Link>.</Table.Cell>
                <Table.Cell>XLSX</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>exe/macro/ms/powerpoint<br/>exe/macro/ms/powerpoint/version:id</Table.Cell>
                <Table.Cell>application/vnd.ms-powerpoint.presentation.macroEnabled.12<br/>application/vnd.ms-powerpoint.template.macroEnabled.12<br/>application/vnd.ms-powerpoint.addin.macroEnabled.12<br/>application/vnd.ms-powerpoint.slideshow.macroEnabled.12</Table.Cell>
                <Table.Cell>Preserve macros in Microsoft PowerPoint presentations. See information on <Link to='/documentation/risks/macros'>Microsoft Office macros</Link>.</Table.Cell>
                <Table.Cell>PPTX</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>exe/macro/ms/word<br/>exe/macro/ms/word/version:id</Table.Cell>
                <Table.Cell>application/vnd.ms-word.document.macroEnabled.12<br/>application/vnd.ms-word.template.macroEnabled.12</Table.Cell>
                <Table.Cell>Preserve macros in Microsoft Word documents. See information on <Link to='/documentation/risks/macros'>Microsoft Office macros</Link>.</Table.Cell>
                <Table.Cell>DOCX</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>links/remove/all</Table.Cell>
                <Table.Cell>application/pdf<br/>message/rfc822<br/>text/html<br/>Microsoft Office formats (all)</Table.Cell>
                <Table.Cell>We normally try to only allow safe automatic and clickable links through transformation. What we consider a safe type of link varies for each format and where it is found within it. For example "https://" links are safe in most text content, but links with the "file://" scheme are considered problematic. Allowing this risk tries to remove all content links regardless of the scheme. This is considered risky as it can break normal usability expectations, some links may remain and despite our removal of a link, some applications automatically make them clickable again anyway.</Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>poly/text/xml</Table.Cell>
                <Table.Cell>application/xml</Table.Cell>
                <Table.Cell>Allow XML documents, even though they are text that could also be interpreted as an alternative file format, see <Link to='/documentation/risks/structured-data'>Risks of structured data files</Link>.</Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>poly/text/json</Table.Cell>
                <Table.Cell>application/json</Table.Cell>
                <Table.Cell>Allow JSON documents, even though they are text that could also be interpreted as an alternative file format, see  <Link to='/documentation/risks/structured-data'>Risks of structured data files</Link>.</Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>preview/audio/x-wav</Table.Cell>
                <Table.Cell>audio/x-wav</Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>preview/audio/mpeg</Table.Cell>
                <Table.Cell>audio/mpeg</Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>preview/application/ms-tnef</Table.Cell>
                <Table.Cell>application/ms-tnef</Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>preview/application/vnd.ms-outlook</Table.Cell>
                <Table.Cell>application/vnd.ms-outlook</Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>preview/application/zip</Table.Cell>
                <Table.Cell>application/zip</Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>preview/image/heif</Table.Cell>
                <Table.Cell>image/heif</Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>preview/message/rfc822</Table.Cell>
                <Table.Cell>message/rfc822</Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>preview/video/mp4</Table.Cell>
                <Table.Cell>video/mp4</Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>preview/text/css</Table.Cell>
                <Table.Cell>text/css</Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>structured/no-schema/xml</Table.Cell>
                <Table.Cell>application/xml</Table.Cell>
                <Table.Cell>Allow XML documents without schema validation, see <Link to='/documentation/risks/structured-data'>Risks of structured data files</Link>.</Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>structured/no-schema/json</Table.Cell>
                <Table.Cell>application/json</Table.Cell>
                <Table.Cell>Allow JSON documents without schema validation, see <Link to='/documentation/risks/structured-data'>Risks of structured data files</Link>.</Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>steg/image/jpeg</Table.Cell>
                <Table.Cell>image/jpeg</Table.Cell>
                <Table.Cell rowSpan={8}>Allow images that have not had steganography disruption applied to them, see <Link to='/documentation/risks/image-quality'>Risks of preserving image quality</Link>.</Table.Cell>
                <Table.Cell rowSpan={8}></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>steg/image/jpeg2k</Table.Cell>
                <Table.Cell>image/jp2</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>steg/image/jxr</Table.Cell>
                <Table.Cell>image/jxr</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>steg/image/bmp</Table.Cell>
                <Table.Cell>image/bmp</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>steg/image/gif</Table.Cell>
                <Table.Cell>image/gif</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>steg/image/png</Table.Cell>
                <Table.Cell>image/png</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>steg/image/tiff</Table.Cell>
                <Table.Cell>image/tiff</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>steg/image/webp</Table.Cell>
                <Table.Cell>image/webp</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>steg/image/pdf</Table.Cell>
                <Table.Cell>application/pdf</Table.Cell>
                <Table.Cell>Allow pdf documents with images that have not had steganography disruption applied to them, see <Link to='/documentation/risks/image-quality'>Risks of preserving image quality</Link>.</Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>


          <Header as='h3'>Why am I seeing an unexpected risk taken?</Header>

          <p>
            The risk taken may not appear immediately relevant to the data you have submitted.
            For example, if you submit a .DOCX with allow set to "exe/macro/ms" and you receive a response with a risk taken of "exe/macro/ms/excel".
          </p>
          <p>
            This can happen when the content format is what we refer to as a "container" type - one which can hold other types of data embedded inside.
            The APIs transform embedded content as well, leading to the above allowed risk being taken. If this isn't the desired behaviour in your situation, then you can be more specific with your risks.
          </p>


        </Container>
      </Layout>
    )
  }
}
