import { ENABLE_MFA, ENABLE_MFA_SUCCEEDED, ENABLE_MFA_FAILED,
         VERIFY_MFA, VERIFY_MFA_SUCCEEDED, VERIFY_MFA_FAILED,
         DISABLE_MFA, DISABLE_MFA_SUCCEEDED, DISABLE_MFA_FAILED,
         LOADING_MFA } from '../actions/mfaActions'
import { all, call, fork, put, take } from 'redux-saga/effects'

import { initMFACode, verifyMFAToken, enableMFA, disableMFA } from '../../services/self'


export function* enableAsync() {
  yield put({type: LOADING_MFA})
  try {
    const mfaCode = yield call(initMFACode)
    yield put({type: ENABLE_MFA_SUCCEEDED, payload: mfaCode})
  }
  catch (error) {
    yield put({type: ENABLE_MFA_FAILED, error: mfaError(error, 'Failed to generate authentication code')})
  }
}

export function* verifyAsync(payload) {
  yield put({type: LOADING_MFA})
  try {
    yield call(verifyMFAToken, payload)
    yield call(enableMFA)
    yield put({type: VERIFY_MFA_SUCCEEDED})
  }
  catch (error) {
    if (error?.message.startsWith('Code mismatch')) error.message = 'Invalid verification code';
    yield put({type: VERIFY_MFA_FAILED, error: mfaError(error, 'Failed to verify 2FA') })
  }
}

export function* disableAsync() {
  yield put({type: LOADING_MFA})
  try {
    yield call(disableMFA)
    yield put({type: DISABLE_MFA_SUCCEEDED})
  }
  catch (error) {
    yield put({type: DISABLE_MFA_FAILED, error: mfaError(error, 'Failed to disable 2FA') })
  }
}


const mfaError = (error = {}, defaultMessage) => {
  return {
    code: error.code || 'Unknown',
    response: {
      message: error.message || defaultMessage
    }
  }
}


export function* watchEnable() {
  while (true) {
    yield take(ENABLE_MFA)
    yield fork(enableAsync)
    yield take([ENABLE_MFA_SUCCEEDED, ENABLE_MFA_FAILED])
  }
}
export function* watchVerify() {
  while (true) {
    const action = yield take(VERIFY_MFA)
    yield fork(verifyAsync, action.payload)
    yield take([VERIFY_MFA_SUCCEEDED, VERIFY_MFA_FAILED])
  }
}
export function* watchDisable() {
  while (true) {
    yield take(DISABLE_MFA)
    yield fork(disableAsync)
    yield take([DISABLE_MFA_SUCCEEDED, DISABLE_MFA_FAILED])
  }
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    call(watchVerify),
    call(watchEnable),
    call(watchDisable)
  ])
}