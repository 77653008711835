import { connect } from 'react-redux'

import { withData, withStateMappedToProps, IS_AUTHENTICATED, USER_CONFIG_FREE_TRIAL_STATUS } from '../../redux/withData'

import TheComponent from './GettingStarted.js';

const requiredData = [IS_AUTHENTICATED, USER_CONFIG_FREE_TRIAL_STATUS]

const mapStateToProps = state => {
  return withStateMappedToProps(state, requiredData)
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(withData(requiredData)(TheComponent))