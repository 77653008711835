import { CONFIRM_AWS_MARKETPLACE_SUBSCRIPTION_SUCCEEDED, LOADING_AWS_MARKETPLACE_SUBSCRIPTION, CONFIRM_AWS_MARKETPLACE_SUBSCRIPTION_FAILED } from '../actions/awsMarketplaceActions'

function awsMarketplaceReducer(existing = {}, action) {
  switch(action.type) {
    case CONFIRM_AWS_MARKETPLACE_SUBSCRIPTION_SUCCEEDED:
      return {
        items: action.payload
      }
    case LOADING_AWS_MARKETPLACE_SUBSCRIPTION:
      return {
        ...existing,
        loading: true,
      }
    case CONFIRM_AWS_MARKETPLACE_SUBSCRIPTION_FAILED:
      return {
        ...existing,
        loading: false,
      }

    default:
      return existing
  }
}

export default awsMarketplaceReducer