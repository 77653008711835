import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Link, Redirect } from 'react-router-dom'
import { Card, Container, Embed, Grid, Header, Icon, Image, Menu, Message, Segment, Button, Label, Ref } from 'semantic-ui-react'
import Layout from '../../components/Layout'
// import Register from '../components/Register'
import { getLoginState, loginHosted } from '../../services/self'
import './Home.css'
import FreeTrialApplication from '../../components/FreeTrialApplication'
import ScrollButton from '../../components/ScrollButton'
import LinkButton from '../../components/LinkButton'

import apiImage from './images/api.png'
import apiFlowChart from './images/api-flow-chart.png'
import videoImage from './images/video-preview.jpg'
import oldVideoImage from './images/video_image.jpg'
import whatIsCtrImage from './images/what-is-threat-removal.png'
import useCasesImage from './images/resources-solutionsbriefs-nav.png'

export default class HomePage extends PureComponent {
  state = {}

  static propTypes={
    catalog: PropTypes.array,
    usagePlans: PropTypes.array,
    subscriptions: PropTypes.array,
    yaraEnabled: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    freeTrialStatus: PropTypes.string,
    userConfig: PropTypes.object
  }

  componentDidMount() {
    // Only show the popup if we are not already signed up and the page isn't about to refresh
    if (localStorage.getItem('freeTrialSignup') ) {
        this.setState({ freeTrialSignup: true });
    }
  }

  componentDidUpdate() {
    this.componentDidMount()
  }

  // React Refs for scrollToRef method (see ScrollButton component)
  navBarRef = React.createRef()
  wideContentAfterLightRef = React.createRef()
  wideContentAfterDarkRef = React.createRef()
  getStartedCardRef = React.createRef()

  toggleButtonVisibility(button) {
    this.setState({
      activeScrollButton: button
    })

  }
  handleRegisterHosted = (event) => this._handleLoginHosted(event, true)

  _handleLoginHosted(event, register) {
    event.preventDefault()
    loginHosted({marketplace: true, freeTrialSignup: true}, register)
  }

  prepareDemos(catalog, subscriptions, usagePlans) {
    const subscriptionIds = subscriptions.map(s => s.id)
    if (catalog?.length && usagePlans?.length) {
      const activeApis = catalog
        .filter(x => x.usagePlans && x.usagePlans.length)
        .map(x => ({ apiName: x.apiName, usagePlanIds: x.usagePlans.filter(y => usagePlans.includes(y.id)).map(y => y.id) }))
        .reduce((obj, item) => {
          if (item.usagePlanIds.length) {
            const subscribed = item.usagePlanIds.some(id => subscriptionIds.includes(id));
            obj[item.apiName] = `/api/${item.apiName}/${subscribed ? 'demo' : 'manage'}`
          }
          return obj
        }, {})
      if (Object.keys(activeApis).length) {
        return { demo: true, demoApis: activeApis }
      }
    }
    return {demo:false}
  }

  handleShowFreeTrialSignup = () => this.setState({ freeTrialSignup: true })
  handleCloseFreeTrialSignup = () => {
    this.setState({ freeTrialSignup: false });
    localStorage.removeItem('freeTrialSignup');
  }

  demoNow = () => {
    const { isAuthenticated, catalog, subscriptions = [], yaraEnabled, usagePlans } = this.props
    if (!isAuthenticated) return null
    const { demo, demoApis } = this.prepareDemos(catalog, subscriptions, usagePlans)

    return !demo ? null
      : <div className='demo'>
          <Message info compact>
            <Message.Header>
              Start using Zero Trust CDR services straight away
            </Message.Header>
            <Message.Content>
              <br/>
              <Menu compact stackable>
                { demoApis.instant ? <Menu.Item as={Link} to={demoApis.instant}><Icon name='cog' />Instant API Demo</Menu.Item> : null }
                { demoApis.instant && !demoApis.async && !demoApis.s3 ? <Menu.Item as={Link} to='/api/instant/manage'><Icon name='cog' />API Integration</Menu.Item> : null}
                { demoApis.async ? <Menu.Item as={Link} to={demoApis.async}><Icon name='cogs' />Async API Demo</Menu.Item> : null }
                { demoApis.s3 ? <Menu.Item as={Link} to={demoApis.s3}><Icon name='aws' />S3 API Demo</Menu.Item> : null }
                { yaraEnabled ? <Menu.Item as={Link} to='/yara-dashboard'><Icon name='dashboard' />YARA Results</Menu.Item> : null }
              </Menu>
            </Message.Content>
          </Message>
        </div>
  }

  showFreeTrialSignup = () => {
    const { freeTrialStatus, userConfig } = this.props;
    const { freeTrialSignup=false } = this.state
    if (typeof userConfig === 'undefined' || freeTrialStatus) return null;

    return (
      <div className='freetrial-signup'>
        <FreeTrialApplication open={freeTrialSignup} onClose={this.handleCloseFreeTrialSignup}
            trigger={<Button color='green' onClick={this.handleShowFreeTrialSignup}>Apply for a free trial</Button>}
        />
      </div>
    )
  }

  showSignInOrRegister = () => {
    return (
      <div className='freetrial-signup'>
        <Container>
          <Message compact>
            <Message.Content>
              <p>Zero Trust CDR as a Service (CDRaaS) is the easy way to ensure data handled by portals, workflows and web applications is 100% threat free.</p>
              <Button onClick={this.handleRegisterHosted} color='green' size='big'>Start 30 day free trial</Button>
            </Message.Content>
          </Message>
        </Container>
      </div>
    )
  }

  wideContent = () => {
    const { isAuthenticated, usagePlans = [] } = this.props;

    // Contains the ref of the section containing the active ScrollButton, all other buttons are hidden
    const { activeScrollButton = null } = this.state;

    return <div className='background'>
        { isAuthenticated
          ? usagePlans.length > 0
            ? this.demoNow()
            : this.showFreeTrialSignup()
          : this.showSignInOrRegister()
        }

        <Ref innerRef={this.navBarRef}>
          <Container textAlign='center' style={{paddingTop: '12vh'}}>
            <div className='headers'>
            </div>
          </Container>
        </Ref>
        { this.normalContentApis(activeScrollButton, this.navBarRef, this.wideContentAfterLightRef) }
        { this.wideContentAfterLight(activeScrollButton, this.wideContentAfterLightRef, this.wideContentAfterDarkRef) }
        { this.wideContentAfterDark(activeScrollButton, this.wideContentAfterDarkRef, this.getStartedCardRef) }
        { this.getStartedCard(this.getStartedCardRef, this.navBarRef) }
      </div>
  }

  labelStyle={zIndex: 100}

  normalContentApis = (activeScrollButton, currentScrollRef, nextScrollRef) => {
    const { isAuthenticated, catalog, subscriptions = [], usagePlans } = this.props
    const { demoApis } = this.prepareDemos(catalog, subscriptions, usagePlans)
    return <Container textAlign='center' className='apis' onMouseOver={() => this.toggleButtonVisibility(currentScrollRef)} onMouseOut={() => this.toggleButtonVisibility(null)}>
      <Grid stackable columns='equal'>
        <Grid.Row stretched>
          <Grid.Column>
            <Card fluid>
              <Card.Content>
                { demoApis?.instant && isAuthenticated && <Link to='/api/instant/manage'><Label color='green' attached='top right' size='large' style={this.labelStyle}>Get your API key</Label></Link> }
                { !isAuthenticated && <Link to='/api/instant'><Label color='green' attached='top right' size='large'>Try for free!</Label></Link> }
                <Link to='/api/instant'><Image inline src={apiImage}  alt='CDRaaS Instant API' size='tiny' /></Link>
                <Card.Header><Link to='/api/instant'>CDRaaS Instant API</Link></Card.Header>
                The CDRaaS Instant API enables simple uploading and immediate downloading and doesn't require any additional infrastructure
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card fluid>
              <Card.Content>
                { demoApis?.async && isAuthenticated && <Link to='/api/async/manage'><Label color='green' attached='top right' size='large' style={this.labelStyle}>Get your API key</Label></Link> }
                <Link to='/api/async'><Image inline src={apiImage} alt='CDRaaS Async API' size='tiny' /></Link>
                <Card.Header><Link to='/api/async'>CDRaaS Async API</Link></Card.Header>
                The CDRaaS Async API enables large files to be processed
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card fluid>
              <Card.Content>
                { demoApis?.s3 && isAuthenticated && <Link to='/api/s3/manage'><Label color='green' attached='top right' size='large' style={this.labelStyle}>Get your API key</Label></Link> }
                <Link to='/api/s3'><Image inline src={apiImage} alt='CDRaaS S3 API' size='tiny' /></Link>
                <Card.Header><Link to='/api/s3'>CDRaaS S3 API</Link></Card.Header>
                The CDRaaS S3 API lets us pull and push content from and to your S3 buckets
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <ScrollButton scrollRef={nextScrollRef} alwaysVisible={false} isVisible={activeScrollButton === currentScrollRef} />
    </Container>
  }

  wideContentAfterLight = (activeScrollButton, currentScrollRef, nextScrollRef) => {
    return <Ref innerRef={currentScrollRef}>
      <Segment basic className='light' onMouseOver={() => this.toggleButtonVisibility(currentScrollRef)} onMouseOut={() => this.toggleButtonVisibility(null)}>
        <Grid stackable columns='equal' centered style={{marginBottom: '0', marginTop: '0'}}>
          <Grid.Column>
            <Header as='h2' textAlign='center' style={{marginBottom: '0'}}>How does CDRaaS Work?</Header>
            <div>
              <Image src={apiFlowChart} alt='' style={{margin: '0 auto'}}/>
            </div>

            <p style={{maxWidth: '70%', textAlign: 'center', margin: '0 auto'}}>
                The CDRaaS APIs use a unique approach called content transformation, the service extracts only the valid business information, leaving behind any threat. It then discards the original and builds a brand new threat-free one for you to use. This process ensures that the user experience is maintained (the before and after look visually the same). However, the file has now been 'cleaned' through a normalisation process, where Deep Secure only transforms known safe parts of the file format. Find out more watching the video below.
            </p>
          </Grid.Column>
        </Grid>

        <Grid stackable columns='equal' centered style={{marginBottom: '0vh', marginTop: '2vh'}}>
          <Grid.Column className='imageCol' floated='right'>
            <div style={{maxWidth:'500px', marginLeft: 'auto'}}>
              <Embed
                id='kJ9Xx1DyAXQ'
                placeholder={videoImage}
                source='youtube'
                brandedUI={false}
                aspectRatio='16:9'
                />
            </div>
          </Grid.Column>
          <Grid.Column className='textCol'>

            <p><strong>100% malware protection:</strong></p>
            <p>Ensures files are always malware free</p>

            <p><strong>Designed for developers:</strong></p>
            <p>Choice of simple, JSON/REST and S3 APIs</p>

            <p><strong>Easy to integrate:</strong></p>
            <p>Cloud-native APIs let you build-in protection in minutes</p>

            <p><strong>Limitless scalability:</strong></p>
            <p>Subscription service built on serverless computing</p>

          </Grid.Column>
        </Grid>
        <div style={{marginTop: '-15vh'}}>
          <ScrollButton scrollRef={nextScrollRef} alwaysVisible={false} isVisible={activeScrollButton === currentScrollRef} />
        </div>
      </Segment>
    </Ref>
  }

  wideContentAfterDark = (activeScrollButton, currentScrollRef, nextScrollRef) => {
    const liStyles = {marginBottom: '10px'}
    return <Ref innerRef={currentScrollRef}>
      <Segment basic inverted className='dark' onMouseOver={() => this.toggleButtonVisibility(currentScrollRef)} onMouseOut={() => this.toggleButtonVisibility(null)}>
        <Container style={{marginBottom: '40vh', fontSize: '1.2em', lineHeight: '1.4em'}}>
          <Grid stackable columns='equal' centered>
            <Grid.Column>
              <Header as='h2'>The Benefits of CDRaaS</Header>
              <ul className='logo'>
                <li style={liStyles}>100% threat-free</li>
                <li style={liStyles}>No scanning, no sandboxing, no waiting</li>
                <li>Eliminates evasive, zero-day and even undetectable threats</li>
              </ul>
            </Grid.Column>
            <Grid.Column>
              <Header as='h2'>Digitally Pure Content</Header>
              <ul className='logo'>
                <li style={liStyles}>Choice of 3 API types</li>
                <li style={liStyles}>Pay-as-you-use pricing model</li>
                <li style={liStyles}>Limitless scalability - serverless design using microservices architecture</li>
                <li style={liStyles}>Industry-leading reliability levels</li>
                <li style={liStyles}>Choice of AWS deployment region</li>
                <li>Extensive sample code and API documentation</li>
              </ul>
            </Grid.Column>
          </Grid>
        </Container>
        <ScrollButton scrollRef={nextScrollRef} alwaysVisible={false} isVisible={activeScrollButton === currentScrollRef} />
      </Segment>
    </Ref>
  }

  getStartedCard = (currentScrollRef, nextScrollRef) => {
    return <Ref innerRef={currentScrollRef}>
      <Container textAlign='center' className='home-actions'>
        <Grid stackable columns='equal' style={{marginBottom: '40vh'}}>
          <Grid.Row stretched>
            <Grid.Column>
              <Card fluid>
                <a href='mailto:contact-us@deep-secure.com'><Image inline src={oldVideoImage} alt='Speak to a Developer' className='card-image' /></a>
                <Card.Content>
                  <Card.Header><a href='mailto:contact-us@deep-secure.com'>Speak to a Developer</a></Card.Header>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column>
                <Card fluid>
                  <Link to='/getting-started'><Image inline src={whatIsCtrImage} alt='Get started today!' className='card-image' /></Link>
                  <Card.Content>
                    <Card.Header><LinkButton handleClick={this.handleRegisterHosted}>Start 30 Day Free Trial Today</LinkButton></Card.Header>
                  </Card.Content>
                </Card>
            </Grid.Column>
            <Grid.Column>
              <Card fluid>
                <Link to='/use-cases'><Image inline src={useCasesImage} alt='Use cases' className='card-image' /></Link>
                <Card.Content>
                  <Card.Header><Link to='/use-cases'>Use Cases</Link></Card.Header>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <ScrollButton scrollDirection='up' scrollRef={nextScrollRef} alwaysVisible={true} />
      </Container>
    </Ref>
  }

  render() {
    const { isAuthenticated } = this.props

    const redirect = isAuthenticated ? getLoginState('redirect') : null

    // return <Layout header='Zero Trust CDR as a Service' appClassName='home' usagePlanId={usagePlanId} token={token} errors={errors}>
    //   hello
    // </Layout>

    return redirect
      ? <Redirect push to={redirect} />
      : <Layout header='Zero Trust CDR as a Service' appClassName='home'>
          { this.wideContent() }
        </Layout>
  }
}
