import { connect } from 'react-redux'

import { withData, withStateMappedToProps, IS_AUTHENTICATED, USER_CONFIG_MFA_ENABLED, USER_CONFIG_ENTERPRISE_ENABLED, MFA,
         USER_CONFIG_PORTAL_API, PORTAL_API_CONFIG, ROLES, LOADING_DETECTED, USER_CONFIG_ACCOUNTS, ACCOUNT } from '../../redux/withData'
import { enableMfa, verifyMfa, disableMfa, cancelMfa } from '../../redux/actions/mfaActions'
import { createPortalApiUser, deletePortalApiUser } from '../../redux/actions/portalApiActions'
import { setDefaultAccount } from '../../redux/actions/userActions'

import TheComponent from './AccountPage.js';

const requiredData = [
  IS_AUTHENTICATED, USER_CONFIG_MFA_ENABLED, USER_CONFIG_ENTERPRISE_ENABLED, MFA, ACCOUNT,
  USER_CONFIG_PORTAL_API, PORTAL_API_CONFIG, ROLES, LOADING_DETECTED, USER_CONFIG_ACCOUNTS
]

const mapStateToProps = state => {
  return withStateMappedToProps(state, requiredData)
}

const mapDispatchToProps = { enableMfa, verifyMfa, disableMfa, cancelMfa, createPortalApiUser, deletePortalApiUser, setDefaultAccount }

export default connect(mapStateToProps, mapDispatchToProps)(withData(requiredData)(TheComponent))