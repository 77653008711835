import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Container, Dropdown, Grid, Header, Image, Menu, Label, Icon, Popup, Message, Confirm } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import AwsMarketplace from '../../../components/AwsMarketplace'
import { logout, loginHosted, hasPermission, relog } from '../../../services/self'

import deepSecureImage from './images/deep-secure-by-forcepoint.png'

export default class LayoutHeader extends PureComponent {
  static propTypes={
    header: PropTypes.string.isRequired,
    yaraEnabled: PropTypes.bool,
    enterprise: PropTypes.bool,
    mfaEnabled: PropTypes.bool,
    hasProducts: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    accounts: PropTypes.array,
    defaultAccountEmail: PropTypes.string,
    switchAccount: PropTypes.func,
    cancelSwitchAccount: PropTypes.func,
    assumeAccountEmail: PropTypes.string,
    assumeAccountId: PropTypes.string,
    assumeAccountRole: PropTypes.string,
    adminHasAssumedAnotherIdentity : PropTypes.bool,
    mfaRequired: PropTypes.bool,
    freeTrialStatus: PropTypes.string
  }
  state = {
    switchingAccount: false
  }

  logout() {
    logout()
  }

  handleLoginHosted = (event) => this._handleLoginHosted(event, false)
  handleRegisterHosted = (event) => this._handleLoginHosted(event, true)

  _handleLoginHosted(event, register) {
    event.preventDefault()

    loginHosted({marketplace: true}, register)
  }

  handleSwitchAccount = (e, { text }) => this.switchAccount(text);
  handleResetAccount = () => this.switchAccount();

  switchAccount = async (email) => {
    const { switchAccount, cancelSwitchAccount, defaultAccountEmail } = this.props;
    this.setState({ switchingAccount: true });
    const _email = email || defaultAccountEmail;
    return _email ? switchAccount(_email) : cancelSwitchAccount()
  }

  handleMfaRelog = (e) => {
    e.preventDefault();
    relog({ redirect: '/account', accountModal: 'showMFAModal' }, true);
  }
  handleCancelMfa = () => this.setState({ switchingAccount: false });

  showFreeTrialStatus = () => {
    const { freeTrialStatus } = this.props;

    return (
      <>
        { freeTrialStatus === 'NoAccess'
          ? <Message info size='small' className='free_trial_status'>
              To enable access to the Zero Trust CDR API, sign up to the <Link to='/api/instant'>free trial</Link> or <a href='mailto:info@deep-secure.com?subject=Access to Zero Trust CDR as a service'>contact us</a>
            </Message>
          : null }
        { freeTrialStatus === 'Applied'
          ? <Message info size='small' className='free_trial_status' content='Your application for a free trial is currently being reviewed' />
          : null }
        { freeTrialStatus === 'Expired'
          ? <Message warning size='small' className='free_trial_status' content = 'Your free trial has expired' />
          : null }
      </>
    )
  }

  render() {
    const { header, isAuthenticated, yaraEnabled, mfaEnabled, hasProducts, accounts, assumeAccountEmail,
            assumeAccountId, assumeAccountRole, adminHasAssumedAnotherIdentity, mfaRequired, defaultAccountEmail
      } = this.props
    const { switchingAccount } = this.state;


    return (
      <div className='page-header'>
        { assumeAccountEmail && assumeAccountEmail !== defaultAccountEmail
        ? <Label as='a' color='red' size='tiny' onClick={this.handleResetAccount} className='account-switch'>
            Signed in as {assumeAccountRole} for {assumeAccountEmail}&nbsp;
            <Popup content='Return to your account' position='bottom left' trigger={
              <Icon name='sign-out' fitted circular/>
            }/>
          </Label>
        : null
        }

        <Container textAlign='center' className='logo'>
          <Link to='/'><Image inline src={deepSecureImage} alt='Deep Secure' /></Link>
        </Container>

        <Grid container textAlign='center'>
          <Menu stackable borderless className='top-menu'>
            <Menu.Item as={Link} to='/' icon='home' />
            <Menu.Item as={Link} to='/what-is-threat-removal'>What is Zero Trust CDR?</Menu.Item>
            <Menu.Item as={Link} to='/getting-started'>Getting Started</Menu.Item>

            <Dropdown item text='Services'>
              <Dropdown.Menu>
                <Dropdown.Item as={Link} to='/apis' text='Overview' />
                <Dropdown.Item as={Link} to='/features' text='Features' />
                <Dropdown.Item as={Link} to='/apis/choosing' text='Help me choose' />
                <Dropdown.Item as={Link} to='/pricing' text='Pricing' />
                <Dropdown.Header content='The APIs'/>
                <Dropdown.Item as={Link} to='/api/instant' text='Instant API' icon='cog' />
                <Dropdown.Item as={Link} to='/api/async' text='Async API' icon='cogs' />
                <Dropdown.Item as={Link} to='/api/s3' text='S3 API' icon='aws' />
                <Dropdown.Item as={Link} to='/api/azure' text='Azure' icon='microsoft' />
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown item text='Documentation'>
              <Dropdown.Menu>
                <Dropdown.Header content='The APIs'/>
                <Dropdown.Item as={Link} to='/api/instant/documentation' text='Instant API' icon='cog' />
                <Dropdown.Item as={Link} to='/api/async/documentation' text='Async API' icon='cogs' />
                <Dropdown.Item as={Link} to='/api/s3/documentation' text='S3 API' icon='aws' />
                <Dropdown.Item as={Link} to='/api/azure' text='Azure' icon='microsoft' />
                <Dropdown.Divider />
                { hasProducts
                ? <Dropdown item fluid text='File types'>
                    <Dropdown.Menu>
                      <Dropdown.Item as={Link} to='/content-types/pdf' text='PDF' icon='file pdf outline' />
                      <Dropdown.Item as={Link} to='/content-types/officex' text='Office X' icon='file word outline' />
                      <Dropdown.Item as={Link} to='/content-types/images' text='Images' icon='file image outline' />
                      <Dropdown.Item as={Link} to='/content-types/xml-json' text='JSON and XML' icon='file code outline' />
                    </Dropdown.Menu>
                  </Dropdown>
                : null }
                <Dropdown.Item as={Link} to='/documentation/environments' text='Environments'  />
                <Dropdown.Item as={Link} to='/documentation/format-conversion' text='Format conversion'  />
                { hasProducts ? <Dropdown.Item as={Link} to='/content-types/preview' text='Preview mode' /> : null }
                  <Dropdown item fluid text='Risk management'>
                    <Dropdown.Menu>
                      <Dropdown.Item as={Link} to='/documentation/risks' text='Managing risks'/>
                      <Dropdown.Item as={Link} to='/documentation/risks/macros' text='Macros'/>
                      <Dropdown.Item as={Link} to='/documentation/risks/structured-data' text='Structured data'/>
                      <Dropdown.Item as={Link} to='/documentation/risks/image-quality' text='Image quality'/>
                    </Dropdown.Menu>
                  </Dropdown>
                <Dropdown.Item as={Link} to='/documentation/report' text='Reports'  />
                <Dropdown.Divider />
                <Dropdown.Item as={Link} to='/api/errors' text='Error glossary' />
              </Dropdown.Menu>
            </Dropdown>

            <Menu.Item as={Link} to='/use-cases'>Use Cases</Menu.Item>
            <Menu.Item as={Link} to='/faq'>FAQ</Menu.Item>
            <Dropdown item text='' icon={isAuthenticated ? 'user' : 'user x'}>
              <Dropdown.Menu>
                { isAuthenticated && hasProducts ? <Dropdown.Item as={Link} to='/support' text='Support' icon='help' /> : null }
                { isAuthenticated
                  ? <Dropdown.Item as={Link} to='/dashboard' text='Dashboard' icon='dashboard' />
                  : <Dropdown.Item onClick={this.handleRegisterHosted} text='Register' icon='address card outline' />
                }
                { yaraEnabled
                  ? <Dropdown.Item as={Link} to='/yara-dashboard' text='YARA Results' icon='dashboard' />
                  : null
                }
                { mfaEnabled && hasPermission() && !adminHasAssumedAnotherIdentity
                  ? <Dropdown.Item as={Link} to='/admin' text='Administration' icon='cogs' />
                  : null
                }
                { isAuthenticated ? <Dropdown.Item as={Link} to='/account' text='Account' icon='id card outline' /> : null }
                { isAuthenticated && accounts && accounts.length > 0 && !adminHasAssumedAnotherIdentity
                  ? <Dropdown.Item>
                      <Dropdown text='Switch Account'>
                        <Dropdown.Menu id='switch_accounts_menu'>
                        { accounts.map((account, i) => < Dropdown.Item
                            key={i} text={account} onClick={this.handleSwitchAccount}
                            disabled={account===assumeAccountEmail}
                        />)}
                        { assumeAccountId ?
                            <>
                              <Dropdown.Divider />
                              <Dropdown.Item text='Return to your account' onClick={this.handleResetAccount} />
                            </>
                          : null
                        }
                        </Dropdown.Menu>
                      </Dropdown>
                    </Dropdown.Item>
                  : null
                }
                <Dropdown.Divider />
                { isAuthenticated
                  ? <Dropdown.Item onClick={() => this.logout()} text='Sign out' icon='sign-out' />
                  : <Dropdown.Item onClick={this.handleLoginHosted} text='Sign in' icon='sign-in' />
                }
              </Dropdown.Menu>
            </Dropdown>

          </Menu>
        </Grid>
        { !hasProducts ? this.showFreeTrialStatus() : null }
        { mfaRequired && switchingAccount ?
          <Confirm open={true} cancelButton='No' confirmButton='Yes' onConfirm={this.handleMfaRelog}
            content = {'Switching to this account requires two factor authentication to be enabled on your account. ' +
            'In order to activate this you will be asked to sign in again. Continue?'}
            onCancel={this.handleCancelMfa} />
        : null}
        <Container textAlign='center'>
          <Header as='h1'>{header}</Header>
        </Container>
        <div className="header-messages">
            <AwsMarketplace/>
        </div>
      </div>
    )
  }
}

