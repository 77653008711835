import React, { PureComponent } from 'react'
import Layout from '../../../components/Layout'
import { Container, Header } from 'semantic-ui-react'

import { PrismAsyncLight as SyntaxHighlighter } from "react-syntax-highlighter";
import { tomorrow as SyntaxStyle } from 'react-syntax-highlighter/dist/cjs/styles/prism';

export default class Macros extends PureComponent {

  render() {
    return (
      <Layout header='Macro risks'>
        <Container>

          <Header as='h3'>The risk</Header>
          <p>
            Macros are active code that is part of a file, which are used to add custom functionality to the application that opens the file.
            A macro can be set to run when the user clicks a button or when a certain condition arises, but they can also be run automatically when the file is opened.
          </p>
          <p>
            Macros can read or write files, access the network and perform any other action that the user has permission to perform.
            This makes them risky.
            A malicious macro can do things the user could do but would never want to do, such as delete important files or leak sensitive data.
          </p>
          <p>
            As macros are code, it is not possible to transform them to make them safe.
            The only fully safe course of action is to block macro enabled files or to remove the macros from them before they are delivered.
          </p>

          <p>When we use the term 'macro' below we are referring to all of the macro related code within a given macro-enabled Microsoft Office file.</p>

          <Header as='h3'>The options</Header>
          <p>
            The Zero Trust CDR service can handle macro-enabled Office documents – DOCM, PPTM and XLSM files.
          </p>
          <p>
            The default action is to remove the macros from these files and deliver them as DOCX, PPTX and XLSX files, but it is possible to explicitly accept the risk and have the transformation preserve the macros.
          </p>
          <p>
            The risk should only be accepted when the wider system is set up to treat macro-enabled files with caution.
            For example, applications such as Microsoft Office can be configured so they will not run macros, or to run macros only if the user agrees to it when they open the file.
            If macros are only allowed for users who need to run them, and are properly trained to understand the issues, the risk might be deemed acceptable and the service be allowed to deliver macros.
          </p>

          <Header as='h3'>Enabling macro preservation</Header>
          <p>We have two ways that you can enable macro preservation in Microsoft Excel, Microsoft Powerpoint or Microsoft Word.</p>
          <Header as='h4'>Allow all</Header>
          <p>The easiest way to enable macros is to accept a risk such as <em>exe/macro/ms/word</em> which will allow all Microsoft Word macros straight through the transformation.</p>
          <Header as='h4'>Allow specifically</Header>
          <p>However, you can, and should, be more selective. Whenever a macro-enabled file is processed the report includes a value identifying that specific macro, and the risk required to allow it specifically.</p>
          <p>After you have transformed a trusted macro-enabled file once, you can use this specific risk to allow the macro through subsequently, regardless of any changes to the other content within the file. You can do this either by always submitting all/some of your allowed macro risks, or by re-submitting them if the macro is found in the report. Re-submitting may be a necessary approach if you have a large number of allowed macros.</p>
          <p>A specific macro risk looks like <em>exe/macro/ms/powerpoint/1:8cf32e3d2e84601e89eb9e12850fe7b5690b5e4c30b9a920d2c0d86063e1fcfc</em>.</p>
          <p>If you change the macro code of a file you have previously allowed, a new specific risk would need to be reported on and then allowed to permit the updated macro. Other content or data changes should not alter the macro risk identifier.</p>
          <Header as='h4'>Macro preservation versioning</Header>
          <p>We have an algorithm to uniquely identify individual macros. However we are aware that there could be a future need to change this algorithm, and that this could alter the macro identifiers. As you store previous macro identifiers in your own records, and expect them to continue working, we have planned ahead and created a reliable way to maintain support for multiple versions.</p>
          <p>In the above example of a specific macro risk, the "/1:" part signifies version "1" of the algorithm. The report will always return the risks for the newest version of the algorithm, but you can request that previous versions are also included.</p>
          <p>Whenever a risk is specified, the corresponding versions will automatically be added to the report. You can also request the versions using the shortened form <em>exe/macro/ms/word/1</em> - this risk "allows" those specific versions to be reported on - it doesn't allow any actual macros.</p>

          <Header as='h3'>Examples</Header>
          <Header as='h4'>Allow all</Header>
          <p>Request options:</p>
          <SyntaxHighlighter language='json' style={SyntaxStyle}>
            {JSON.stringify(
{
  "risks": {
    "allow": [
      "exe/macro/ms/word"
    ]
  }
}, null, 4)}
          </SyntaxHighlighter>
          <p>Report:</p>
          <SyntaxHighlighter language='json' style={SyntaxStyle}>
            {JSON.stringify(
{
  "settings": {
      "format": "changed"
  },
  "structure": [
      {
          "details": {
              "ext": "docm",
              "parts": [
                  {
                      "risk": "exe/macro/ms/word/1:8cf32e3d2e84601e89eb9e12850fe7b5690b5e4c30b9a920d2c0d86063e1fcfc",
                      "type": "macro"
                  }
              ],
              "sha256": "<transformed hash>",
              "size": "<transformed size>",
              "type": "application/vnd.ms-word.document.macroEnabled.12"
          },
          "previous": {
              "details": {
                  "sha256": "25bd07bd6c436350ed1af15f311bd79656490f083884b234cd947a49971c49d3",
                  "size": 17523
              }
          }
      }
  ]
}, null, 4)}</SyntaxHighlighter>

          <Header as='h4'>Allow a specific macro</Header>
          <p>Request options:</p>
          <SyntaxHighlighter language='json' style={SyntaxStyle}>
            {JSON.stringify(
{
  "risks": {
    "allow": [
      "exe/macro/ms/word/1:8cf32e3d2e84601e89eb9e12850fe7b5690b5e4c30b9a920d2c0d86063e1fcfc"
    ]
  }
}, null, 4)}
          </SyntaxHighlighter>
          <p>Report:</p>
          <SyntaxHighlighter language='json' style={SyntaxStyle}>
            {JSON.stringify(
{
  "settings": {
      "format": "changed"
  },
  "structure": [
      {
          "details": {
              "ext": "docm",
              "parts": [
                  {
                      "risk": "exe/macro/ms/word/1:8cf32e3d2e84601e89eb9e12850fe7b5690b5e4c30b9a920d2c0d86063e1fcfc",
                      "type": "macro"
                  }
              ],
              "sha256": "<transformed hash>",
              "size": "<transformed size>",
              "type": "application/vnd.ms-word.document.macroEnabled.12"
          },
          "previous": {
              "details": {
                  "sha256": "25bd07bd6c436350ed1af15f311bd79656490f083884b234cd947a49971c49d3",
                  "size": 17523
              }
          }
      }
  ]
}, null, 4)}</SyntaxHighlighter>

          <Header as='h4'>Allow a specific macro - not found</Header>
          <p>Request options:</p>
          <SyntaxHighlighter language='json' style={SyntaxStyle}>
            {JSON.stringify(
{
  "risks": {
    "allow": [
      "exe/macro/ms/word/1:000000000000000000000000000000000000000000000000000000000000000000"
    ]
  }
}, null, 4)}
          </SyntaxHighlighter>
          <p>Report:</p>
          <SyntaxHighlighter language='json' style={SyntaxStyle}>
            {JSON.stringify(
{
  "settings": {
      "format": "changed"
  },
  "structure": [
      {
          "changed": {
              "action": "converted",
              "reason": "macros",
              "removedParts": [
                  {
                      "risk": "exe/macro/ms/word/1:6cf32e3d2e84601e89eb9e12850fe7b5690b5e4c30b9a920d2c0d86063e1fcfc",
                      "type": "macro"
                  }
              ]
          },
          "details": {
              "ext": "docx",
              "sha256": "<transformed hash>",
              "size": "<transformed size>",
              "type": "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          },
          "previous": {
              "details": {
                  "ext": "docm",
                  "sha256": "25bd07bd6c436350ed1af15f311bd79656490f083884b234cd947a49971c49d3",
                  "size": 17523,
                  "type": "application/vnd.ms-word.document.macroEnabled.12"
              }
          }
      }
  ],
  "summary": {
      "converted": 1,
      "removedParts": {
          "macro": 1
      }
  }
}, null, 4)}</SyntaxHighlighter>

        </Container>
      </Layout>
    )
  }
}
