import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'

import { scriptContent, scriptUrl } from './embed'

export default class Freshdesk extends PureComponent {
  static propTypes={
    freshdeskAuthenticationToken: PropTypes.object,
    loadFreshdeskToken: PropTypes.func,
  }

  componentDidMount() {
    if (!window.FreshworksWidget) {
      const scriptTag = document.createElement('script')
      scriptTag.text = scriptContent

      const scriptUrlTag = document.createElement('script')
      scriptUrlTag.src = scriptUrl
      scriptUrlTag.async = true
      scriptUrlTag.defer = true

      this.instance.appendChild(scriptTag)
      this.instance.appendChild(scriptUrlTag)
    }
    this.authenticate()
  }

  componentDidUpdate(prevProps) {
    const { freshdeskAuthenticationToken } = this.props

    if (!_.isEqual(prevProps.freshdeskAuthenticationToken, freshdeskAuthenticationToken))
      this.authenticate()
  }

  componentWillUnmount() {
    this.disable()
  }

  authenticate(count) {
    const { freshdeskAuthenticationToken, loadFreshdeskToken } = this.props
    if (freshdeskAuthenticationToken) {
      if (window.FreshworksWidget) {
        window.FreshworksWidget('authenticate', {
          token: freshdeskAuthenticationToken,
          callback: () => loadFreshdeskToken(),
        })
        window.FreshworksWidget('show')
      } else {
        count = count || 0
        if (count < 30)
          window.setTimeout(1000, () => this.authenticate(count + 1)) // this isn't react-y so we can ignore react lifecycle etc
      }
    } else {
      this.disable()
    }
  }

  disable() {
    if (window.FreshworksWidget) {
      window.FreshworksWidget('logout')
      window.FreshworksWidget('hide')
    }
  }

  render() {
    return <div ref={el => (this.instance = el)} />
  }
}