import { LOAD_USAGE_PLANS, LOADING_USAGE_PLANS, LOAD_USAGE_PLANS_SUCCEEDED, LOAD_USAGE_PLANS_FAILED } from '../actions/usagePlanActions'
import { all, call, fork, put, take } from 'redux-saga/effects'


import { fetchUsagePlans } from '../../services/api-catalog'

export function* loadAsync() {
  yield put({type: LOADING_USAGE_PLANS})
  try {
    const payload = yield call(fetchUsagePlans)
    yield put({type: LOAD_USAGE_PLANS_SUCCEEDED, payload})
  }
  catch (error) {
    yield put({type: LOAD_USAGE_PLANS_FAILED, error})
  }
}

export function* watchLoadAsync() {
  while (true) {
    yield take(LOAD_USAGE_PLANS)
    yield fork(loadAsync)
    yield take([LOAD_USAGE_PLANS_SUCCEEDED, LOAD_USAGE_PLANS_FAILED])
  }
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    call(watchLoadAsync),
  ])
}