import { ENABLE_MFA_SUCCEEDED, ENABLE_MFA_FAILED,
         VERIFY_MFA_SUCCEEDED, VERIFY_MFA_FAILED,
         DISABLE_MFA_SUCCEEDED, DISABLE_MFA_FAILED,
         LOADING_MFA, CANCEL_MFA } from '../actions/mfaActions'

function mfaReducer(existing = {}, action) {
  switch(action.type) {
    case LOADING_MFA:
        return {
            ...existing,
            loading: true
        }
    case ENABLE_MFA_SUCCEEDED:
      return {
        mfaCode: action.payload
      }

    case VERIFY_MFA_SUCCEEDED:
    case DISABLE_MFA_SUCCEEDED:
    case CANCEL_MFA:
      return {
        mfaCode: null
      }

    case ENABLE_MFA_FAILED:
    case DISABLE_MFA_FAILED:
    case VERIFY_MFA_FAILED:
      return {
        mfaCode: null
      }

    default:
      return existing
  }
}

export default mfaReducer