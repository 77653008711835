import React, { PureComponent } from 'react'
import Layout from '../../components/Layout'
import { Container, Header } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import { PrismAsyncLight as SyntaxHighlighter } from "react-syntax-highlighter";
import { tomorrow as SyntaxStyle } from 'react-syntax-highlighter/dist/cjs/styles/prism';

export default class Report extends PureComponent {

  constructor(props) {
    super(props)
    this.settingsRef = React.createRef()
    this.reportRef = React.createRef()
  }

  render() {
    const jsSample =
      `
var zlib = require('zlib');
const input = {
    'report__compressed': {
        'data': 'eJxNjstOxDAMRX9llDUSifNy+BXEwnGcTiWmU7UpAqr+O21nwex85fs4q5qltX7oZvV2WVW9Tzdq+6n4SkMnRW0vFzW3aeG2TLI/3ldVpFH/+QjI9+keh04dxiuBD2cca3WGi8XgKIr1xrAziFCKJXQ65GISIIhIdehYB2+Ch2STpxhqPtv632PROPBul+1nPKTqb9TJ67F4sI2TfPX35UHzTPbPQpRq9WwpJohF5wrWIQeqBbUFyJJBI5FoTxm5Fo9S2OoSmaNFiM8saBJu2/ax/QE251us',
        'method': 'base64/deflate',
    }
}
//Create a buffer object from the data from the compressed report with base64 as the encoding
const buffer = Buffer.from(input['report__compressed']['data'], 'base64');

//using zlib we can inflate the compressed report
const inflatedReport =  JSON.parse(zlib.inflateSync(buffer).toString())
`
    const pythonSample =
      `
from zlib import decompress
from base64 import b64decode
import json


report = {
            "report__compressed": {
                "data": "eJxNjstOxDAMRX9llDUSifNy+BXEwnGcTiWmU7UpAqr+O21nwex85fs4q5qltX7oZvV2WVW9Tzdq+6n4SkMnRW0vFzW3aeG2TLI/3ldVpFH/+QjI9+keh04dxiuBD2cca3WGi8XgKIr1xrAziFCKJXQ65GISIIhIdehYB2+Ch2STpxhqPtv632PROPBul+1nPKTqb9TJ67F4sI2TfPX35UHzTPbPQpRq9WwpJohF5wrWIQeqBbUFyJJBI5FoTxm5Fo9S2OoSmaNFiM8saBJu2/ax/QE251us",
                "method": "base64/deflate"}
        }

# Use b64decode imported from the base64 library on the data from the report
decoded_report = b64decode(report['report__compressed']['data'])
# Then you can decompress the data with the zlib library
report_string = decompress(decoded_report)

# Finally load the json to create a dictionary
report = json.loads(report_string)
`;
    return (
      <Layout header='Reports'>
        <div className='reports-page'>
          <Container>
            <Header as='h2'>Configuring the Report</Header>
            <p>
              Reports are produced alongside your new file in JSON format. They are designed to give you more insight into the changes and details of the ZTCDR process. You can use this information in your application to make decisions.
              The APIs can be told what format you want the report in. The supplied option will be used to customise the report formatting
              to your needs.
            </p>
            <p>
              Reports are configured by specifying the report.format property in the options object for the
              <Link to='/api/async/definition'> Async </Link>
              and
              <Link to='/api/s3/definition'> S3 </Link>
              APIs, or the X-Options header for the
              <Link to='/api/instant/definition'> Instant </Link>
              API. See the <Link to='#' onClick={() => this.settingsRef.current.scrollIntoView()}>settings</Link> keys for the list of available options.
            </p>

            <SyntaxHighlighter language='json' style={SyntaxStyle}>
              {
                '{\n' +
                '  "options": {\n' +
                '    "report": {\n' +
                '      "format": "full"\n' +
                '    }\n' +
                '  }\n' +
                '}'
              }
            </SyntaxHighlighter>
            <div ref={this.reportRef}></div>
            <Header as='h2'>Reports Format</Header>
            <p>
              The following is an example of a report taken from the
              <Link to='/api/instant/demo'> Instant api demo </Link>
              response:
            </p>
            <SyntaxHighlighter language='json' style={SyntaxStyle}>
              {
                '{\n' +
                '    "settings": {\n' +
                '        "format": "changed"\n' +
                '    },\n' +
                '    "structure": [\n' +
                '        {\n' +
                '            "details": {\n' +
                '                "ext": "png",\n' +
                '                "sha256": "c8ff41cd3864a7e3511c41882dd3a8406bd19282eeef484c06516529395a76fb",\n' +
                '                "size": 14254,\n' +
                '                "type": "image/png"\n' +
                '            },\n' +
                '            "previous": {\n' +
                '                "details": {\n' +
                '                    "sha256": "aa9ff5c3a7927d0bf2348c6afd80322beb208aae05ab8cfd58edc30d7cc73827",\n' +
                '                    "size": 18198\n' +
                '                }\n' +
                '            }\n' +
                '        }\n' +
                '    ]\n' +
                '}\n'
              }
            </SyntaxHighlighter>

            <p>
              In this report we can see that an image was uploaded and that it was changed
            </p>

            <Header as='h3'>Keys breakdown</Header>

            <div ref={this.settingsRef}></div>
            <Header as='h4'>settings</Header>
            <p>
              The settings object contains the key <strong>format</strong>  and the value of this will reflect what type of report has been produced.
              The following report formats are potentially available.

            </p>
            <ul>
              <li><strong>changed</strong> reports on data that has had a notable change</li>
              <li><strong>default</strong> the current default behaviour is the "changed" setting</li>
              <li><strong>full</strong> reports everything about the data, including information on data that hasn't been transformed. These reports can be big!</li>
              <li><strong>none</strong> a report will not be in the response. Use this if you don't need the report</li>
              <li><strong>failure</strong> failure to generate report e.g. unsupported data or a bad request</li>
            </ul>
            <Header as='h4'>structure</Header>
            <p>
              This will have a list of objects containing all necessary information
            </p>
            <Header as='h4'>details</Header>
            <p>
              This will report the changes to the data, detailing the new properties, examples of fields that may appear if
              relevant:
            </p>
            <ul>
              <li><strong>ext</strong> is the extension of the file type e.g. .png, .doc, .pdf,etc.</li>
              <li><strong>name</strong> is the name of the file</li>
              <li><strong>sha256</strong> is the new hash of the transformed data</li>
              <li><strong>size</strong> is the new size of the transformed data</li>
              <li><strong>type</strong> is the mime type of the data</li>
            </ul>
            <Header as='h4'>previous</Header>
            <p>
              This is will contain a details object as above but reports the original data before any transformation has taken place. Only values that have changed are shown.
            </p>
            <Header as='h3'>Report children structure</Header>
            <p>Certain file types can contain other files that may be transformed, redacted etc. In the below example we see a zip file
              containing a Word document with a macro that has been converted to a non-macro format.
            </p>

            <SyntaxHighlighter language='json' style={SyntaxStyle}>
{JSON.stringify({
  "settings": {
      "format": "changed"
  },
  "structure": [
      {
          "children": [
              {
                  "changed": {
                      "action": "converted",
                      "reason": "macros",
                      "removedParts": [
                          {
                              "risk": "exe/macro/ms/word/1:6cf32e3d2e84601e89eb9e12850fe7b5690b5e4c30b9a920d2c0d86063e1fcfc",
                              "type": "macro"
                          }
                      ]
                  },
                  "details": {
                      "ext": "docx",
                      "name": "minimal.docx",
                      "sha256": "d2038f6ff00732247f9b6274fa8f150396f242b2fda00605952b66f5c4ca698b",
                      "size": 9066,
                      "type": "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  },
                  "previous": {
                      "details": {
                          "ext": "docm",
                          "name": "minimal.docm",
                          "sha256": "25bd07bd6c436350ed1af15f311bd79656490f083884b234cd947a49971c49d3",
                          "size": 17523,
                          "type": "application/vnd.ms-word.document.macroEnabled.12"
                      }
                  }
              }
          ],
          "details": {
              "ext": "zip",
              "sha256": "9650e4ed57eef1a07b519ecc2aaf671f3a21b4fbd72d468e9fe0107d3b8d0b1f",
              "size": 8422,
              "type": "application/zip"
          },
          "previous": {
              "details": {
                  "sha256": "f8344c48e1fa4400a7b5a86f39f3b29652fba6b74c509c803b172dfac65c0751",
                  "size": 14755
              }
          }
      }
  ],
  "summary": {
      "converted": 1,
      "removedParts": {
          "macro": 1
      }
  }
}, null, 4)}
            </SyntaxHighlighter>

            <Header as='h3'>Extra keys breakdown</Header>
            <Header as='h4'>children</Header>
            <p>
              The children key will appear in reports if there are nested files within the file. Some examples are emails with attachments and
              zip files with images and documents. The children array will contain a list of files which may have the following keys.
            </p>
            <ul>
              <li><strong>changed</strong> the section describes the changes that have been made
                <ul>
                  <li><strong>action</strong> the type of change that has been made</li>
                  <li><strong>reason</strong> the reason it has been made</li>
                  <li><strong>removedParts</strong> this is only appears in certain circumstances. It is used to describe elements of a file that have been removed.
                    <ul>
                      <li><strong>risk</strong> where supplied, this is the name of a risk that can be allowed if you wish to support this element</li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li><strong>details</strong> this can appear on its own unaccompanied by changed and previous if there was no change. Otherwise it will refer to the current state of the file after a change has been made</li>
              <li><strong>previous</strong> information relevant to the file before being changed</li>
            </ul>

            <Header as='h4'>summary</Header>
            <p>
              The summary will have a count of all changes that have been made to the files, such as redacted, converted and removed
            </p>
            <Header as='h3'>Failure report</Header>
            <p>
              Example of a report returned when an issue prevented the whole file from being processed
            </p>
            <SyntaxHighlighter language='json' style={SyntaxStyle}>
              {
                '{\n' +
                '  "settings": {\n' +
                '    "format": "failure"\n' +
                '  },\n' +
                '  "structure": [\n' +
                '    {\n' +
                '      "changed": {\n' +
                '        "action": "removed",\n' +
                '        "reason": "bad-request"\n' +
                '      }\n' +
                '    }\n' +
                '  ],\n' +
                '  "summary": {\n' +
                '    "removed": 1\n' +
                '  }\n' +
                '}\n'
              }
            </SyntaxHighlighter>
            <Header as='h3'>Compressed report</Header>
            <p>In the event a report is too large, the API will try to return a compressed report instead. Please note that the
              example below is small and unlikely to be compressed under normal circumstances. It is a compressed version of the<Link to='#' onClick={() => this.reportRef.current.scrollIntoView()}> Reports Format </Link> example above.</p>
            <SyntaxHighlighter language='json' style={SyntaxStyle}>
              {
                '{\n' +
                '  "report__compressed": {\n' +
                '      "data": "eJxNjstOxDAMRX9llDUSifNy+BXEwnGcTiWmU7UpAqr+O21nwex85fs4q5qltX7oZvV2WVW9Tzdq+6n4SkMnRW0vFzW3aeG2TLI/3ldVpFH/+QjI9+keh04dxiuBD2cca3WGi8XgKIr1xrAziFCKJXQ65GISIIhIdehYB2+Ch2STpxhqPtv632PROPBul+1nPKTqb9TJ67F4sI2TfPX35UHzTPbPQpRq9WwpJohF5wrWIQeqBbUFyJJBI5FoTxm5Fo9S2OoSmaNFiM8saBJu2/ax/QE251us",\n' +
                '      "method": "base64/deflate"\n' +
                '  }\n' +
                '}\n'
              }
            </SyntaxHighlighter>
            <ul>
              <li><strong>report__compressed </strong> this section details the compressed report
                <ul>
                  <li><strong>data</strong> the report that has been compressed</li>
                  <li><strong>method</strong> detailing that the report is base64 encoded and compressed using zlib deflate</li>
                </ul>
              </li>
            </ul>
            <Header as='h3'>Decompress report samples</Header>
            <p>The below code samples should help to explain how to retrieve the actual reports from the compressed form. Both samples below use
              the zlib library. For python read more <a href='https://docs.python.org/3/library/zlib.html'> here </a>  and for javascript read more <a href='https://nodejs.org/api/zlib.html'> here</a>.
            </p>
            <div className="codeSample">
              <Tabs className="codeTabs">
                <Header as='h3' className='codeHeader'>Code samples</Header>
                <TabList>
                  <Tab>Python 3.6+</Tab>
                  <Tab>JavaScript</Tab>
                </TabList>
                <TabPanel>
                  <SyntaxHighlighter language='python' style={SyntaxStyle}>
                    {pythonSample}
                  </SyntaxHighlighter>
                </TabPanel>
                <TabPanel>
                  <SyntaxHighlighter language='javascript' style={SyntaxStyle}>
                    {jsSample}
                  </SyntaxHighlighter>
                </TabPanel>
              </Tabs>
            </div>
          </Container>
        </div>
      </Layout>
    )
  }
}
