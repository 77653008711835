import { APPLY_FREE_TRIAL_SUCCEEDED, APPLY_FREE_TRIAL_FAILED } from '../actions/freeTrialActions'

function freeTrialReducer(existing = {}, action) {
  switch(action.type) {
    case APPLY_FREE_TRIAL_SUCCEEDED:
      return {
          ...existing,
          freeTrialApplicationSubmissionSuccess: true,
          freeTrialApplicationSubmissionError: null,
          freeTrialActivated: action.payload?.activated
      }
    case APPLY_FREE_TRIAL_FAILED:
      return {
        ...existing,
        freeTrialApplicationSubmissionSuccess: false,
        freeTrialApplicationSubmissionError: true
      }

    default:
      return existing
  }
}

export default freeTrialReducer