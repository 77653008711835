import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Layout from '../../components/Layout'
import { Accordion, Container, Header, Icon, Table, List } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

export default class Faq extends PureComponent {
  static propTypes={
    isAuthenticated: PropTypes.bool
  }
  state = { activeIndex: -1 }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  renderItem = (activeIndex, itemIndex, title, content) => (
    <div>
      <Accordion.Title active={activeIndex === itemIndex} index={itemIndex} onClick={this.handleClick}>
        <Icon name='dropdown' />
        {title}
      </Accordion.Title>
      <Accordion.Content active={activeIndex === itemIndex}>
        {content}
      </Accordion.Content>
    </div>
    )

  render() {
    const { isAuthenticated } = this.props
    const { activeIndex } = this.state
    let itemIndex = 0

    return (
      <Layout header='Frequently asked questions'>
        <Container>

          <Header as='h2'>Processing</Header>

          <Accordion fluid styled>
            {
              this.renderItem(activeIndex, itemIndex++,
                'What content types are supported?',
                <div>
                  <p>
                    You can submit files of these types
                  </p>
                  <Table celled selectable>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Format</Table.HeaderCell>
                        <Table.HeaderCell>Extension</Table.HeaderCell>
                        <Table.HeaderCell>Content types</Table.HeaderCell>
                        <Table.HeaderCell>Notes</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>PDF</Table.Cell>
                        <Table.Cell>.pdf</Table.Cell>
                        <Table.Cell>application/pdf</Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>PowerPoint (Office X)</Table.Cell>
                        <Table.Cell>.pptx</Table.Cell>
                        <Table.Cell>application/vnd.openxmlformats-officedocument.presentationml.presentation<br/>application/vnd.openxmlformats-officedocument.presentationml.template<br/>application/vnd.openxmlformats-officedocument.presentationml.slideshow</Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>PowerPoint with macros (Office X)</Table.Cell>
                        <Table.Cell>.pptm</Table.Cell>
                        <Table.Cell>application/vnd.ms-powerpoint.presentation.macroEnabled.12<br/>application/vnd.ms-powerpoint.template.macroEnabled.12<br/>application/vnd.ms-powerpoint.addin.macroEnabled.12<br/>application/vnd.ms-powerpoint.slideshow.macroEnabled.12</Table.Cell>
                        <Table.Cell>Use <Link to='/documentation/risks'>Risks</Link> to preserve macros</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Excel (Office X)</Table.Cell>
                        <Table.Cell>.xlsx</Table.Cell>
                        <Table.Cell>application/vnd.openxmlformats-officedocument.spreadsheetml.sheet<br/>application/vnd.openxmlformats-officedocument.spreadsheetml.template</Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Excel with macros (Office X)</Table.Cell>
                        <Table.Cell>.xlsm</Table.Cell>
                        <Table.Cell>application/vnd.ms-excel.sheet.macroEnabled.12<br/>application/vnd.ms-excel.template.macroEnabled.12<br/>application/vnd.ms-excel.addin.macroEnabled.12</Table.Cell>
                        <Table.Cell>Use <Link to='/documentation/risks'>Risks</Link> to preserve macros</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Visio (Office X)</Table.Cell>
                        <Table.Cell>.vsdx</Table.Cell>
                        <Table.Cell>application/vnd.ms-visio.drawing<br/>application/vnd.ms-visio.viewer</Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Word (Office X)</Table.Cell>
                        <Table.Cell>.docx</Table.Cell>
                        <Table.Cell>application/vnd.openxmlformats-officedocument.wordprocessingml.document<br/>application/vnd.openxmlformats-officedocument.wordprocessingml.template</Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Word with macros (Office X)</Table.Cell>
                        <Table.Cell>.docm</Table.Cell>
                        <Table.Cell>application/vnd.ms-word.document.macroEnabled.12<br/>application/vnd.ms-word.template.macroEnabled.12</Table.Cell>
                        <Table.Cell>Use <Link to='/documentation/risks'>Risks</Link> to preserve macros</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>GIF</Table.Cell>
                        <Table.Cell>.gif</Table.Cell>
                        <Table.Cell>image/gif</Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Bitmap</Table.Cell>
                        <Table.Cell>.bmp</Table.Cell>
                        <Table.Cell>image/bmp<br/>image/x-ms-bmp</Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>JPEG</Table.Cell>
                        <Table.Cell>.jpg</Table.Cell>
                        <Table.Cell>image/jpg<br/>image/jpeg</Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>JPEG 2000</Table.Cell>
                        <Table.Cell>.jp2</Table.Cell>
                        <Table.Cell>image/jp2</Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>JPEG XR</Table.Cell>
                        <Table.Cell>.jxr</Table.Cell>
                        <Table.Cell>image/jxr<br/>image/vnd.ms-photo</Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>PNG</Table.Cell>
                        <Table.Cell>.png</Table.Cell>
                        <Table.Cell>image/png</Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>TIFF</Table.Cell>
                        <Table.Cell>.tiff</Table.Cell>
                        <Table.Cell>image/tiff</Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>WebP</Table.Cell>
                        <Table.Cell>.webp</Table.Cell>
                        <Table.Cell>image/webp</Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>XML</Table.Cell>
                        <Table.Cell>.xml</Table.Cell>
                        <Table.Cell>application/xml<br/>text/xml</Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>JSON</Table.Cell>
                        <Table.Cell>.json</Table.Cell>
                        <Table.Cell>application/json</Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>MP3</Table.Cell>
                        <Table.Cell>.mp3</Table.Cell>
                        <Table.Cell>audio/mpeg</Table.Cell>
                        <Table.Cell>This feature is in preview. More information can be found on <Link to='/content-types/preview'>Preview mode</Link> page</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>MP4</Table.Cell>
                        <Table.Cell>.mp4</Table.Cell>
                        <Table.Cell>video/mp4<br/>audio/mp4<br/>application/mp4</Table.Cell>
                        <Table.Cell>This feature is in preview. More information can be found on <Link to='/content-types/preview'>Preview mode</Link> page</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>M4A</Table.Cell>
                        <Table.Cell>.m4a</Table.Cell>
                        <Table.Cell>audio/x-m4a</Table.Cell>
                        <Table.Cell>This feature is in preview. More information can be found on <Link to='/content-types/preview'>Preview mode</Link> page</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>WAV</Table.Cell>
                        <Table.Cell>.wav</Table.Cell>
                        <Table.Cell>audio/x-wav</Table.Cell>
                        <Table.Cell>This feature is in preview. More information can be found on <Link to='/content-types/preview'>Preview mode</Link> page</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>HEIF</Table.Cell>
                        <Table.Cell>.heif</Table.Cell>
                        <Table.Cell>image/heif</Table.Cell>
                        <Table.Cell>This feature is in preview. More information can be found on <Link to='/content-types/preview'>Preview mode</Link> page</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>HEIC</Table.Cell>
                        <Table.Cell>.heic</Table.Cell>
                        <Table.Cell>image/heic</Table.Cell>
                        <Table.Cell>This feature is in preview. More information can be found on <Link to='/content-types/preview'>Preview mode</Link> page</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>MSG</Table.Cell>
                        <Table.Cell>.msg</Table.Cell>
                        <Table.Cell>application/vnd.ms-outlook</Table.Cell>
                        <Table.Cell>This feature is in preview. More information can be found on <Link to='/content-types/preview'>Preview mode</Link> page</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>EML</Table.Cell>
                        <Table.Cell>.eml</Table.Cell>
                        <Table.Cell>message/rfc822</Table.Cell>
                        <Table.Cell>This feature is in preview. More information can be found on <Link to='/content-types/preview'>Preview mode</Link> page</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>ZIP</Table.Cell>
                        <Table.Cell>.zip</Table.Cell>
                        <Table.Cell>application/zip<br/>application/x-zip-compressed<br/>application/x-compressed</Table.Cell>
                        <Table.Cell>This feature is in preview. More information can be found on <Link to='/content-types/preview'>Preview mode</Link> page</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>TNEF</Table.Cell>
                        <Table.Cell>.dat</Table.Cell>
                        <Table.Cell>application/ms-tnef</Table.Cell>
                        <Table.Cell>This feature is in preview. More information can be found on <Link to='/content-types/preview'>Preview mode</Link> page</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>CSS</Table.Cell>
                        <Table.Cell>.css</Table.Cell>
                        <Table.Cell>text/css</Table.Cell>
                        <Table.Cell>This feature is in preview. More information can be found on <Link to='/content-types/preview'>Preview mode</Link> page</Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  <p>
                    Some additional formats can be supported through <Link to='/documentation/format-conversion'>format conversion</Link>.
                  </p>
                  <Table celled selectable>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Format</Table.HeaderCell>
                        <Table.HeaderCell>Extension</Table.HeaderCell>
                        <Table.HeaderCell>Content types</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>MS Word 97</Table.Cell>
                        <Table.Cell>.doc</Table.Cell>
                        <Table.Cell>application/msword</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>MS PowerPoint 97</Table.Cell>
                        <Table.Cell>.ppt</Table.Cell>
                        <Table.Cell>application/vnd.ms-powerpoint</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>MS Excel 97</Table.Cell>
                        <Table.Cell>.xls</Table.Cell>
                        <Table.Cell>application/vnd.ms-excel</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>MS Excel 2007 Binary</Table.Cell>
                        <Table.Cell>.xlsb</Table.Cell>
                        <Table.Cell>application/vnd.ms-excel.sheet.binary.macroenabled.12</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Open Office Writer</Table.Cell>
                        <Table.Cell>.odt</Table.Cell>
                        <Table.Cell>application/vnd.oasis.opendocument.text</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Open Office Impress</Table.Cell>
                        <Table.Cell>.odp</Table.Cell>
                        <Table.Cell>application/vnd.oasis.opendocument.presentation</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Open Office Calc</Table.Cell>
                        <Table.Cell>.ods</Table.Cell>
                        <Table.Cell>application/vnd.oasis.opendocument.spreadsheet</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>CSV</Table.Cell>
                        <Table.Cell>.csv</Table.Cell>
                        <Table.Cell>text/csv</Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  <p>
                    The quality of the conversion depends on the tool used, see
                    <a rel="noopener noreferrer" target="_blank" href='https://help.libreoffice.org/6.3/en-US/text/shared/guide/ms_import_export_limitations.html?&DbPAR=WRITER&System=WIN'> Libre Office documentation </a>
                    for details on how Libre Office deals with conversions.
                  </p>
                </div>
              )
            }
            {
              this.renderItem(activeIndex, itemIndex++,
                'How long will the processing take?',
                <div>
                  <p>
                    The amount of time required to process a file depends on the file type, size and the complexity of the content.
                  </p>
                  <p>
                    The overall time is also impacted by the latency between where your code is running and the API.
                  </p>
                </div>
              )
            }
            {
              this.renderItem(activeIndex, itemIndex++,
                'What options do I have for handling macros in an Office X file?',
                <div>
                  <p>Macro preservation is optional. By default macros will be removed and the file transformed to the non-macro equivalent.</p>
                  <p>Macros can be used for various attacks therefore using them is risky.</p>
                  <p>To preserve macros the relevant <Link to='/documentation/risks'>Risk</Link> must be allowed. The result will have the original macros, and any dangers they contain.</p>
                </div>
              )
            }
            {
              this.renderItem(activeIndex, itemIndex++,
                'How do I provide a schema for json / xml validation?',
                <div>
                  <p>Schema validation is not available yet, details on how to upload schema's and initiate validtion will be made available soon.</p>
                </div>
              )
            }
          </Accordion>

          <Header as='h2'>Getting started</Header>
          <Accordion fluid styled>
            {
              this.renderItem(activeIndex, itemIndex++,
                'Do you have a free trial?',
                <div>
                  <p>
                    Yes we do!
                  </p>
                  <p>
                    The free trial applies to the <Link to='/api/instant'>Instant API</Link>, and full details are available there.
                  </p>
                </div>
              )
            }
            {
              this.renderItem(activeIndex, itemIndex++,
                'Do you support Microsoft Azure?',
                <div>
                  <p>
                    Yes, Microsoft Azure can be used to interact with our Zero Trust CDR services.
                  </p>
                  <p>
                    Using Azure infrastructure generated by our deployment scripts, files can be uploaded to Azure and sent to our AWS-hosted APIs to be transformed into safe content. This infrastructure can be deployed at the push of a button. Full details can be found on our <Link to='/api/azure'>Azure documentation</Link> page.
                  </p>
                  <p>
                    We will support Microsoft Azure natively in the future.
                  </p>
                </div>
              )
            }
            {
              this.renderItem(activeIndex, itemIndex++,
                'Which region should I choose?',
                <div>
                  <p>
                    The APIs have independent services running in various AWS regions.
                    You can activate as many regions as you wish.
                  </p>
                  <p>
                    There are three main factors to selecting a region - cost, latency and data residency.
                    For applications running within AWS all data transfer within the same region is usually free.
                    For non-AWS hosted applications a good region choice can improve latency by over 100ms for each API call.
                    For a simple demonstration of latency differences please visit sites such as <a href='https://www.cloudping.info/' target='_blank'  rel='noopener noreferrer'>CloudPing.info <Icon name='external' size='small'/></a>.
                  </p>
                  <p>
                    If you would like to use a region that isn't available please get in touch.
                  </p>
                </div>
              )
            }
            {
              this.renderItem(activeIndex, itemIndex++,
                'What error responses do I need to handle?',
                <div>
                  <p>
                    Sometimes things don't follow the happy path and an error is returned. This might be due to the code used to call the API, the file submitted or an issue with the service itself.
                  </p>
                  <p>
                    We have a list of the known responses you might receive in the <Link to='/api/errors'>Error Glossary</Link>.
                  </p>
                </div>
              )
            }
          </Accordion>

          <Header as='h2'>Content security</Header>
          <Accordion fluid styled>
            {
              this.renderItem(activeIndex, itemIndex++,
                'Where does my data go?',
                <div>
                  <p>
                    Your content stays within the AWS region of the API you select.
                  </p>
                  <p>
                    AWS provide some information about this in the <a href='https://aws.amazon.com/blogs/security/addressing-data-residency-with-aws/' target='_blank' rel='noopener noreferrer'>Addressing Data Residency with AWS <Icon name='external' size='small'/> blog post.</a>
                  </p>
                </div>
              )
            }
          </Accordion>

          <Header as='h2'>Account</Header>
          <Accordion fluid styled>
            {
              this.renderItem(activeIndex, itemIndex++,
                'How do I change my password?',
                <div>
                  <p>
                    Please use the 'Forgot your password?' link when logging in.
                  </p>
                </div>
              )
            }
            {
              this.renderItem(activeIndex, itemIndex++,
                'Why can\'t I login?',
                <div>
                  <p>
                    The email address you login with is case sensitive, please use the same casing you registered with.
                  </p>
                  <p>
                    After you have registered you will need to conirm your email address. A verification will be sent to you. You will be unable to login until this is completed.
                  </p>
                </div>
              )
            }
            {
              this.renderItem(activeIndex, itemIndex++,
                'Can I give limited access to my account to other members of my team?',
                <div>
                  <p>Yes, enterprise users can allow other users to access thier account using role based access control.</p>
                  <p>
                    This feature is available via the { isAuthenticated ? <Link to='account'>Accounts page</Link> : 'Accounts Page'}.
                    The Manage Users button opens up an interface where you can enter the email addresses of your team members
                    and set their roles.
                  </p>
                  <p>The roles you can assign to people are as follows:-</p>
                  <List bulleted>
                    <List.Item header="viewer"
                      description="Viewers are able to view which APIs you are subscribed to and view the usage dashboard."/>
                    <List.Item header="developer"
                      description="Developers are able to view subscriptions and API keys. They can also use the demos."/>
                    <List.Item header="manager"
                      description="Managers in addtion to being able to view usage and API keys as with viewers and developers, can also subscribe/unsubscribe to APIs and create/delete API keys." />
                    <List.Item header="owner"
                      description="Owners have all the permissions of manager but can also assign roles and add users to the account."/>
                  </List>
                </div>
              )
            }
            {
              this.renderItem(activeIndex, itemIndex++,
                'Can I monitor the usage of my account',
                <div>
                  <p>The portal provides a dashboard to keep track of a number of usage metrics on your account, these include:-</p>
                  <List bulleted>
                    <List.Item header='Total/average input volume'
                      description='This records the actual size of the files uploaded to the API, for compressed archives/documents, this will be the compressed size.'/>
                    <List.Item>
                      <List.Header content='Total/average output volume'/>
                      <List.Description>
                        This records the size of the transformed files downloaded from the API, this will usually differ from the
                        input size as the Zero Trust CDR engine will have removed certain parts of the file. This doesn't always
                        result in a smaller file as the final size of the file depends on how the data is encoded and transformed
                        files may end up larger in some cases.
                      </List.Description>
                    </List.Item>
                    <List.Item>
                      <List.Header content='Total/average billed volume'/>
                      <List.Description>
                        The billed volume is the uncompressed size of the file being processed with a minimum file size of 100kb.
                        This will likely be larger than the input/output file size for documents and compressed archives as the
                        transform engine has to process the files within the archive.
                        The minimum file size of 100kb will be reported for any file smaller than that, and also for certain
                        errors which we identify early enough we will bill the minimum size (others are billed at the full size).
                        For more detail see the <Link to='/pricing'>Pricing/Billing page</Link>.
                      </List.Description>
                    </List.Item>
                    <List.Item header ='Total number of files'
                      description='This is simply a breakdown of the number of files processed by content type'/>
                    <List.Item>
                      <List.Header content='Processing time'/>
                      <List.Description>
                        This metric records how long the file took to process, this is also broken down by content type.
                        As documents can vary greatly in complexity the time to process can also vary, this metric will
                        give you a better idea of how long typical work loads take to process.
                      </List.Description>
                    </List.Item>
                    <List.Item>
                      <List.Header content='Transformation rate'/>
                      <List.Description>
                        This is the success rate of transformations, usually this should be 100% accross the board but if
                        the transform engine fails for any reason (i.e. being passed a corrupt file) the failure will be
                        reflected in this metric.
                      </List.Description>
                    </List.Item>
                  </List>
                </div>
              )
            }
          </Accordion>

          <Header as='h2'>Disaster recovery</Header>
          <Accordion fluid styled>
            {
              this.renderItem(activeIndex, itemIndex++,
                'What do I need to do in the event of a serious incident?',
                <div>
                  <p>
                    With respect to GDPR and general best practices we deliberately keep very little customer data, so there is little risk of anything sensitive being exposed.
                  </p>
                  <p>
                    In a situation which results in a part or parts of the system being irreparably damaged or becoming untrustworthy,
                    we will simply replace the necessary part or parts.
                    This may require you to recreate your API Keys and/or management user login details.
                    We may lose some historical data for your account.
                  </p>
                </div>
              )
            }
          </Accordion>
        </Container>
      </Layout>
    )
  }
}
