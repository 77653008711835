import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Icon, Step } from 'semantic-ui-react'
import axios from 'axios'
import download from 'js-file-download'

import TryIt, { configureAxios, sizeInMB, addResponseToState, addRequestToState } from '../../components/TryIt'

export default class TryItAsync extends PureComponent {
  static propTypes={
    match: PropTypes.shape({
      params: PropTypes.shape({
        usagePlanId: PropTypes.string,
        apiKeyIndex: PropTypes.string,
      })
    }),
    location: PropTypes.object,
    apiKeys: PropTypes.array,
  }
  state = {}
  apiId = 'async'
  apiTitle = 'Async'
  sizeLimit = '250'
  sizeLimitInBytes = this.sizeLimit * 1024 * 1024

  checkStatusInterval = 1000

  constructor(props) {
    super(props)
    this.state = this.getInitialState()
    this.setRequest = data => this.setState(addRequestToState(data))
    this.setResponse = (response, data) => this.setState(addResponseToState(response, data))
    this.axios = configureAxios(axios, this.setRequest, this.setResponse)
  }

  getInitialState() {
    return {
      comms: [],
      steps: {},
      result: null,
      error: null,
      fileName: null,
      fileType: null,
      outFile: null,
      outType: null,
      timestamps: null,
      jobId: null,
    }
  }

  handleDrop = (files, usagePlan, allowedRisks, deniedRisks, imageQualityList, reportFormat) => {
    if (this.timeLeftInterval) clearInterval(this.timeLeftInterval)
    this.setState({
      ...this.getInitialState(),
      ...{files},
      jobId: Date.now(),
      fileName: files[0].name,
      fileType: files[0].contentType,
      outFile: files[0].convertedFilename || files[0].name,
      outType: files[0].convertedMimeType || files[0].contentType
    })

    // first get the pre-signed URL
    this.getUploadUrl(files[0], usagePlan, allowedRisks, deniedRisks, imageQualityList, reportFormat)
      .then(response => this.upload(response.data, files))
      .then(this.complete)
      .catch(error => {
        console.error(error)
        if (error.response) {
          this.showError(error.response)
        } else {
          this.setState({ error: { message: error?.message || 'Unknown error' } })
        }
      })
  }

  getUploadUrl = async (file, usagePlan, allowedRisks = [], deniedRisks = [], imageQualityList, reportFormat) => {
    if (file.unsupported) throw new Error('Unsupported file type')
    if (file.size > this.sizeLimitInBytes) {
      throw new Error(`File size (${sizeInMB(file.size)} MB) exceeds maximum size of ${this.sizeLimit} MB`)
    }

    const data = { 'mime-type': file.convertedMimeType ? 'application/octet-stream' : file.contentType }
    if (file.convertedMimeType || allowedRisks.length || deniedRisks.length) {
      data.options = {};
      if (file.convertedMimeType) data.options.conversion = { from: { 'mime-type': file.contentType } }
      if (allowedRisks.length || deniedRisks.length) data.options.risks = {
        allow: allowedRisks, deny: deniedRisks
      }
    }

    if (imageQualityList && imageQualityList.length) {
      if (!data.options) data.options = {}
      data.options.images = { quality: { preserve: imageQualityList}}
    }

    if (!data.options) data.options = {}
    if (reportFormat && reportFormat !== 'default') data.options.report = {"format": reportFormat}


    return this.axios({
      method: 'post',
      url: `${usagePlan.url}/initiate`,
      data: data,
      headers: this.generateHeaders(file.contentType, file.convertedMimeType)
    })
    .then(response => {
      this.finishStep('start')

      return response
    })
  }

  generateHeaders = () => {
    const { apiKeys, match: { params: { apiKeyIndex } } } = this.props
    return {
      'Content-Type' : 'application/json',
      'x-api-key': apiKeys[parseInt(apiKeyIndex)].value,
    }
  }

  upload = async (data, files) =>
    // now do a PUT request to the pre-signed URL
    this.axios({
      method: data.links.upload.method,
      url: data.links.upload.url,
      data: files[0].data,
      headers: data.links.upload.headers
    })
    .then(() => {
      this.finishStep('upload')
      return this.checkStatus(data.links.status, true)
    })

  handleDownload = () => {
    const {result} = this.state

    return this.axios({
      method: result.raw.method,
      url: result.raw.url,
      headers: result.raw.headers,
      maxRedirects: 0,
      responseType: 'blob'
    }).catch(response =>
      this.showError(response)
    ).then(response =>
      download(response.data, `transformed-${result.fileName}`, result.type)
    )
  }

  complete = (response) => {
    const { outFile } = this.state
    this.finishStep('status')

    if (!response.data.error && response.data.status.success) {
      // Handle cases where the file extension has changed
      const extension = response.data.links?.result?.url.split('?')[0].split('.').pop();
      const fileName = extension ? outFile.split('.')[0] + '.' + extension : outFile;

      this.finishStep('complete')
      this.setState({
        timestamps: response.data._metadata.timestamps,
        result : {
          raw: response.data.links.result,
          url : response.data.links.result.url,
          size: response.data.links.result.fileSize,
          expiry: response.data.links.result.expiry,
          fileName: fileName,
          type: response.data._metadata.contentType,
          conversionBy: response.data._metadata?.conversion?.before?.with[0]?.name,
          report: response.data.report,
          risks: response.data?.risks?.taken || []
        }
      })
    } else {
      this.showError(response)
    }
  }

  showError = (response) => {
      this.setState({
        timestamps: (response.data._metadata || {}).timestamps,
        error: {...(response.data.error || {type: 'Unknown', message: 'Something unexpected happened'}), ...{report: response.data.report}},
      })
  }

  finishStep = (name) => {
    const {steps} = this.state
    this.setState({ steps: Object.assign({}, steps, { [name]: true }) })
  }

  delay = ms => new Promise(r => setTimeout(r, ms))

  checkStatus = (statusLink, untilComplete) => {
    const {jobId} = this.state
    if (!untilComplete) return this.axios({ method: statusLink.method, url: statusLink.url, headers: statusLink.headers })

    const checkStatusUntilComplete = (thisJobId, statusLink, etag) => {
      if (jobId !== thisJobId)
        return
      const config = {
        method: statusLink.method,
        url: statusLink.url,
        validateStatus: status => status < 400, // Reject only if the status code is greater than or equal to 400
        headers: statusLink.headers
      }
      if (etag)
        config.headers['If-None-Match'] = etag

      return this.axios(config).then(response =>
        response.data && response.data.status.complete
          ? response
          : this.delay(this.checkStatusInterval)
            .then(() => checkStatusUntilComplete(jobId, response.data ? response.data.links.status : statusLink, response.headers['etag']))
      ).catch(error =>
        (error.response || {}).status === 429
        ? this.delay(this.checkStatusInterval)
          .then(() => checkStatusUntilComplete(jobId, statusLink, etag))
        : Promise.reject(error)
      )
    }

    return checkStatusUntilComplete(jobId, statusLink)
  }

  renderSteps() {
    const { steps = {} } = this.state
    return (
      <Step.Group widths={4} attached='top'>
        <Step active={!steps.start} completed={steps.start}>
          <Icon name='send' />
          <Step.Content>
            <Step.Title>Get url</Step.Title>
          </Step.Content>
        </Step>

        <Step active={steps.start && !steps.upload} disabled={!steps.start} completed={steps.upload}>
          <Icon name='cloud upload' />
          <Step.Content>
            <Step.Title>Upload</Step.Title>
          </Step.Content>
        </Step>

        <Step active={steps.upload && !steps.status} disabled={!steps.upload} completed={steps.status}>
          <Icon name='refresh' loading={steps.upload && !steps.status}/>
          <Step.Content>
            <Step.Title>Check status</Step.Title>
          </Step.Content>
        </Step>

        <Step active={steps.status && !steps.complete} disabled={!steps.status} completed={steps.complete}>
          <Icon name='file outline' />
          <Step.Content>
            <Step.Title>Ready to download</Step.Title>
          </Step.Content>
        </Step>
      </Step.Group>
    )
  }

  render() {
    const { location, match: { params: { apiKeyIndex, usagePlanId } } } = this.props
    const { comms, error, result } = this.state

    return <TryIt comms={comms} onDrop={this.handleDrop} apiKeyIndex={apiKeyIndex} usagePlanId={usagePlanId} currentLocation={location} error={error} result={result} steps={this.renderSteps()} apiTitle={this.apiTitle} apiId={this.apiId} onDownloadClick={this.handleDownload} sizeLimit={this.sizeLimit} />
  }
}