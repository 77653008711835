/* global AWS */
// import AWS from 'aws-sdk'
// import * as AWS from '../aws.core.min.js'
export const awsRegion = 'us-west-2'
export const cognitoRegion = 'us-west-2'
export const cognitoUserPoolId = 'us-west-2_LQ5eb3LJE'
export const cognitoIdentityPoolId = 'us-west-2:e5cb8776-605d-47b3-b9b3-ffde630a90ab'
export const cognitoClientId = '2oi2nl4jq0cqvpptahoko0t5k1'
export const cognitoUserPoolDomain = 'tr-portal-test.auth.us-west-2.amazoncognito.com'
export const websiteUrl = 'https://threat-removal.test.dsdevops.com/'
export const apiUrl = 'https://threat-removal.test.dsdevops.com/prod'
export const apiGatewayApiId = 'cdif5w6ehe' // the minimum needed for running locally in a meaningful way
export const s3BucketName = 'tr-portal-test'
export const accountId = '596754055315'
export const mfaProviderName = 'tr-portal-test'

export function getApiGatewayPublicApiUrl() {
    return apiUrl
}

export function getApiGatewayRealApiHost() {
    return `${apiGatewayApiId}.execute-api.${awsRegion}.amazonaws.com`
}

AWS.config.region = cognitoRegion
