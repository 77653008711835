import { LOAD_PRODUCT_SUCCEEDED, LOADING_PRODUCT, LOAD_PRODUCT_FAILED } from '../actions/productActions'

function productReducer(existing = {}, action) {
  switch(action.type) {
    case LOAD_PRODUCT_SUCCEEDED:
      return {
        items: action.payload
      }
    case LOADING_PRODUCT:
      return {
        ...existing,
        loading: true,
      }
    case LOAD_PRODUCT_FAILED:
      return {
        ...existing,
        loading: false,
      }

    default:
      return existing
  }
}

export default productReducer