import { connect } from 'react-redux'

import { withData, withStateMappedToProps, IS_AUTHENTICATED, USER_CONFIG_YARA_ENABLED, USER_CONFIG_ENTERPRISE_ENABLED,
  USER_CONFIG_MFA_ENABLED, USER_CONFIG_HAS_PRODUCTS, USER_CONFIG_ACCOUNTS, ACCOUNT, USER_CONFIG_FREE_TRIAL_STATUS } from '../../../redux/withData'
import { switchAccount, cancelSwitchAccount } from '../../../redux/actions/userActions'

import TheComponent from './LayoutHeader.js';

const requiredData = [IS_AUTHENTICATED, USER_CONFIG_YARA_ENABLED, USER_CONFIG_ENTERPRISE_ENABLED, USER_CONFIG_MFA_ENABLED,
  USER_CONFIG_HAS_PRODUCTS, USER_CONFIG_ACCOUNTS, ACCOUNT, USER_CONFIG_FREE_TRIAL_STATUS]

const mapStateToProps = state => {
  return withStateMappedToProps(state, requiredData)
}

const mapDispatchToProps = { switchAccount, cancelSwitchAccount }

export default connect(mapStateToProps, mapDispatchToProps)(withData(requiredData)(TheComponent))