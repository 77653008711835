import React, { PureComponent } from 'react'
import Layout from '../../components/Layout'
import { Link } from 'react-router-dom'
import { Container, Header, List } from 'semantic-ui-react'
import { PrismAsyncLight as SyntaxHighlighter } from "react-syntax-highlighter";
import { tomorrow as SyntaxStyle } from 'react-syntax-highlighter/dist/cjs/styles/prism';


export default class ContentTypesPreview extends PureComponent {
  render() {
    return (
      <Layout header='Preview mode'>
        <Container>
          <Header as='h2'>Why are some content types only available in preview?</Header>
          <p>It is important to us that the results from the API are reliable and of the highest quality.
          Sometimes if our support for a format is not up to our standards and we want to make it available for people to start using, then we release it in preview mode.</p>
          <p>The reasons may differ between formats, but they include things such as:</p>
          <List bulleted>
            <List.Item>
              Some common features may not be implemented
            </List.Item>
            <List.Item>
              We may be about to change the way the transformation works internally. This may result in different data structures within the result when it comes out of preview.
            </List.Item>
          </List>
          <p>For more details see the relevant page for the content type.</p>

          <Header as='h2'>Does this make the result less secure?</Header>
          <p>The transformation process is still as secure.</p>

          <Header as='h2'>Why do I need to enable preview mode?</Header>
          <p>By requiring you to enable preview mode we are also giving you the opportunity to understand the implications this may have on your use case. For many operations this will have no impact, however if you are making use of particular features, or have automated post processing that relies on specific structures then you may need to take this into account.</p>
          <p>Please get in touch if you have any queries.</p>

          <Header as='h2'>How do I enable preview mode?</Header>
          <p>Simply add the HTTP Header <code>X-Options</code> with a JSON options object describing all of the content types that you accept preview mode for.</p>
          <p>For example, to enable ALL preview formats</p>
          <SyntaxHighlighter language='text' style={SyntaxStyle}>{'curl --header \'x-Options: {"risks":{"allow":["preview"]}}\''}</SyntaxHighlighter>
          <p>Please see <Link to='/documentation/risks'> managing risks </Link> on allowing risks</p>

        </Container>
      </Layout>
    )
  }
}
