import { CLEAR_ERROR, NEW_ERROR, ERROR_REMOVE, CUSTOM_FAILURE } from '../actions/errorActions'
import { all, call, fork, put, take } from 'redux-saga/effects'

export function* clearError(action) {
  yield put({type: ERROR_REMOVE, payload: action.payload})
}

export function* watchClear() {
  while (true) {
    const action = yield take(CLEAR_ERROR)
    yield fork(clearError, action)
  }
}

export function* newError(action) {
  yield put({type: CUSTOM_FAILURE, payload: action.payload})
}

export function* watchNew() {
  while (true) {
    const action = yield take(NEW_ERROR)
    yield fork(newError, action)
  }
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    call(watchClear),
    call(watchNew),
  ])
}