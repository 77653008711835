import { connect } from 'react-redux'

import { withData, withStateMappedToProps, CATALOG, IS_AUTHENTICATED, SUBSCRIPTIONS, API_KEYS } from '../../redux/withData'

import { loadApiKeys } from '../../redux/actions/apiKeyActions'

import TheComponent from './TryItAsync.js';

const requiredData = [CATALOG, IS_AUTHENTICATED, SUBSCRIPTIONS, API_KEYS]

const mapStateToProps = (state, ownProps) => {
  const { match: { params: { usagePlanId} } } = ownProps
  const params = {usagePlanId: usagePlanId}
  return withStateMappedToProps(state, requiredData, params)
}

const mapDispatchToProps = {
  loadApiKeys: (usagePlanId) => loadApiKeys(usagePlanId, true),
}

export default connect(mapStateToProps, mapDispatchToProps)(withData(requiredData)(TheComponent))