import React, { PureComponent } from 'react'
import {Icon, Popup } from 'semantic-ui-react'
import PropTypes from 'prop-types'

import './ScrollButton.css'

export default class ScrollButton extends PureComponent {

    static propTypes = {
        scrollDirection: PropTypes.string,
        scrollRef: PropTypes.object.isRequired,
        alwaysVisible: PropTypes.bool.isRequired,
        isVisible: PropTypes.bool
    }

    // Used to hold the props of each button type
    buttonStyles = {
        // Default scroll direction
        'down': {
            'classNames': 'scroll-button bouncing',
            'iconType': 'angle double down',
            'popupContent': 'More information',

        },
        'up': {
            'classNames': 'scroll-button',
            'iconType': 'angle double up',
            'popupContent': 'Return to the top of the page',

        }
    }

    // Generic scroll to react Ref function
    scrollToRef = (ref) => {
        ref.current.scrollIntoView({behavior: "smooth", block: "center"})
    }

    render() {
        // Destructure props - default scrollDirection is down
        const {scrollDirection = 'down', scrollRef, isVisible, alwaysVisible} = this.props
        
        // Get button styles based on buttonType 
        const {classNames, iconType, popupContent} = this.buttonStyles[scrollDirection]

        // If button is not always visible, set fade-in/fade-out based on isVisible prop
        var fade = ''
        if(alwaysVisible !== true) {
            // fade-in or fade-out dictated by isVisible prop
            fade = isVisible ? ' fade-in' : ' fade-out'
        }

        return <div className={classNames + fade}>
            <Popup
            trigger={
                <Icon link circular inverted name={iconType} size='big' onClick={() => this.scrollToRef(scrollRef)} />
            }
            content= {popupContent}
            position='right center'
            inverted
            />
      </div>
    }
}