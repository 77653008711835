import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Icon, Step } from 'semantic-ui-react'
import axios from 'axios'
import download from 'js-file-download'
import { fileReader } from '../../services/misc'

import TryIt, { configureAxios, sizeInMB, addRequestToState, addResponseToState } from '../../components/TryIt'

// These types of files might be returned as a different type due to risk settings etc.
const fallbackTypes = [
  'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'application/vnd.ms-word.document.macroEnabled.12'
]

export default class TryItInstant extends PureComponent {
  static propTypes={
    match: PropTypes.shape({
      params: PropTypes.shape({
        usagePlanId: PropTypes.string,
        apiKeyIndex: PropTypes.string,
      })
    }),
    location: PropTypes.object,
    apiKeys: PropTypes.array,
  }
  state = {}
  apiId = 'instant'
  apiTitle = 'Instant'
  sizeLimit = '4.5'
  sizeLimitBytes = this.sizeLimit * 1024 * 1024

  constructor(props) {
    super(props)
    this.state = this.getInitialState()
    this.setRequest = data => this.setState(addRequestToState(data))
    this.setResponse = (response, data) => this.setState(addResponseToState(response, data))
    this.axios = configureAxios(axios, this.setRequest, this.setResponse)
  }

  getInitialState() {
    return {
      comms: [],
      steps: {},
      result: null,
      error: null,
    }
  }

  handleDrop = (files, usagePlan, allowedRisks, deniedRisks, imageQualityList, reportFormat) => {
    this.setState({...this.getInitialState(), ...{files}})
    this.upload(files, usagePlan, allowedRisks, deniedRisks, imageQualityList, reportFormat)
      .then(this.complete)
      .catch(error => {
        console.error(error)
        if (error.response?.data) {
          fileReader(error.response.data).then(data => {
            const body = JSON.parse(data);
            this.setResponse({}, data)
            this.setState({ error: {...body.error, ...{report: body.report} } })
          });
        } else {
          this.setState({ error: { message: error?.message || 'Unknown error' } })
        }
      })
  }

  upload = async (files, usagePlan, allowedRisks, deniedRisks, imageQualityList, reportFormat) => {
    if (files[0].unsupported) throw new Error('Unsupported file type')
    if (files[0].size > this.sizeLimitBytes) {
      throw new Error(`File size (${sizeInMB(files[0].size)} MB) exceeds maximum size of ${this.sizeLimit} MB`)
    }

    this.setState({ fileName: files[0].name, outFile: files[0].convertedFilename || files[0].name })
    // now do a POST request to the api URL
    return this.axios({
        method: 'post',
        url: `${usagePlan.url}/upload`,
        data: files[0].data,
        headers: this.generateHeaders(files[0].contentType, files[0].convertedMimeType, allowedRisks, deniedRisks, imageQualityList, reportFormat),
        responseType: 'blob',
      })
      .then(response => {
        this.finishStep('upload')
        return response
      })
  }

  generateHeaders = (fileType, convertToType, allowedRisks, deniedRisks, imageQualityList, reportFormat) => {
    const { apiKeys, match: { params: { apiKeyIndex } } } = this.props
    const contentType = convertToType || fileType;
    const resultType = fallbackTypes.includes(contentType) ? '*/*' : contentType;
    const headers = {
      'Content-Type' : convertToType ? 'application/octet-stream' : fileType,
      'Accept' : `${resultType},application/json`,
      'x-api-key': apiKeys[parseInt(apiKeyIndex)].value
    }
    const options = {}
    if (convertToType) options.conversion = { from: { "mime-type": fileType } };
    if ((allowedRisks && allowedRisks.length) || (deniedRisks && deniedRisks.length)) {
      options.risks = {};
      if (allowedRisks && allowedRisks.length) options.risks.allow = allowedRisks;
      if (deniedRisks && deniedRisks.length) options.risks.deny = deniedRisks;
    }
    if (imageQualityList && imageQualityList.length > 0) {
      options.images = { quality: { preserve: imageQualityList}}
    }
    if (reportFormat && reportFormat !== 'default') options.report = {"format": reportFormat}
    if (Object.keys(options).length)  headers["x-options"] = JSON.stringify(options);
    return headers;
  }

  complete = (response) => {
    const { outFile } = this.state
    const conversionHeader = response.headers['x-conversion']
    const reportHeader = response.headers['x-report']
    const result = response.data
    result.fileName = outFile
    result.conversionBy = conversionHeader ? JSON.parse(conversionHeader).before.with[0].name : null
    result.report = reportHeader ? JSON.parse(reportHeader) : null
    const risksHeader = response.headers['x-risks-taken']
    if (risksHeader) result.risks = risksHeader.split(',')
    const extension = response.headers['x-file-extension']
    if (extension) result.fileName = result.fileName.split('.').slice(0, -1).concat([extension]).join('.')

    this.finishStep('complete')
    this.setState({ fileType: response.headers['content-type'], result })
  }

  finishStep = (name) => {
    const {steps} = this.state
    this.setState({ steps: Object.assign({}, steps, { [name]: true }) })
  }

  handleDownload = () => {
    const {result} = this.state
    download(result, `transformed-${result.fileName}`, result.type)
  }

  renderSteps() {
    const { steps = {} } = this.state
    return (
      <Step.Group widths={4} attached='top'>
        <Step active={!steps.upload} completed={steps.upload}>
          <Icon name='cloud upload' />
          <Step.Content>
            <Step.Title>Upload</Step.Title>
          </Step.Content>
        </Step>

        <Step active={steps.upload && !steps.complete} disabled={!steps.upload} completed={steps.complete}>
          <Icon name='file outline' />
          <Step.Content>
            <Step.Title>Complete</Step.Title>
          </Step.Content>
        </Step>
      </Step.Group>
    )
  }

  render() {
    const { location, match: { params: { apiKeyIndex, usagePlanId } } } = this.props
    const { comms, error, result } = this.state

    return <TryIt comms={comms} onDrop={this.handleDrop} apiKeyIndex={apiKeyIndex} usagePlanId={usagePlanId} currentLocation={location} error={error} result={result} steps={this.renderSteps()} apiTitle={this.apiTitle} apiId={this.apiId} onDownloadClick={this.handleDownload} sizeLimit={this.sizeLimit} />
  }
}