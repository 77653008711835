import { connect } from 'react-redux'

import { withData, withStateMappedToProps, IS_AUTHENTICATED, PRODUCTS, ACCOUNT } from '../../redux/withData'
import { adminSwitchAccount } from '../../redux/actions/userActions'

import TheComponent from './UserManagement.js';

const requiredData = [IS_AUTHENTICATED, PRODUCTS, ACCOUNT]

const mapStateToProps = state => {
  return withStateMappedToProps(state, requiredData)
}

const mapDispatchToProps = { adminSwitchAccount }

export default connect(mapStateToProps, mapDispatchToProps)(withData(requiredData)(TheComponent))