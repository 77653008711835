import { getApiGatewayClientForSession } from './self'

export async function adminSwitchAccount(email) {
    const apiGatewayClient = await getApiGatewayClientForSession();
    const response = await apiGatewayClient.post('/admin/switch-account', {}, { email });
    return { accountId: response.data.accountId, role: 'administrator' }
}

export async function switchAccount(email) {
    const apiGatewayClient = await getApiGatewayClientForSession();
    const response = await apiGatewayClient.post('/switch-account', {}, { email });
    return { accountId: response.data.accountId, role: response.data.role, isDefaultAccount: response.data.isDefaultAccount };
}

export async function setDefaultAccount(email) {
    const apiGatewayClient = await getApiGatewayClientForSession();
    await apiGatewayClient.post('/default-account', {}, { email });
}

export async function getDelegates() {
    const apiGatewayClient = await getApiGatewayClientForSession();
    const response = await apiGatewayClient.get('/delegates', {}, {}, null, ['admin']);
    return response?.data?.delegates || [];
}

export async function addDelegate(email, metaRole, roles) {
    const apiGatewayClient = await getApiGatewayClientForSession();
    const response = await apiGatewayClient.post(`/delegates`, {}, { email, metaRole, roles }, null, ['admin']);
    return response?.data?.delegates || [];
}

export async function updateDelegateRole(userId, metaRole, roles) {
    const apiGatewayClient = await getApiGatewayClientForSession();
    const response = await apiGatewayClient.put(`/delegates`, {}, { userId, metaRole, roles }, null, ['admin']);
    return response?.data?.delegates || [];
}

export async function removeDelegate(userId) {
    const apiGatewayClient = await getApiGatewayClientForSession();
    const response = await apiGatewayClient.delete(`/delegates`, {}, { userId }, null, ['admin']);
    return response?.data?.delegates || [];
}

export async function acceptEula() {
    const apiGatewayClient = await getApiGatewayClientForSession();
    await apiGatewayClient.post('/accept-eula', {}, { });
}