export const LOAD_DELEGATES = 'LOAD_DELEGATES'
export const LOADING_DELEGATES = 'LOADING_DELEGATES'
export const LOAD_DELEGATES_SUCCEEDED = 'LOAD_DELEGATES_SUCCEEDED'
export const LOAD_DELEGATES_FAILED = 'LOAD_DELEGATES_FAILED'
export const ADD_DELEGATE = 'ADD_DELEGATE'
export const ADD_DELEGATE_SUCCEEDED = 'ADD_DELEGATE_SUCCEEDED'
export const ADD_DELEGATE_FAILED = 'ADD_DELEGATE_FAILED'
export const UPDATE_DELEGATE_ROLE = 'UPDATE_DELEGATE_ROLE'
export const UPDATE_DELEGATE_ROLE_SUCCEEDED = 'UPDATE_DELEGATE_ROLE_SUCCEEDED'
export const UPDATE_DELEGATE_ROLE_FAILED = 'UPDATE_DELEGATE_ROLE_FAILED'
export const REMOVE_DELEGATE = 'REMOVE_DELEGATE'
export const REMOVE_DELEGATE_SUCCEEDED = 'REMOVE_DELEGATE_SUCCEEDED'
export const REMOVE_DELEGATE_FAILED = 'REMOVE_DELEGATE_FAILED'

export function loadDelegates() {
    return { type: LOAD_DELEGATES }
}

export function addDelegate(email, metaRole, roles) {
    return { type: ADD_DELEGATE, payload: { email, metaRole, roles } }
}

export function updateDelegatesRole(userId, metaRole, roles) {
    return { type: UPDATE_DELEGATE_ROLE, payload: { userId, metaRole, roles } }
}

export function removeDelegate(userId) {
    return { type: REMOVE_DELEGATE, payload: { userId } }
}