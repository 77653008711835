import React, { PureComponent } from 'react'
import Layout from '../../../components/Layout'
import { Container, Header } from 'semantic-ui-react'

export default class ImageQuality extends PureComponent {

  render() {
    return (
      <Layout header='Risks of preserving image quality'>
        <Container>

          <Header as='h3'>The risk</Header>
          <p>
            Image files can be used to hide data, either to bring it into an organisation without being detected
            or to take confidential information out of an organisation without being detected.
            The technique used to hide data within images is called steganography. It relies on making subtle
            changes to the image that are almost impossible for humans to perceive.
          </p>
          <p>
            Steganography can be very difficult to detect, and for certain types of steganography there is no
            known way of detecting it at all.
          </p>
          <p>
            There is however a limited capacity for hiding information within an image, as the more information
            stored the more the image is altered. So by using steganography to add our own meaningless information
            to the image, we can destroy any information an attacker has hidden there.
          </p>
          <p>
            The downside of steganography disruption is that whilst the changes are "almost" impossible to detect
            there is still some distruption of the image. For certain workloads this effect can be undesirable.
          </p>
          <Header as='h3'>The options</Header>
          <p>
            If you require the image quality to be preserved, and you are willing to accept the risk posed by
            steganography (or you are certain there can be no steganography in the image), then a list of File
            types that are to be transformed without the anti-steganography disruption can be passed to the service.
          </p>
          <p>
            This feature is found in the options object under images.quality.preserve the details for this are in
            the API definition.
          </p>

        </Container>
      </Layout>
    )
  }
}
