
const quoteIfNeeded = (data) => data.match(/[",\n]/) ? `"${data.replace('"', '""')}"` : data;

// download the data as a csv file
export function csvDownload(filename, headers, data) {
    const rows = data.map(row => row.map(cell => {
        if (cell == null) return "";
        if (cell.toUTCString) return quoteIfNeeded(cell.toUTCString());
        if (typeof cell === 'number') return cell;
        return quoteIfNeeded(cell || "");
    }).join(','));
    rows.unshift(headers.map(header => quoteIfNeeded(header)).join(','));

    const blob =  window.URL.createObjectURL( new Blob( [rows.join('\n')], { type: 'text/csv' }))
    const link = document.createElement('a')
    link.href = blob;
    link.download = filename;
    document.body.appendChild(link);

    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window}));

    document.body.removeChild(link);

    setTimeout(() => window.URL.revokeObjectURL(blob), 5000)
}