import { getApiGatewayClientForSession } from './self'
// export let subscriptions
import { websiteUrl } from './aws'

import catalogData from './data/catalog.json'
import productsData from './data/products.json'
import regionsData from './data/regions.json'

const webUrl = websiteUrl.replace('index.html', 'index.html#/');

export async function getCatalog() {
  return catalogData
}

export async function getProducts() {
  return productsData
}

export async function getRegions() {
  return regionsData
}

export function findApiByName(catalog, apiName) {
  if (!catalog || !catalog.length) return
  return catalog.find(a => a.apiName === apiName)
}

export async function fetchUsagePlans() {
  const apiGatewayClient = await getApiGatewayClientForSession();
  try {
    const response = await apiGatewayClient.get('/usage-plans', {}, {}, {});
    return response.data || [];
  } catch(e) {
    if (e?.request?.status === 403) return [];
    throw e
  }
}

export async function fetchSubscriptions(refresh) {
  const query = refresh ? {queryParams: { refresh: true}} : {};
  const apiGatewayClient = await getApiGatewayClientForSession();
  try {
    const response = await apiGatewayClient.get('/subscriptions', {}, {}, query);
    return response.data || [];
  } catch(e) {
    if (e?.request?.status === 403) return [];
    throw e
  }
}

export async function fetchSecurePageContent(pageName) {
  // get subscribed usage plans
  const apiGatewayClient = await getApiGatewayClientForSession();
  try {
    const response = await apiGatewayClient.get(`/pages/${pageName}`, {}, {}, {});
    const content = (response?.data?.content || "").replace(/__WEB_URL__/g, webUrl)
    return {content};
  } catch(e) {
    if (e?.request?.status === 403) return {};
    throw e;
  }
}

export function isSubscribed(usagePlanId, subscriptions) {
  return !!getSubscribedUsagePlan(usagePlanId, subscriptions)
}

// export function getUsagePlanApiStages(usagePlanId) {
//   const subscribedUsagePlan = getSubscribedUsagePlan(usagePlanId)
//
//   return (subscribedUsagePlan && subscribedUsagePlan.apiStages) || []
// }

export function getSubscribedUsagePlan(usagePlanId, subscriptions) {
  const subscribedUsagePlan = subscriptions && subscriptions.find && subscriptions.find(s => s.id === usagePlanId)
  return subscribedUsagePlan
}

export async function addSubscription(usagePlanId) {
  const apiGatewayClient = await getApiGatewayClientForSession();
  const response = await apiGatewayClient.put(`/subscriptions/${usagePlanId}`, {}, {}, null, ['subscribe']);
  return response.data || [];
}

export async function submitFreeTierApplication(details, source, sourceDetails, contact, customerName) {
  const apiGatewayClient = await getApiGatewayClientForSession();
  const response = await apiGatewayClient.post(
    `/free-tier/apply`, {},
    {details, source, sourceDetails, contact, customerName},
    null, ['admin']
  );
  return response.data || [];
}

export async function submitCreateUser(email, freetrial) {
  const apiGatewayClient = await getApiGatewayClientForSession();
  return apiGatewayClient.post(`/admin/user`, {}, {email, freetrial}, null, ['admin']);
}

export async function adminGetUser(email) {
  const apiGatewayClient = await getApiGatewayClientForSession();
  return apiGatewayClient.get(`/admin/user`, {}, {}, {queryParams: { 'email': email }}, ['admin']);
}

export async function adminSetBillingRef(email, BillingRef) {
  const apiGatewayClient = await getApiGatewayClientForSession();
  return apiGatewayClient.post(`/admin/billingref`, {}, {email, BillingRef}, null, ['admin']);
}

export async function adminEnableAutoProductSubscribe(email, product) {
  const apiGatewayClient = await getApiGatewayClientForSession();
  return apiGatewayClient.post(`/admin/autoproductsubscribe`, {}, {email, product}, null, ['admin']);
}

export async function adminDisableAutoProductSubscribe(email, product) {
  const apiGatewayClient = await getApiGatewayClientForSession();
  return apiGatewayClient.delete(`/admin/autoproductsubscribe`, {}, {email, product}, null, ['admin']);
}

export async function adminSubscribe(email, product) {
  const apiGatewayClient = await getApiGatewayClientForSession();
  return apiGatewayClient.post(`/admin/subscribe`, {}, {email, product}, null, ['admin']);
}

export async function adminCancelSubscribe(email, product) {
  const apiGatewayClient = await getApiGatewayClientForSession();
  return apiGatewayClient.delete(`/admin/subscribe`, {}, {email, product}, null, ['admin']);
}

export async function adminUpdateSettings(email, settings) {
  const apiGatewayClient = await getApiGatewayClientForSession();
  return apiGatewayClient.put(`/admin/settings`, {}, Object.assign({email}, settings), null, ['admin']);
}

export async function adminDisableMFA(email) {
  const apiGatewayClient = await getApiGatewayClientForSession();
  return apiGatewayClient.delete(`/admin/mfa`, {}, {email}, null, ['admin']);
}

export async function confirmMarketplaceSubscription(token) {
  const apiGatewayClient = await getApiGatewayClientForSession();
  return apiGatewayClient.put('/marketplace-subscriptions', {}, {"token" : token}, null, ['admin']);
}

export async function removeSubscription(usagePlanId) {
  const apiGatewayClient = await getApiGatewayClientForSession();
  return apiGatewayClient.delete(`/subscriptions/${usagePlanId}`, {}, {}, null, ['unsubscribe']);
}

export async function fetchUsage(usagePlanId, apiKeyId) {
    const date = new Date()
    const start = new Date(date.getFullYear(), date.getMonth(), 1).toJSON().split('T')[0]
    const end = new Date().toJSON().split('T')[0]
    const apiGatewayClient = await getApiGatewayClientForSession();
    const response = await apiGatewayClient.get(
      `/subscriptions/${usagePlanId}/usage/${apiKeyId}`,
      {}, {},
      {queryParams: { start, end }},
      ['view-keys']
    );
    return response;
}

export async function fetchBilling(year = '', month = '', day = '') {

  const apiGatewayClient = await getApiGatewayClientForSession();
  try {
    const query = {queryParams: { year: year || '', month: month || '', day: day || '', detail: year ? true : false }};
    const response = await apiGatewayClient.get('/billing', {}, {}, query)

    return response.data
  }
  catch(e) {
    if (e?.response?.status === 403) return {contentTypes: [], dates: [], summary: []}
    throw e
  }
}

export async function fetchYaraResults(year = '', month = '', day = '') {
  const apiGatewayClient = await getApiGatewayClientForSession();
  const response = await apiGatewayClient.get(
    '/yara/results',
    {}, {},
    {queryParams: { year: year || '', month: month || '', day: day || '' }}
  );
  return response.data;
}

export async function fetchYaraFilenames(ruleFile, ruleName, location) {
  const apiGatewayClient = await getApiGatewayClientForSession();
  const response = await apiGatewayClient.get(
    '/yara/filenames',
    {}, {},
    {queryParams: { 'RuleFile': ruleFile || '', 'RuleName': ruleName || '', 'Location': location || '' }}
  );
  return response.data;
}

export async function submitYaraUrl(url) {
  const apiGatewayClient = await getApiGatewayClientForSession();
  return apiGatewayClient.post('/yara/url', {}, {url}, null, ['yara']);
}

export function mapUsageByDate(usage, usedOrRemaining) {
    const apiKeyDates = {}
    Object.keys(usage.items).forEach(apiKeyId => {
        apiKeyDates[apiKeyId] = mapApiKeyUsageByDate(usage.items[apiKeyId], usage.startDate, usedOrRemaining)
    })

    const dates = {}
    Object.keys(apiKeyDates).forEach((apiKeyId) => {
        const apiKeyUsage = apiKeyDates[apiKeyId]
        apiKeyUsage.forEach(dailyUsage => {
            const date = dailyUsage[0]
            const value = dailyUsage[1]

            if (!dates[date])
                dates[date] = 0
            dates[date] += value
        })
    })

    const usageByDate = Object.keys(dates).sort().map(date => [date, dates[date]])

    return usageByDate
}

function mapApiKeyUsageByDate(apiKeyUsage, startDate, usedOrRemaining) {
    const dateParts = startDate.split('-')
    const year = dateParts[0]
    const month = dateParts[1]
    const day = dateParts[2]
    const apiKeyDate = new Date(year, month - 1, day)
    apiKeyDate.setHours(0, 0, 0, 0)
    const usedOrRemainingIndex = usedOrRemaining === 'used'
        ? 0
        : 1

    if (apiKeyUsage && !Array.isArray(apiKeyUsage[0]))
        apiKeyUsage = [apiKeyUsage]

    return apiKeyUsage.map((usage) => {
        const date = apiKeyDate.setDate(apiKeyDate.getDate())
        const item = [date, usage[usedOrRemainingIndex]]
        apiKeyDate.setDate(apiKeyDate.getDate() + 1)
        return item
    })
}

const CONTENT_TYPES = [
  { type: 'application/pdf', label: 'pdf' },
  { type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation', label: 'pptx' },
  { type: 'application/vnd.ms-powerpoint.presentation.macroEnabled.12', label: 'pptm',
    fallback: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' },
  { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', label: 'xlsx' },
  { type: 'application/vnd.ms-excel.sheet.macroEnabled.12', label: 'xlsm',
    fallback: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
  { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', label: 'docx' },
  { type: 'application/vnd.ms-word.document.macroEnabled.12', label: 'docm',
    fallback: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
  { type: 'image/gif', label: 'gif' },
  { type: 'image/bmp', label: 'bmp' },
  { type: 'image/jpeg', label: 'jpeg' },
  { type: 'image/jpg', label: 'jpeg' },
  { type: 'image/jp2', label: 'jpeg2k' },
  { type: 'image/png', label: 'png' },
  { type: 'image/x-ms-bmp',  label: 'bmp' },
  { type: 'image/tiff', label: 'tiff' },
  { type: 'application/msword', label: 'doc', convertTo: {
    mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    extension: 'docx'
  }},
  { type: 'application/vnd.ms-excel', label: 'xls', convertTo: {
    mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    extension: 'xlsx'
  }},
  { type: 'application/vnd.ms-powerpoint', label: 'ppt', convertTo: {
    mimeType: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    extension: 'pptx'
  }},
  { type: 'application/vnd.oasis.opendocument.text', label: 'odt', convertTo: {
    mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    extension: 'docx'
  }},
  { type: 'application/vnd.oasis.opendocument.presentation', label: 'odp', convertTo: {
    mimeType: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    extension: 'pptx'
  }},
  { type: 'application/vnd.oasis.opendocument.spreadsheet', label: 'ods', convertTo: {
    mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    extension: 'xlsx'
  }},
  { type: 'application/zip', label: 'zip' },
  { type: 'application/x-zip-compressed', label: 'zip', mimeType: 'application/zip' },
  { type: 'text/xml', label: 'xml' },
  { type: 'application/xml', label: 'xml' },
  { type: 'application/json', label: 'json' },
  { type: 'text/json', label: 'json' },
  { type: 'text/csv', label: 'csv',convertTo: {
    mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    extension: 'xlsx'} },
]

export function getSupportedContentTypes(includeConversionTypes = true) {
    return includeConversionTypes ? CONTENT_TYPES : CONTENT_TYPES.filter(x => !x.convertTo)
}

export function getEnabledProducts(catalog, usagePlans) {
    return catalog.map(x => x.usagePlans.filter(y => usagePlans.includes(y.id)).length && x).filter(x=>x)
}
export function isProductEnabled(catalog, usagePlans, apiName) {
    const enabledApiNames = getEnabledProducts(catalog, usagePlans).map(x => x.apiName)
    return enabledApiNames.includes(apiName)
}
export function getSubscribedUsagePlans(usagePlans, subscriptions) {
    if (!Array.isArray(subscriptions) || !subscriptions.length) return [];
    const subscriptionIds = subscriptions.map(sub => sub.id)
    return usagePlans.filter(up => subscriptionIds.includes(up.id))
}

export async function createPortalApiUser() {
  const apiGatewayClient = await getApiGatewayClientForSession();
  const response = await apiGatewayClient.post('/portal-api', {}, {}, {}, ['admin']);
  return response.data;
}

export async function deletePortalApiUser() {
  const apiGatewayClient = await getApiGatewayClientForSession();
  const response = await apiGatewayClient.delete('/portal-api', {}, {}, {}, ['admin']);
  return response.data;
}
