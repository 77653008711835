import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Container, Divider, Grid, Header, Icon, Label, List, Message, Segment } from 'semantic-ui-react'
import Layout from '../../components/Layout'
import ApiHeader from '../../components/ApiHeader'

import { PrismAsyncLight as SyntaxHighlighter } from "react-syntax-highlighter";
import { json } from 'react-syntax-highlighter/dist/cjs/languages/prism';
import { tomorrow as SyntaxStyle}  from 'react-syntax-highlighter/dist/cjs/styles/prism';
import { Link } from 'react-router-dom'

SyntaxHighlighter.registerLanguage('json', json);

export default class ApiS3Documentation extends PureComponent {
  static propTypes={
    location: PropTypes.object
  }
  state = {
    title: 'S3 API',
  }

  render() {
    const {location} = this.props
    const {title} = this.state
    return (
      <Layout header={`The ${title}`}>
        <Container>

          <ApiHeader currentLocation={location}/>

          <Header as='h2'>Requirements</Header>

          <p>The following is the minimum requirement to call the API:</p>
          <List bulleted>
              <List.Item>
                  An HTTP client with access to the API
              </List.Item>
              <List.Item>
                  A source S3 bucket for the API to read 'unsafe' files from
              </List.Item>
              <List.Item>
                  A target S3 bucket for the API to write 'safe' files to (this must be in the same AWS region as the API)
              </List.Item>
              <List.Item>
                  An SNS Topic for the API to publish status updates to
              </List.Item>
              <List.Item>
                  Three IAM roles configured to allow the API to assume them (secured with AWS Account Id and an ExternalId)
                  <List.List>
                      <List.Item>
                          A source role with <code>s3:GetObject</code> permission on the source S3 bucket
                      </List.Item>
                      <List.Item>
                          A target role with <code>s3:PutObject</code> and <code>s3:GetBucketLocation</code> permissions on the target S3 bucket
                      </List.Item>
                      <List.Item>
                          A status role with <code>sns:Publish</code> permission for the status SNS Topic
                      </List.Item>
                  </List.List>
              </List.Item>
          </List>

          <Header as='h2'>Flow diagrams</Header>

          <p>The normal flow</p>

          <Segment placeholder color='green' style={{marginLeft: '1em'}}>
            <Grid columns='equal' textAlign='center'>
              <Grid.Row>
                <Grid.Column>
                  <Icon name='code' size='huge' color='blue'/>
                  <Label pointing basic size='large'>
                    Your application
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon.Group size='huge'>
                    <Icon name='aws' color='blue'/>
                    <Icon name='file alternate' corner color='yellow'/>
                  </Icon.Group>
                  <Label pointing basic size='large'>
                    uploads a file to your S3 bucket
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon.Group size='huge'>
                    <Icon name='code' color='blue'/>
                    <Icon name='file code outline' corner color='grey'/>
                  </Icon.Group>
                  <Label pointing basic size='large'>
                    and your application sends some JSON
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon.Group size='huge'>
                    <Icon name='cog' color='black'/>
                    <Icon name='file code outline' corner color='grey'/>
                  </Icon.Group>
                  <Label pointing basic size='large'>
                    to the S3 API, which returns some JSON
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon name='code' size='huge' color='blue'/>
                  <Label pointing basic size='large'>
                    to your application
                  </Label>
                </Grid.Column>
              </Grid.Row>


              <Divider horizontal>Then in the background</Divider>


              <Grid.Row>
                <Grid.Column>
                  <Icon name='cog' size='huge' color='black'/>
                  <Label pointing basic size='large'>
                    The S3 API processing job
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon.Group size='huge'>
                    <Icon name='aws' color='blue'/>
                    <Icon name='file alternate' corner color='yellow'/>
                  </Icon.Group>
                  <Label pointing basic size='large'>
                    downloads the file from your source S3 bucket
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon name='cog' size='huge' loading color='black'/>
                  <Label pointing basic size='large'>
                    processes it
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon.Group size='huge'>
                    <Icon name='aws' color='blue'/>
                    <Icon name='file alternate' corner color='green'/>
                  </Icon.Group>
                  <Label pointing basic size='large'>
                    uploads a new file to your target S3 bucket
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon.Group size='huge'>
                    <Icon name='aws' color='blue'/>
                    <Icon name='file code outline' corner color='grey'/>
                  </Icon.Group>
                  <Label pointing basic size='large'>
                    and sends status JSON to your SNS topic
                  </Label>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>

          <p>And when there is an error</p>

          <Segment placeholder color='red' style={{marginLeft: '1em'}}>
            <Grid columns='equal' textAlign='center'>
              <Grid.Row>
                <Grid.Column>
                  <Icon name='code' size='huge' color='blue'/>
                  <Label pointing basic size='large'>
                    Your application
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon.Group size='huge'>
                    <Icon name='aws' color='blue'/>
                    <Icon name='file video outline' corner color='red'/>
                  </Icon.Group>
                  <Label pointing basic size='large'>
                    uploads an unsupported file type to your S3 bucket
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon.Group size='huge'>
                    <Icon name='code' color='blue'/>
                    <Icon name='file code outline' corner color='grey'/>
                  </Icon.Group>
                  <Label pointing basic size='large'>
                    and your application sends some JSON
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon.Group size='huge'>
                    <Icon name='cog' color='black'/>
                    <Icon name='file code outline' corner color='grey'/>
                  </Icon.Group>
                  <Label pointing basic size='large'>
                    to the S3 API, which returns some JSON
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon name='code' size='huge' color='blue'/>
                  <Label pointing basic size='large'>
                    to your application
                  </Label>
                </Grid.Column>
              </Grid.Row>


              <Divider horizontal>Then in the background</Divider>


              <Grid.Row>
                <Grid.Column>
                  <Icon name='cog' size='huge' color='black'/>
                  <Label pointing basic size='large'>
                    The S3 API processing job
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon.Group size='huge'>
                    <Icon name='aws' color='blue'/>
                    <Icon name='file video outline' corner color='red'/>
                  </Icon.Group>
                  <Label pointing basic size='large'>
                    downloads the file from your source S3 bucket
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon name='cog' size='huge' loading color='black'/>
                  <Label pointing basic size='large'>
                    tries to process it
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='red'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon.Group size='huge'>
                    <Icon name='aws' color='blue'/>
                    <Icon name='file code outline' corner color='grey'/>
                  </Icon.Group>
                  <Label pointing basic size='large'>
                    and sends the error status JSON to your SNS topic
                  </Label>
                </Grid.Column>
                <Grid.Column>

                </Grid.Column>
                <Grid.Column>

                </Grid.Column>
              </Grid.Row>


            </Grid>
          </Segment>

          <Header as='h2'>Authentication</Header>

          <p>Authentication is necessary for every API call, and is via an API Key in the request header named <code>x-api-key</code>.</p>

          <Header as='h2'>Security</Header>

          <p>For more information on the IAM role security approach we take, please see the AWS documentation around <a href='https://docs.aws.amazon.com/IAM/latest/UserGuide/id_roles_create_for-user_externalid.html' target='_blank' rel='noopener noreferrer'>ExternalId <Icon name='external' size='small'/></a>. The relevant AWS Account Ids and
              your personal ExternalId are available from the API Keys page.</p>

          <p>When the API assumes a role within your AWS account, the user id will depend on the resource it is interacting with,
            as follows:-</p>
          <List bulleted>
            <List.Item>Source S3 Bucket = &lt;RoleId&gt;:&lt;target-bucket-name&gt;</List.Item>
            <List.Item>Target S3 Bucket = &lt;RoleId&gt;:&lt;source-bucket-name&gt;</List.Item>
            <List.Item>SNS Topic = &lt;RoleId&gt;:ThreatRemovalApi</List.Item>
          </List>
          <p>The user id will appear in Cloud Trail logs and the aws:userid policy variable can be used to restrict access
            i.e. only allow a source bucket to be used with a specific target bucket (see Cloud Formation sample for an example)</p>

          <Header as='h2'>Recommendations</Header>

          <p>In addition to the minimum requirements we would recommend an S3 event handler that automatically calls the API when objects are placed in the source bucket, and an SNS subscriber to react to any errors received within status updates. The CloudFormation code sample provides a reference implementation which includes these features.</p>
          <p>You may also wish to trigger further events and actions once you receive new files in the target bucket. The API may change the name of the file, so do not rely on the value you specify for the target s3 key.</p>
          <p>The target bucket can be in a different AWS account than the source if required.</p>

          <Header as='h2'>Key names</Header>

          <p>Whilst in many cases the successfully processed result will be uploaded to the target s3 key name provided, this is not guaranteed.</p>

          <p>The API defends against many forms attacks. One of which is around polymorphism - these are files that can be used in multiple different ways. Files have been created that are both a valid ZIP and simultaneously a valid PDF for example. These kinds of files can cause problems where they are made safe for one usage scenario, but perhaps are still dangerous for another. A simple example of this is a simple text file - but if treated as JavaScript, bash or powershell it has a significant potential for harm.</p>

          <p>This is one reason that we standardise the extension part of s3 key names. Another reason for a potential change is after a format conversion.</p>

          <p>If you are either reacting to new files by using S3 Events, or the status notifications, then this will be transparent for you. Although if you used the full key name as an identifier in a database you might not be able to connect them directly. You can use either the userData for your own ids, or the initial response id property as a reliable identifier.</p>

          <Header as='h2'>Status</Header>

          <p>The status is your way of finding out about any errors during processing.</p>
          <p>Many content processing jobs will be completed quickly, but we also cater for considerably more complex content. Some files may have issues that prevent them from being processed.</p>
          <p>Status updates are sent via <a href='https://aws.amazon.com/sns/' target='_blank' rel='noopener noreferrer'>Amazon Simple Notification Service (SNS) <Icon name='external' size='small'/></a>. This enables an efficient event driven integration.</p>
          <Message icon color='blue'>
            <Icon name='info circle' />
            <Message.Content>
              We suggest you use status to react to any errors during processing, and tidy up the source files if necessary. Successful completion is also marked by the upload of your content to the target bucket, and the status notifications or S3 events are a good mechanism for any further actions you wish to take.
            </Message.Content>
          </Message>

          <Header as='h4'>
            <Icon name='code' />
            <Header.Content>In progress</Header.Content>
          </Header>
          <SyntaxHighlighter language='json' style={SyntaxStyle}>{`{
  "id": "data/14124215481719872.pdf",
  "status": {
    "state": "PROCESSING",
    "complete": false
  },
  "userData": "some-data"
}`}
          </SyntaxHighlighter>

          <Header as='h4'>
            <Icon name='code' />
            <Header.Content>Complete, with error</Header.Content>
          </Header>

          <SyntaxHighlighter language='json' style={SyntaxStyle}>{`{
  "id": "data/14124215481719872.pdf",
  "userData": "some-data",
  "status": {
    "state": "COMPLETE",
    "complete": true,
    "success": false
  },
  "error": {
    "code": 3020,
    "name": "PROCESSING_NOT_RECOGNISED",
    "subType": "Parsing/NotRecognised",
    "message": "This file could not be processed: the file content isn't recognised as 'application/pdf'",
    "type": "PROCESSING"
  }
}`}
          </SyntaxHighlighter>

          <Header as='h4'>
            <Icon name='code' />
            <Header.Content>Complete and successful</Header.Content>
          </Header>

          <SyntaxHighlighter language='json' style={SyntaxStyle}>{`{
  "id": "data/14124215481719872.pdf",
  "userData": "some-data",
  "links": {
    "source": {
      "bucket": "your-source-bucket-with-original-files",
      "key": "path/to/file.pdf"
    },
    "target": {
      "bucket": "your-target-bucket-for-storing-the-safe-files",
      "key": "path/to/file.that-might-have-been-renamed-by-the-api.pdf"
    }
  },
  "status": {
    "state": "COMPLETE",
    "complete": true,
    "success": true
  }
}`}
          </SyntaxHighlighter>

          <Header as='h4'>
            <Icon name='code' />
            <Header.Content>Complete and successful, with a risk taken</Header.Content>
          </Header>

          <SyntaxHighlighter language='json' style={SyntaxStyle}>{`{
  "id": "data/14124215481719872.xlsm",
  "links": {
    "source": {
      "bucket": "your-source-bucket-with-original-files",
      "key": "path/to/file.pdf"
    },
    "target": {
      "bucket": "your-target-bucket-for-storing-the-safe-files",
      "key": "path/to/file.that-might-have-been-renamed-by-the-api.pdf"
    }
  },
  "risks": {
    "taken": ["exe/macro/ms/excel"]
  },
  "status": {
    "state": "COMPLETE",
    "complete": true,
    "success": true
  }
}`}
          </SyntaxHighlighter>

          <Header as='h2'>Code samples</Header>

          <p>The code samples provide a simple reference implementation which uses the API endpoints, they supplement the descriptions here and the swagger request / response definitions. They can be found on the API Definition page.</p>

          <Header as='h2'>Charging</Header>

          <p>The API calls to <code>/s3</code> will be charged per MB of the submitted file in S3.</p>
          <p>Some errors can be caught before charges are incurred. These are limited to the format of the request body and headers. Please note that issues such as an invalid SNS topic configuration, misconfigured IAM roles or empty PDFs can not be detected in advance.</p>
          <p>You will be responsible for all AWS charges incurred within your own infrastructure. This includes S3 storage, S3 request charges, S3 data transfer charges, lambda innovocations, and SNS. If your S3 buckets are in the same region as the API endpoint then,
              at the time of writing, data transfer is free. Data transfer costs are by far the largest element of these AWS charges so we recommend avoiding incurring them.</p>
          <Message icon color='yellow'>
            <Icon name='warning circle' />
            <Message.Content>
              You will be responsibile for some AWS costs in your account. These will be in addition to the Zero Trust CDR as a Service charges.
            </Message.Content>
          </Message>

          <Header as='h2'>Format conversion</Header>

          <p>In addition to transforming your content, the API can also optionally convert your files from a different format as outlined on the <Link to='/documentation/format-conversion'>format conversion page</Link>.
            To use this feature you will need to populate the options object in the <code>/s3</code> request.</p>

          <Header as='h2'>Report</Header>
          <p>You can use the report option to specify the response report format, see the <Link to='/documentation/report'>reports page</Link> for a list of possible values</p>

          <Header as='h2'>Risks</Header>
          <Header as='h3'>Risk management</Header>

          <p>To change your risk profile you can choose to allow certain risks, as outlined on the <Link to='/documentation/risks'>risk management page</Link>.
            To use this feature you will need to populate the options object in the <code>/s3</code> request.</p>

          <Header as='h3'>Risks taken</Header>
          <p>The risks taken during the transformation are reported in the status object as an array under the property <code>risks.taken</code>.</p>

          <Header as='h3'>Risks taken (S3 specific)</Header>
          <p>
            The S3 API goes a step further than the other APIs in controlling risks.
            The list of risks taken are added to an S3 Tag on the target object.
            They are supplied in single string list format, sorted alphabetically, with each surrounded by the ':' character.
          </p>
          <p>
            S3 Tag values are limited to 256 characters and if the risk string is short enough it is placed in a tag named 'RisksTaken_1', but if it is longer it is split across several tags, using the names 'RisksTaken_1', 'RisksTaken_2', 'RisksTaken_3', 'RisksTaken_4' and 'RisksTaken_5' as necessary.
            Each tag used contains a subset of the individual risks.
            Individual risks are not split across tags.
          </p>
          <p>
            The tags can be used to control what risky content can be placed in a particular bucket when you configure a Bucket Policy to your target bucket.
            By defining an IAM Condition against the s3:PutObjectTagging action, you can be precise about which risks are taken, independently of the choices given in the requests.
            You can also choose to only allow risks in particular 'folders' within the bucket.
            This means that until you grant the target bucket's corresponding IAM Role the permission to use the s3:PutObjectTagging action (usually via the Bucket Policy), then risky content will not be able to make it through to your target bucket.
          </p>
          <p>
            The Bucket Policy can be used to further restrict the use of risks. By defining an IAM Condition against the s3:PutObjectTagging action, you can carefully restrict which risks are taken. You can also choose to only allow risks in particular 'folders' within the bucket.
          </p>

          <p>
            For example, to restrict the bucket to storing only macro enabled Word documents, set up a condition that tests for this one risk.
          </p>
          <SyntaxHighlighter language='yaml' style={SyntaxStyle}>
            {
            'Effect: Allow\n' +
            'Action:\n' +
            '- s3:PutObjectTagging\n' +
            'Resource: !Sub \'arn:aws:s3:::your-bucket-name/word-with-macros/*\'\n' +
            'Principal:\n' +
            '  AWS: !GetAtt TargetBucketExternalRole.Arn\n' +
            'Condition:\n' +
            '  StringEquals:\n' +
            '    s3:RequestObjectTag/RisksTaken_1: \':exe/macro/ms/word:\'\n'
            }
          </SyntaxHighlighter>

          <p>
            To allow any kind of macro enabled document in the bucket, set a condition that permits ":exe/macro/*:" in the risk tags. As the "*" will also match all other risks, the "StringNotLike" condition limits this to one risk.
          </p>
          <SyntaxHighlighter language='yaml' style={SyntaxStyle}>
            {
            'Effect: Allow\n' +
            'Action:\n' +
            '- s3:PutObjectTagging\n' +
            'Resource: !Sub \'arn:aws:s3:::your-bucket-name/risky/*\'\n' +
            'Principal:\n' +
            '  AWS: !GetAtt TargetBucketExternalRole.Arn\n' +
            'Condition:\n' +
            '  StringLike:\n' +
            '    s3:RequestObjectTag/RisksTaken_1: \':exe/macro*\'\n' +
            '  StringNotLike:\n' +
            '    s3:RequestObjectTag/RisksTaken_1: \':*:*:\'\n'
            }
          </SyntaxHighlighter>

          <p>
            The example above allows any file that contains macros to be stored in the bucket, and also any file that has a macro enabled document embedded in it.
            Since a file can have many embedded files in it, it may carry every kind of macro enabled Office document and so there could be multiple different risks reported.
            The example above allows only one kind of macro enabled document, but by taking advantage of the alphabetic ordered list multiple kinds are allowed in the example below.
          </p>
          <SyntaxHighlighter language='yaml' style={SyntaxStyle}>
            {
            'Effect: Allow\n' +
            'Action:\n' +
            '- s3:PutObjectTagging\n' +
            'Resource: !Sub \'arn:aws:s3:::your-bucket-name/risky/*\'\n' +
            'Principal:\n' +
            '  AWS: !GetAtt TargetBucketExternalRole.Arn\n' +
            'Condition:\n' +
            '  StringLike:\n' +
            '    s3:RequestObjectTag/RisksTaken_1:\n' +
            '    - \':exe/macro/ms/excel:\'\n' +
            '    - \':exe/macro/ms/powerpoint:\'\n' +
            '    - \':exe/macro/ms/word:\'\n' +
            '    - \':exe/macro/ms/*:exe/macro/ms/powerpoint:\'\n' +
            '    - \':exe/macro/ms/*:exe/macro/ms/word:\'\n'
            }
          </SyntaxHighlighter>

          <br/>
          <p>
            For more complex scenarios you may find it easier to use a temporary target location, and have a lambda function confirm the tag values before copying elsewhere.
          </p>

          <Header as='h2'>Limits</Header>

          <p>We have some limits in place to help maintain a good level of service for everyone. If you have any concerns about these limits, please get in touch.</p>

          <List bulleted>
              <List.Item>
              File size: The maximum supported file size is around 1.25&nbsp;GB
            </List.Item>
            <List.Item>
              Complexity: Files will be processed for up to 5 minutes, very rarely this may not be enough to complete processing
            </List.Item>
            <List.Item>
              Traffic: All accounts are subject to a generous throttling profile
            </List.Item>
            <List.Item>
              Region: The target S3 bucket must be in the same region as the API
            </List.Item>
          </List>

          <Header as='h2'>Errors</Header>

          <p>Error codes can be returned as a direct response from the API (i.e. a 400 error) or within the status message in the
             SNS notification, in general the error message is quite descriptive of what has gone wrong but if you need a more
             detailed explanation or just a list of possible error codes, they are available in the <Link to={'/api/errors'}>Error Glossary</Link>.</p>

        </Container>
      </Layout>)
  }
}
