import { all, call } from 'redux-saga/effects'

import apiKeySagas from './apiKeySagas'
import awsMarketplaceSagas from './awsMarketplaceSagas'
import catalogSagas from './catalogSagas'
import errorSagas from './errorSagas'
import freshdeskSagas from './freshdeskSagas'
import productSagas from './productSagas'
import regionsSagas from './regionsSagas'
import secureContentSagas from './secureContentSagas'
import subscriptionSagas from './subscriptionSagas'
import userConfigSagas from './userConfigSagas'
import usagePlanSagas from './usagePlanSagas'
import userSagas from './userSagas'
import mfaSagas from './mfaSagas'
import portalApiSagas from './portalApiSagas'
import freeTrialSagas from './freeTrialSagas'
import delegateSagas from './delegateSagas'

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    call(apiKeySagas),
    call(awsMarketplaceSagas),
    call(catalogSagas),
    call(errorSagas),
    call(freshdeskSagas),
    call(productSagas),
    call(regionsSagas),
    call(secureContentSagas),
    call(subscriptionSagas),
    call(userConfigSagas),
    call(usagePlanSagas),
    call(userSagas),
    call(mfaSagas),
    call(portalApiSagas),
    call(freeTrialSagas),
    call(delegateSagas)
  ])
}