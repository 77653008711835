import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Container, Message, Icon, Header } from 'semantic-ui-react'
import ApiCatalog from '../../components/ApiCatalog'
import Layout from '../../components/Layout'
import { Link } from 'react-router-dom'

export default class ApisPage extends PureComponent {
  static propTypes={
    catalog: PropTypes.array,
    usagePlans: PropTypes.array,
    isAuthenticated: PropTypes.bool,
  }

  showNotSubscribedToAllApisMessage(catalog, usagePlans) {
    const {isAuthenticated} = this.props
    return isAuthenticated && !(usagePlans.length && !catalog.map(x => !x.usagePlans.filter(y => usagePlans.includes(y.id)).length).filter(x=>x).length)
  }

  render() {
    const { catalog = [], usagePlans = [] } = this.props
    const ready = catalog && catalog.length
    return (
      <Layout header='The Zero Trust CDR APIs' loading={!ready}>
        {ready
          ? <Container>

            <Header as='h2'>What do Zero Trust CDR APIs do?</Header>
            <p>The Zero Trust CDR APIs let you upload files and download fresh files in return. The new file will have all the useful content, but none of the dangerous elements that can be included within that file format. This is the key difference between existing
                anti-virus detection based approaches, and our next generation Zero Trust CDR technology. Malicious content is simply left behind.</p>

            <Header as='h2'>The APIs</Header>

            <p>For more details and documentation please select one below, or visit our <Link to='/apis/choosing' text='help me choose'>Which API?</Link> page.</p>

            <ApiCatalog/>

            { usagePlans.length
              ? null
              : <Message negative>
                  <Message.Header><Icon name='warning sign'/>You are not subscribed to any of our APIs</Message.Header>
                  <p>Please login or <a href='https://www.forcepoint.com/product/zero-trust-cdr' target='_blank' rel='noopener noreferrer'>contact us</a> for access. If you have just subscribed it can take a few minutes for our records to be updated.</p>
                </Message>
            }

            { usagePlans.length && this.showNotSubscribedToAllApisMessage(catalog, usagePlans)
              ? <Message warning>
                  <Message.Header><Icon name='lock'/>You are not subscribed to some of our APIs</Message.Header>
                  <p>If you need more access please <a href='https://www.forcepoint.com/product/zero-trust-cdr' target='_blank' rel='noopener noreferrer'>contact us</a>. If you have just subscribed it can take a few minutes for our records to be updated.</p>
                </Message>
              : null
            }

          </Container>
          : null
        }
      </Layout>)
  }
}