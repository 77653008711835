import { connect } from 'react-redux'

import { withData, withStateMappedToProps } from '../../redux/withData'

import TheComponent from './App.js';

const requiredData = []

const mapStateToProps = state => {
  return withStateMappedToProps(state, requiredData)
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(withData(requiredData)(TheComponent))