import { LOAD_FRESHDESK_TOKEN_SUCCEEDED, LOADING_FRESHDESK_TOKEN, LOAD_FRESHDESK_TOKEN_FAILED } from '../actions/freshdeskActions'
import { UNLOAD_USER_SUCCEEDED, SWITCH_ACCOUNT_SUCCEEDED } from '../actions/userActions'

function freshdeskReducer(existing = {}, action) {
  switch(action.type) {
    case LOAD_FRESHDESK_TOKEN_SUCCEEDED:
      return {
        token: action.payload
      }
    case LOADING_FRESHDESK_TOKEN:
      return {
        ...existing,
        loading: true,
      }
    case LOAD_FRESHDESK_TOKEN_FAILED:
      return {
        ...existing,
        token: null,
        loading: false,
      }

    case SWITCH_ACCOUNT_SUCCEEDED:
    case UNLOAD_USER_SUCCEEDED:
      return {}

    default:
      return existing
  }
}

export default freshdeskReducer