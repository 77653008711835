import React, { PureComponent } from 'react'

import { Button } from 'semantic-ui-react'
import { loginHosted } from '../../services/self'

export default class SignIn extends PureComponent {

  handleLoginHosted = (event) => this._handleLoginHosted(event)

  _handleLoginHosted(event) {
    event.preventDefault()

    if (!loginHosted({marketplace: true}))
        window.location.reload()
  }

  render() {
    return <Button id='sign-in-register' primary fluid onClick={this.handleLoginHosted}>Sign in or Register</Button>
  }
}
