import { LOAD_API_KEYS, LOADING_API_KEYS, LOAD_API_KEYS_SUCCEEDED, LOAD_API_KEYS_FAILED, ADD_API_KEY, REMOVE_API_KEY, UPDATE_API_KEY_FAILED, loadApiKeys } from '../actions/apiKeyActions'
import { all, call, fork, put, take } from 'redux-saga/effects'

import { loadSubscriptions } from '../actions/subscriptionActions'

import { showApiKey, createApiKey, deleteApiKey} from '../../services/api-key'

export function* loadAsync(action) {
  yield put({type: LOADING_API_KEYS})
  try {
    const apiKeys = yield call(showApiKey, action.payload.usagePlanId, action.payload.demo)
    const payload = { apiKeys, usagePlanId: action.payload.usagePlanId}
    yield put({type: LOAD_API_KEYS_SUCCEEDED, payload })
  }
  catch (error) {
    yield put({type: LOAD_API_KEYS_FAILED, error})
  }
}

export function* addAsync(action) {
  yield put({type: LOADING_API_KEYS})
  try {
    yield call(createApiKey, action.payload.usagePlanId)
    yield put(loadApiKeys(action.payload.usagePlanId))
  }
  catch (error) {
    yield put({type: UPDATE_API_KEY_FAILED, error})
  }
}

export function* removeAsync(action) {
  yield put({type: LOADING_API_KEYS})
  try {
    yield call(deleteApiKey, action.payload.usagePlanId, action.payload.apiKeyId)
    yield put(loadApiKeys(action.payload.usagePlanId))
    yield put(loadSubscriptions())
  }
  catch (error) {
    yield put({type: UPDATE_API_KEY_FAILED, error})
  }
}



export function* watchLoadAsync() {
  while (true) {
    const action = yield take(LOAD_API_KEYS)
    yield fork(loadAsync, action)
    yield take([LOAD_API_KEYS_SUCCEEDED, LOAD_API_KEYS_FAILED])
  }
}
export function* watchAdd() {
  while (true) {
    const action = yield take(ADD_API_KEY)
    yield fork(addAsync, action)
  }
}
export function* watchRemove() {
  while (true) {
    const action = yield take(REMOVE_API_KEY)
    yield fork(removeAsync, action)
  }
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    call(watchLoadAsync),
    call(watchAdd),
    call(watchRemove),
  ])
}