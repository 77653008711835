/* eslint-disable camelcase */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import { Container, Form, Message, Header, Label, Button, Modal, Divider, Popup, Icon, Checkbox } from 'semantic-ui-react'
import { hasPermission } from '../../services/self'
import { submitCreateUser, adminGetUser, adminEnableAutoProductSubscribe, adminDisableAutoProductSubscribe, adminSubscribe,
          adminCancelSubscribe, adminSetBillingRef, adminUpdateSettings, adminDisableMFA } from '../../services/api-catalog'
import Layout from '../../components/Layout'
import { Redirect } from 'react-router-dom'
//import AdminHeader from '../../components/AdminHeader'
import ConfigToggle from '../../components/ConfigToggle'
import CopyToClipboard from '../../components/CopyToClipboard'

const validEmail = /\S+@\S+\.\S+/;

const daysDiff = (target) =>  ((new Date().getTime() - target.getTime()) / (1000 * 60 * 60 * 24)).toFixed(0);

export default class UserManagement extends PureComponent {
  static propTypes={
    location: PropTypes.object,
    products: PropTypes.array,
    isAuthenticated: PropTypes.bool,
    adminSwitchAccount: PropTypes.func,
    adminHasAssumedAnotherIdentity : PropTypes.bool
  }
  state = {}
  defaultState = {

    showLoadModal: false,
    showRegisterModal: false,
    showRegenPasswordModal: false,
    showBillingRefModal: false,
    showBillingRefError: false,

    form_email: '',
    domain: '',
    form_BillingRef: '',
    form_NewBillingRef: '',
    userId: '',

    loading: false,
    error: null,
    message: null,
    password: null,
    mailtoLink: null,

    userNew: null,
    userActive: null,
    userRegistered: null,
    userLoaded: null,
    userUnknown: null,
    loadedUser: null,

    subscriptionConfirm: false,
    subscriptionMessage: null,
    unsubscribe: null,
    productName: null,

    form_enterprise: false,
    disableEnterprise: false,
    enableEnterprise: false,

    form_mfa: false,
    disableMfa: false
  };

  componentDidMount() {
    this.setState(this.defaultState)
    this.prepareProducts()
  }

  componentDidUpdate(prevProps) {
    const { products } = this.props
    if (!_.isEqual(prevProps.products, products))
      this.prepareProducts()
  }

  prepareProducts() {
    const { products = [] } = this.props

    const productList = products.map(p => {
      const { productCode, title } = p;
      return {
        description: title.replace('The', 'the'),
        productCode: this.normaliseProductCode(productCode)
      }
    });
    this.setState({ products: productList })
    this.defaultState.products = productList;

  }

  normaliseProductCode(productCode) {
    return productCode.replace('-', '_')
  }

  handleChange = (e, data) => {
    switch (data.type) {
      case 'checkbox': this.setState({['form_' + data.name]: data.checked}); break
      default: this.setState({ ['form_' + data.name]: data.value })
    }
  }

  handleLoadUser = async () => {
    const { form_email, loadedUser, password, mailtoLink, userNew, products } = this.state;
    const newState = Object.assign({}, this.defaultState);
    newState.form_email = form_email;
    newState.userLoaded = true;
    if (loadedUser && loadedUser === form_email) {
      // If this is the same user, preserve the temp password as we can't reload this
      newState.password = password;
      newState.mailtoLink = mailtoLink;
      newState.userNew = userNew;
    }
    newState.loadedUser = form_email;

    if (validEmail.test(form_email)) {
      try {
        const res = await adminGetUser(form_email);
        newState.domain = form_email.split('@').pop().split('.').slice(0,-1).join('.');

        switch (res.data.state) {
          case 'Active': newState.userActive = true; break;
          case 'Enabled':
          case 'Registered': newState.userRegistered = true; break;
          default: newState.userUnknown = true; break;
        }

        newState.form_BillingRef = res.data.BillingRef || '';
        newState.form_NewBillingRef = res.data.BillingRef || '';
        newState.userId = res.data.id || '';

        const userProducts = (newState.userActive ? res.data.products : res.data.AutoProductSubscribe).map(p => {
          return {
            productCode: this.normaliseProductCode(p.productCode || p),
            createdDate: p.createdDate ? new Date(p.createdDate) : null
          }
        });

        newState.products = products.map(p => {
          const newProduct = userProducts.find(np => (np.productCode || np) === p.productCode) || { inactive: true };
          p.active = !newProduct.inactive;
          p.createdDate = newProduct.createdDate;
          p.duration = newProduct.createdDate ? `${daysDiff(newProduct.createdDate)} days` : null;
          p.loading = false;

          return p;
        });

        if (res.data.userData) {
          newState.form_enterprise = res.data.userData.enterprise;
          newState.form_mfa = res.data.mfaEnabled;
        }

      } catch (error) {
        const message = (error.response ? error.response.data.message : null) || error.message;
        newState.error = true;
        newState.message = message;
        newState.userLoaded = false;
      }
    }

    this.setState(newState);
  }

  createLink = (email, password) => {
    const newline = '%0D%0A';
    const dblLine = `${newline}${newline}`
    const subject = "Login to Zero Trust CDR as a service from Deep Secure";
    const url = window.location.origin + window.location.pathname
    let body = `${newline}Your new account is ready, simply go to :-${newline}${url} ${dblLine}`;
    body += `and sign in with this temporary password:- ${dblLine}'${password}'${dblLine}`;
    body += `On first login, you will be asked to change your password`;

    body += `.${dblLine}`;
    return `mailto:${email}?subject=${subject}&body=${body}`;
  }

  handleCreateUser = async () => {
    const { form_email } = this.state
    this.setState({
      error: null,
      message: null,
      password: null,
      mailtoLink: null,

      userNew: false,
      userActive: false,
      userRegistered: false,
      userUnknown: false,

      showRegisterModal: false,
      showRegenPasswordModal: false
    })


    try {
      const res = await submitCreateUser(form_email);

      this.setState({
        userNew: true,
        password: res.data.password,
        mailtoLink: this.createLink(form_email, res.data.password),
        userRegistered: true
      });

    } catch(error) {
      const newState = { message: '', error: true };
      if (error.response) newState.message = error.response.data || '';
      this.setState(newState);
    }
  }

  handleSubscription = (e, name) => {
    const { userActive, products } = this.state;
    const product = products.find(p => p.productCode === name);
    product.loading = true;

    const value = !product.active;
    if (userActive) {
      this.setState({
        productName: name,
        unsubscribe: !value,
        subscriptionMessage: value ? `Subscribe this user to ${product.description}?` :
          `Cancel the users subscription to ${product.description}? Note this will STOP the user's activity immediately, ` +
          `and resubscribing will not restore the service, the user will be required to obtain new API keys.`
      });
    } else this.setState({
      productName: name,
      unsubscribe: !value,
      subscriptionMessage: value ? `Ensure the user is subscribed to ${product.description} when they first log in?` :
        `Cancel the users auto subscription to ${product.description} service?`
    });

    this.setState({ subscriptionConfirm: true });
  }

  processSubscription = async () => {
    const { productName, userActive, unsubscribe, form_email, products } = this.state;
    const product = products.find(p => p.productCode === productName);
    product.loading = true;
    this.setState({ subscriptionConfirm: false });

    try {
      if (userActive) {

        if (unsubscribe) {
          await adminCancelSubscribe(form_email, productName);
        } else {
          await adminSubscribe(form_email, productName);
        }

      } else {

        if (unsubscribe) {
          await adminDisableAutoProductSubscribe(form_email, productName);
        } else {
          await adminEnableAutoProductSubscribe(form_email, productName);
        }

      }

      // Reload the user to confirm we have subscribed to the correct products
      this.handleLoadUser();
    } catch (error) {
      product.loading = false;
      const newState = { message: '', error: true, loading: false, products: products };
      if (error.response && error.response.data) newState.message = error.response.data.message || error.response.data;
      this.setState(newState);
    }
  }

  handleChangeBillingRef = async () => {
    const { form_BillingRef, form_NewBillingRef, form_email } = this.state;
    try {

      if (form_BillingRef === form_NewBillingRef) {
        this.setState({ showBillingRefModal: false, showBillingRefError: false });
        return;
      }

      const response = await adminSetBillingRef(form_email, form_NewBillingRef);
      const billingRef = response.data.BillingRef

      this.setState({
        form_BillingRef: billingRef,
        form_NewBillingRef: billingRef,
        showBillingRefModal: false,
        showBillingRefError: false
      });
    } catch(error) {
      const newState = { message: '', error: true, showBillingRefModal: false };
      if (error.response && error.response.data) newState.message = error.response.data.message || '';
      this.setState(newState);
    }
  }

  validateUser = () => {
    const { form_BillingRef, form_email } = this.state;
    if (!form_email) return false;
    if (!form_BillingRef) {
      this.setState({ showBillingRefError: true });
      return false;
    } else this.setState({ showBillingRefError: false });

    return true;
  }

  updateEnterprise = async (enable) => {
    const { form_email } = this.state;
    await adminUpdateSettings(form_email, { enterprise: enable });
    this.setState({ form_enterprise: enable, disableEnterprise: false, enableEnterprise: false });
  }
  cancelEnterpriseUpdate = async (enable) =>
    this.setState({ form_enterprise: enable, disableEnterprise: false, enableEnterprise: false });


  handleDisableMFA = async () => {
    const { form_email } = this.state;
    await adminDisableMFA(form_email);
    this.setState({ form_mfa: false, disableMFA: false });
  }

  handleSignInAs = async () => {
    const { form_email } = this.state;
    const { adminSwitchAccount } = this.props;
    if (!form_email) return;

    adminSwitchAccount(form_email);
  }

  render() {

    const { isAuthenticated, adminHasAssumedAnotherIdentity  } = this.props

    const {
      error, userNew, userActive, userRegistered, userUnknown, password, mailtoLink, message, userLoaded, form_email = '', showLoadModal, showBillingRefError,
      showBillingRefModal, domain, subscriptionConfirm, subscriptionMessage, unsubscribe, form_BillingRef = '', form_enterprise,
      form_mfa = '', disableMFA, disableEnterprise, enableEnterprise, showRegenPasswordModal, showRegisterModal, products = [],
      userId = ''
    } = this.state

    if (adminHasAssumedAnotherIdentity ) return <Redirect push to={{ pathname: '/' }}/>

    const ready = isAuthenticated && hasPermission(['create-user', 'subscriptions', 'free-trial'])

    // Create the help text based on the users state and what permissions the admin has
    let helpText = null;
    if (!userActive) {
      let also = false;
      if (userUnknown) {
        helpText = 'This user is not an active user';
        if (hasPermission('create-user')) {
          helpText += ', you can register the user to generate a temporary password for them to log in with';
          also = true;
        }
      }

      if (userRegistered) helpText = 'This user is registered but has not yet signed in';

      if (helpText && hasPermission(['free-trial', 'subscriptions'])) {
        helpText += also ? '. You can also ' : '. You can ';
        helpText += 'sign up the user to be automatically subscribed to products when they sign in for the first time.';
      }
    }

    return (
      <Layout header='Administration' loading={!ready}>
        <Container>

            <Form error={error} success={userNew}>
               { helpText !== null
                  ? <Message info content={helpText} />
                  : null
               }

              <Form.Field hidden={!userActive}>
                <Label ribbon color='blue' content='Active User' size='large' />
                <Label as='a' content='User Id' detail={userId} /><CopyToClipboard value={userId}/>
                { hasPermission('access-user') ? <Button
                    content='Sign in as this user' floated='right' compact icon='sign-in'
                    onClick={this.handleSignInAs}
                  />
                  : null
                }
              </Form.Field>

              <Form.Field hidden={!userRegistered} >
                <Label ribbon content='Registered User' color='green' size='large'/>
                { hasPermission('create-user') ? <Button
                    content='Regenerate Temporary Password' floated='right' compact icon='redo'
                    onClick={() => this.validateUser() ? this.setState({ showRegenPasswordModal: true}) : null}
                  />
                  : null
                }


              </Form.Field>

              <Form.Field hidden={!userUnknown}>
                <Label ribbon content='Unregistered User' size='large' />
                { hasPermission('create-user') ? <Button
                    content='Register user' floated='right' positive compact icon='user plus'
                    onClick={() => this.validateUser() ? this.setState({ showRegisterModal: true}) : null }
                  />
                  : null
                }
              </Form.Field>

              <Message success>
                <Header>New user has been successfully created</Header>
                <p>Temporary password is {password} <a href={mailtoLink}>click here to create an email you can edit and send to the customer.</a></p>
              </Message>

              <Message error >
                <Header>There was an error with the request</Header>
                <p>{message}</p>
              </Message>

              <Header as='h5' hidden={userLoaded}>Enter a valid email address of either an existing user or a user you wish to automatically subscribe to products on their first sign-in.</Header>
              <Form.Field>
                <Form.Input placeholder="Please enter the customer's email"
                  readOnly value={form_email}
                  icon={{ name: 'mail outline', circular: true, link: true, onClick: () => this.setState({ showLoadModal: true }) }}
                  onClick={() => this.setState({ showLoadModal: form_email ? false : true })}
                />
              </Form.Field>

              <div hidden={!userLoaded}>

                <Form.Field error={showBillingRefError}>

                  <Form.Input
                    disabled={!(hasPermission('create-user') && (!form_BillingRef || !userActive)) ? true : false}
                    label='Billing Reference' placeholder={`Please enter a code for identifying this customer in the billing reports e.g. '${domain}'`} readOnly value={form_BillingRef}
                    icon={{ name: 'tag', circular: true, link: true, onClick: () => this.setState({ showBillingRefModal: true }) }}
                    onClick={() => this.setState({ showBillingRefModal: true })} required
                  />
                  { showBillingRefError
                    ? <Label basic color='red' content='A billing reference is required before you can update a user' pointing='above' />
                    : null
                  }
                </Form.Field>

                <Form.Group>
                  <Form.Field control={Checkbox} label='Allow access to enterprise features'
                    onChange={()=> form_enterprise ? this.setState({ disableEnterprise: true }) : this.setState({ enableEnterprise: true })}
                    checked={form_enterprise} disabled={!userActive} width={10}
                  />
                  { form_mfa
                  ? <Form.Button width={2} onClick={()=>this.setState({ disableMFA: true })}>Disable MFA</Form.Button>
                  : null
                  }
                </Form.Group>


                <Divider horizontal>Information</Divider>

                <p>
                  The current default allowances for each tier are:
                </p>

                <ul>
                  <li>nano: 5&nbsp;GB</li>
                  <li>micro: 10&nbsp;GB</li>
                  <li>standard: 20&nbsp;GB</li>
                  <li>developer: 100&nbsp;GB</li>
                  <li>professional: 500&nbsp;GB</li>
                  <li>enterprise: 1&nbsp;TB</li>
                </ul>

                <p>The allowances currently only show up in the monthly and weekly usage summary emails.</p>

                <p>The small, medium are large tiers are for AWS only.</p>

                <p>Enterprise features are for added-value features like MFA, multiple portal user management and the Data API.</p>

                <Divider horizontal>Subscriptions</Divider>

                <ul>
                {products.map((product) =>

                    <ConfigToggle
                      key={product.productCode} name={product.productCode}
                      label={`${userActive?'':'*'}Subscribed to ${product.description}`}
                      value={product.active} onToggle={this.handleSubscription} loading={product.loading}
                      disabled={
                        form_BillingRef === '' ||
                        !hasPermission(product.productCode.startsWith('freetier') ? ['free-trial', 'subscriptions'] : ['subscriptions'])
                      }
                      notes={
                        product.duration
                        ? <Popup
                            content={product.createdDate ? `API Key activated on ${product.createdDate.toDateString()}` : null}
                            disabled={product.createdDate ? null : true}
                            trigger={<Label pointing='left'><Icon name='clock outline'/>{product.duration}</Label>}
                          />
                        : null
                      }
                    />

                )}
                </ul>

                <Header as='h6' hidden={userActive}>* Product subscriptions for an inactive user will not be applied until the user signs in for the first time.</Header>

              </div>

            </Form>

            {/* modals */ }

            <Modal open={showBillingRefModal} closeIcon onClose={()=> this.setState({ showBillingRefModal: false })} >
              <Modal.Content>
                <Form onSubmit={this.handleChangeBillingRef}>

                <Form.Input label='Billing Reference' name='NewBillingRef' placeholder={`Please enter a code for identifying this customer in the billing reports e.g. '${domain}'`}  onChange={this.handleChange} required />
                <Form.Button content='Update' />

                </Form>
              </Modal.Content>
            </Modal>

            <Modal open={showLoadModal} closeIcon onClose={()=> this.setState({ showLoadModal: false })} >
              <Modal.Content>
                <Form onSubmit={this.handleLoadUser}>

                 <Form.Input label='Customer Email' name='email' placeholder="Please enter the customer's email" onChange={this.handleChange} required />
                  <Form.Button content='Load' />

                </Form>
              </Modal.Content>
            </Modal>

            <Modal open={disableEnterprise} closeIcon onClose={() => this.setState({ disableEnterprise: false })}>
              <Modal.Content>
                <Form onSubmit={() => this.updateEnterprise(false)}>
                  <p>This will prevent access to enterprise features within the dev portal for this user.
                    Are you sure you want to disable these features?</p>
                  <Form.Button content='Block access to enterprise features' negative icon='block'/>
                </Form>
              </Modal.Content>
            </Modal>
            <Modal open={subscriptionConfirm} closeIcon onClose={()=> this.setState({ subscriptionConfirm: false })} >
              <Modal.Content>
                <Form onSubmit={this.processSubscription}>
                  <p>{subscriptionMessage}</p>
                  <Form.Button size='mini' compact content='Are you completely sure you want to do this?' positive={!unsubscribe} negative={unsubscribe} />
                </Form>
              </Modal.Content>
            </Modal>

            <Modal open={enableEnterprise} closeIcon onClose={() => this.setState({ enableEnterprise: false })}>
              <Modal.Content>
                <Form onSubmit={() => this.updateEnterprise(true)}>
                  <p>This will give this user access to enterprise features on the dev portal.
                    Are you sure you want to enable these features?</p>
                  <Form.Button content='Allow access to enterprise features' positive icon='redo'/>
                </Form>
              </Modal.Content>
            </Modal>

            { form_mfa
            ? <Modal open={disableMFA} closeIcon onClose={() => this.setState({ disableMFA: false })}>
                <Modal.Content>
                  <Form onSubmit={this.handleDisableMFA}>
                    <p>This will disable two factor authentication for this account. Please confirm the identity of the user requesting this before continuing.
                        If their email has been compromised 2FA is the only thing preventing an attacker from gaining access to their account.</p>
                    <Form.Button content='Disable two factor authentication' negative icon='block'/>
                  </Form>
                </Modal.Content>
              </Modal>
            : null
            }

            { hasPermission('create-user')

              ? <div>
                <Modal open={showRegenPasswordModal} closeIcon onClose={() => this.setState({ showRegenPasswordModal: false })}>
                  <Modal.Content>
                    <Form onSubmit={this.handleCreateUser}>
                      <p>This will regenerate the temporary password for the user and render any previous passwords inactive.</p>
                      <Form.Button content='Regenerate Temporary Password' positive icon='redo'/>
                    </Form>
                  </Modal.Content>
                </Modal>

                <Modal open={showRegisterModal} closeIcon onClose={() => this.setState({ showRegisterModal: false })}>
                  <Modal.Content>
                    <Form onSubmit={this.handleCreateUser}>
                      <p>This will register the user and create a temporary password the user can log in with</p>
                      <Form.Button size='mini' compact content='Register User' positive icon='user plus' />
                    </Form>
                  </Modal.Content>
                </Modal>

                </div>
              : null
            }

        </Container>
      </Layout>)
  }
}
