import { connect } from 'react-redux'

import { withData, withStateMappedToProps, CATALOG, IS_AUTHENTICATED, SUBSCRIPTIONS, API_KEYS, ROLES } from '../../redux/withData'

import { loadApiKeys } from '../../redux/actions/apiKeyActions'

import TheComponent from './TryIt.js';

export { configureAxios, addRequestToState, addResponseToState, sizeInMB } from './TryIt.js';

const requiredData = [CATALOG, IS_AUTHENTICATED, SUBSCRIPTIONS, API_KEYS, ROLES]

const mapStateToProps = (state, ownProps) => {
  const { usagePlanId } = ownProps
  const params = {usagePlanId: usagePlanId}
  return withStateMappedToProps(state, requiredData, params)
}

const mapDispatchToProps = {
  loadApiKeys: (usagePlanId) => loadApiKeys(usagePlanId, true)
}

export default connect(mapStateToProps, mapDispatchToProps)(withData(requiredData)(TheComponent))