import { LOAD_REGIONS_SUCCEEDED, LOADING_REGIONS, LOAD_REGIONS_FAILED } from '../actions/regionsActions'

function regionsReducer(existing = {}, action) {
  switch(action.type) {
    case LOAD_REGIONS_SUCCEEDED:
      return {
        items: action.payload
      }
    case LOADING_REGIONS:
      return {
        ...existing,
        loading: true,
      }
    case LOAD_REGIONS_FAILED:
      return {
        ...existing,
        loading: false,
      }

    default:
      return existing
  }
}

export default regionsReducer