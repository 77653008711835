import { connect } from 'react-redux'

import { withData, withStateMappedToProps, CATALOG, IS_AUTHENTICATED, SUBSCRIPTIONS, USER_CONFIG_YARA_ENABLED, USAGE_PLANS, USER_CONFIG_FREE_TRIAL_STATUS } from '../../redux/withData'

import TheComponent from './Home.js';

const requiredData = [CATALOG, IS_AUTHENTICATED, SUBSCRIPTIONS, USER_CONFIG_YARA_ENABLED, USAGE_PLANS, USER_CONFIG_FREE_TRIAL_STATUS]

const params = {catalog: { onlyIfAuthenticated: true}}

const mapStateToProps = state => {
  return withStateMappedToProps(state, requiredData)
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(withData(requiredData, params)(TheComponent))